import { VDivider } from 'vuetify/lib/components/VDivider';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(VDivider)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }