import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticStyle: {
            margin: "5%",
            "overflow-y": "scroll",
            height: "200px",
          },
        },
        [
          _vm.portalHtml
            ? _c("v-runtime-template", {
                attrs: {
                  template: `<div><template name="default">${_vm.portalHtml}</template></div>`,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        VCard,
        { attrs: { flat: "" } },
        [
          _c(
            VCardActions,
            { staticStyle: { "justify-content": "flex-end" } },
            [
              _c(
                VBtn,
                {
                  attrs: { text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.close()
                    },
                  },
                },
                [_vm._v("Close")]
              ),
              _c(
                VBtn,
                {
                  attrs: { color: "primary", text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.apply()
                    },
                  },
                },
                [_vm._v("Apply")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }