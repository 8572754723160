import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(VCard, { staticStyle: { "z-index": "2" } }, [
        _c(
          "div",
          { staticStyle: { display: "flex", height: "88vh" } },
          [
            _c(
              "div",
              { staticStyle: { width: "400px", "overflow-y": "scroll" } },
              [
                _c(
                  VCardTitle,
                  { staticStyle: { "margin-bottom": "-15px" } },
                  [
                    _vm._v(
                      "\n                    Expected Template Test\n                "
                    ),
                  ]
                ),
                _c(
                  "div",
                  [
                    _c(
                      VList,
                      { attrs: { nav: "", dense: "" } },
                      [
                        _c(
                          VListGroup,
                          {
                            staticStyle: { "margin-left": "-15px" },
                            attrs: {
                              value: true,
                              "no-action": "",
                              "sub-group": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function () {
                                  return [
                                    _c(
                                      VListItemContent,
                                      {
                                        staticStyle: { "margin-left": "-10px" },
                                      },
                                      [
                                        _c(VListItemTitle, [
                                          _vm._v("Diff list"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          },
                          _vm._l(_vm.diffList, function (filePath, i) {
                            return _c(
                              VListItem,
                              {
                                key: i,
                                style:
                                  _vm.selectedFilePath == filePath
                                    ? "background-color: aliceblue;"
                                    : "",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onSelected("diff", filePath)
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      VRow,
                                      [
                                        _c("Icon", {
                                          staticStyle: {
                                            color: "#2278cf",
                                            position: "relative",
                                            left: "-30px",
                                          },
                                          style:
                                            _vm.diffPathList[filePath]
                                              .subPath &&
                                            _vm.diffPathList[filePath]
                                              .subPath != ""
                                              ? "top: 20px;"
                                              : "top: 9px;",
                                          attrs: {
                                            icon: "mdi:file-document-edit-outline",
                                            width: "20",
                                            height: "20",
                                          },
                                        }),
                                        _c(
                                          VCol,
                                          {
                                            staticStyle: {
                                              "margin-left": "-35px",
                                              "margin-right": "25px",
                                            },
                                          },
                                          [
                                            _c(
                                              VListItemSubtitle,
                                              {
                                                staticStyle: {
                                                  "font-size": "x-small",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                " +
                                                    _vm._s(
                                                      _vm.diffPathList[filePath]
                                                        .subPath
                                                    ) +
                                                    "\n                                            "
                                                ),
                                              ]
                                            ),
                                            _c(VListItemTitle, [
                                              _vm._v(
                                                "\n                                                " +
                                                  _vm._s(
                                                    _vm.diffPathList[filePath]
                                                      .name
                                                  ) +
                                                  " \n                                            "
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }),
                          1
                        ),
                        _c(
                          VListGroup,
                          {
                            staticStyle: { "margin-left": "-15px" },
                            attrs: {
                              value: true,
                              "no-action": "",
                              "sub-group": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function () {
                                  return [
                                    _c(
                                      VListItemContent,
                                      {
                                        staticStyle: { "margin-left": "-10px" },
                                      },
                                      [
                                        _c(VListItemTitle, [
                                          _vm._v("Actual list"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          },
                          _vm._l(_vm.existOnlyActual, function (filePath, i) {
                            return _c(
                              VListItem,
                              {
                                key: i,
                                style:
                                  _vm.selectedFilePath == filePath
                                    ? "background-color: aliceblue;"
                                    : "",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onSelected(
                                          "actual",
                                          filePath
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      VRow,
                                      [
                                        _c("Icon", {
                                          staticStyle: {
                                            color: "green",
                                            position: "relative",
                                            left: "-30px",
                                          },
                                          style:
                                            _vm.diffPathList[filePath]
                                              .subPath &&
                                            _vm.diffPathList[filePath]
                                              .subPath != ""
                                              ? "top: 20px;"
                                              : "top: 9px;",
                                          attrs: {
                                            icon: "mdi:file-document-plus-outline",
                                            width: "20",
                                            height: "20",
                                          },
                                        }),
                                        _c(
                                          VCol,
                                          {
                                            staticStyle: {
                                              "margin-left": "-35px",
                                              "margin-right": "25px",
                                            },
                                          },
                                          [
                                            _c(
                                              VListItemSubtitle,
                                              {
                                                staticStyle: {
                                                  "font-size": "x-small",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                " +
                                                    _vm._s(
                                                      _vm.diffPathList[filePath]
                                                        .subPath
                                                    ) +
                                                    "\n                                            "
                                                ),
                                              ]
                                            ),
                                            _c(VListItemTitle, [
                                              _vm._v(
                                                "\n                                                " +
                                                  _vm._s(
                                                    _vm.diffPathList[filePath]
                                                      .name
                                                  ) +
                                                  " \n                                            "
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }),
                          1
                        ),
                        _c(
                          VListGroup,
                          {
                            staticStyle: { "margin-left": "-15px" },
                            attrs: {
                              value: true,
                              "no-action": "",
                              "sub-group": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function () {
                                  return [
                                    _c(
                                      VListItemContent,
                                      {
                                        staticStyle: { "margin-left": "-10px" },
                                      },
                                      [
                                        _c(VListItemTitle, [
                                          _vm._v("Expected list"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ]),
                          },
                          _vm._l(_vm.existOnlyExpected, function (filePath, i) {
                            return _c(
                              VListItem,
                              {
                                key: i,
                                style:
                                  _vm.selectedFilePath == filePath
                                    ? "background-color: aliceblue;"
                                    : "",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onSelected(
                                          "expected",
                                          filePath
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      VRow,
                                      [
                                        _c("Icon", {
                                          staticStyle: {
                                            color: "red",
                                            position: "relative",
                                            left: "-30px",
                                          },
                                          style:
                                            _vm.diffPathList[filePath]
                                              .subPath &&
                                            _vm.diffPathList[filePath]
                                              .subPath != ""
                                              ? "top: 20px;"
                                              : "top: 9px;",
                                          attrs: {
                                            icon: "mdi:file-document-minus-outline",
                                            width: "20",
                                            height: "20",
                                          },
                                        }),
                                        _c(
                                          VCol,
                                          {
                                            staticStyle: {
                                              "margin-left": "-35px",
                                              "margin-right": "25px",
                                            },
                                          },
                                          [
                                            _c(
                                              VListItemSubtitle,
                                              {
                                                staticStyle: {
                                                  "font-size": "x-small",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                " +
                                                    _vm._s(
                                                      _vm.diffPathList[filePath]
                                                        .subPath
                                                    ) +
                                                    "\n                                            "
                                                ),
                                              ]
                                            ),
                                            _c(VListItemTitle, [
                                              _vm._v(
                                                "\n                                                " +
                                                  _vm._s(
                                                    _vm.diffPathList[filePath]
                                                      .name
                                                  ) +
                                                  " \n                                            "
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(VDivider, { attrs: { vertical: "" } }),
            _c(
              VCardText,
              { staticStyle: { padding: "1px" } },
              [
                _vm.isDiff
                  ? _c("code-viewer", {
                      staticClass: "gs-expected-code-viewer1",
                      attrs: {
                        type: "diff",
                        isExpectedTemplate: true,
                        "create-value": _vm.selectedTemplateFile,
                        editMode: true,
                        readOnly: true,
                      },
                      model: {
                        value: _vm.selectedTemplateFramework,
                        callback: function ($$v) {
                          _vm.selectedTemplateFramework = $$v
                        },
                        expression: "selectedTemplateFramework",
                      },
                    })
                  : _c(
                      "div",
                      { key: _vm.codeViewerRenderKey },
                      [
                        _c("code-viewer", {
                          ref: "codeViewer",
                          staticClass: "gs-expected-code-viewer1",
                          attrs: {
                            isExpectedTemplate: true,
                            editMode: true,
                            readOnly: true,
                          },
                          model: {
                            value: _vm.selectedTemplateFile,
                            callback: function ($$v) {
                              _vm.selectedTemplateFile = $$v
                            },
                            expression: "selectedTemplateFile",
                          },
                        }),
                      ],
                      1
                    ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }