import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VMenu,
    {
      attrs: {
        transition: "slide-x-transition",
        right: "",
        "offset-x": "",
        "close-on-content-click": false,
        persistent: true,
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _vm.isSubMenu
                ? _c(
                    VListItem,
                    { staticClass: "d-flex justify-space-between" },
                    [
                      _c(
                        VListItemContent,
                        { staticClass: "base-template-in-list-style" },
                        [
                          _c(VListItemTitle, [
                            _vm._v(_vm._s(_vm.copyTemplateInfo.display)),
                          ]),
                          _c(
                            VListItemSubtitle,
                            { staticStyle: { "font-size": "small" } },
                            [
                              _c(
                                VIcon,
                                {
                                  staticStyle: { "margin-right": "5px" },
                                  attrs: { small: "" },
                                },
                                [_vm._v("mdi-star-outline")]
                              ),
                              _vm._v(
                                "Star: " +
                                  _vm._s(_vm.copyTemplateInfo.starCount) +
                                  "\n                    "
                              ),
                              _c(
                                VIcon,
                                {
                                  staticStyle: {
                                    "margin-left": "10px",
                                    "margin-right": "5px",
                                  },
                                  attrs: { small: "" },
                                },
                                [_vm._v("mdi-source-fork")]
                              ),
                              _vm._v(
                                "Fork: " +
                                  _vm._s(_vm.copyTemplateInfo.forkCount) +
                                  "\n                    "
                              ),
                              _c(
                                VIcon,
                                {
                                  staticStyle: {
                                    "margin-left": "10px",
                                    "margin-right": "5px",
                                  },
                                  attrs: { small: "" },
                                },
                                [_vm._v("mdi-update")]
                              ),
                              _vm._v(
                                "Update: " +
                                  _vm._s(_vm.copyTemplateInfo.updateTimeStamp) +
                                  "\n                "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        VListItemAction,
                        { staticStyle: { "flex-flow": "row" } },
                        [
                          _c(
                            VChip,
                            {
                              style:
                                _vm.forkedRepoList.length > 0
                                  ? "margin-bottom: 8.5px;"
                                  : "margin-bottom: 0px;",
                              attrs: { "x-small": "", variant: "elevated" },
                              on: {
                                click: function ($event) {
                                  return _vm.selectTemplate(
                                    _vm.copyTemplateInfo
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                    Select\n                "
                              ),
                            ]
                          ),
                          _c(
                            VBtn,
                            {
                              attrs: { icon: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.openForkedRepo(
                                    _vm.copyTemplateInfo.template
                                  )
                                },
                              },
                            },
                            [_c(VIcon, [_vm._v("mdi-open-in-new")])],
                            1
                          ),
                          _c(
                            VBtn,
                            _vm._g(
                              {
                                attrs: { icon: "" },
                                on: {
                                  click: function ($event) {
                                    _vm.openMenu = true
                                  },
                                },
                              },
                              on
                            ),
                            [_c(VIcon, [_vm._v("mdi-chevron-right")])],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    VListItem,
                    { attrs: { text: "" } },
                    [
                      _c(
                        VListItemContent,
                        { staticClass: "base-template-in-list-style" },
                        [
                          _c(VListItemTitle, [
                            _vm._v(_vm._s(_vm.copyTemplateInfo.display)),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        VListItemAction,
                        { staticStyle: { "flex-flow": "row" } },
                        [
                          _c(
                            VChip,
                            {
                              style:
                                _vm.forkedRepoList.length > 0
                                  ? "margin-bottom: 8.5px;"
                                  : "margin-bottom: 0px;",
                              attrs: { "x-small": "", variant: "elevated" },
                              on: {
                                click: function ($event) {
                                  return _vm.selectTemplate(
                                    _vm.copyTemplateInfo
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                    Select\n                "
                              ),
                            ]
                          ),
                          _vm.forkedRepoList.length > 0
                            ? _c(
                                VBtn,
                                _vm._g(
                                  {
                                    staticStyle: { "margin-right": "-20px" },
                                    attrs: { icon: "" },
                                    on: {
                                      click: function ($event) {
                                        _vm.openMenu = true
                                      },
                                    },
                                  },
                                  on
                                ),
                                [_c(VIcon, [_vm._v("mdi-chevron-right")])],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.openMenu,
        callback: function ($$v) {
          _vm.openMenu = $$v
        },
        expression: "openMenu",
      },
    },
    [
      _c(VList, { staticStyle: { width: "550px" } }, [
        _c(
          "div",
          { key: _vm.renderKey, staticStyle: { "margin-top": "-10px" } },
          [
            _c(
              VBtn,
              {
                staticStyle: { "margin-bottom": "-15px" },
                attrs: { icon: "" },
                on: { click: _vm.closeMenu },
              },
              [_c(VIcon, [_vm._v("mdi-chevron-left")])],
              1
            ),
            _vm._l(_vm.forkedRepoList, function (repo, index) {
              return [
                repo.subMenu
                  ? _c("subMenu", {
                      key: index,
                      attrs: {
                        "is-sub-menu": true,
                        isBaseTemplate: _vm.isBaseTemplate,
                        treeItem: _vm.treeItem,
                        "menu-items": repo.subMenu,
                        templateInfo: repo,
                      },
                      on: { selectTemplate: _vm.emitEventChangedTemplate },
                    })
                  : _c(
                      VListItem,
                      {
                        key: index,
                        on: {
                          mouseenter: function ($event) {
                            return _vm.getForkedList(
                              repo.template,
                              "isForkedRepo"
                            )
                          },
                        },
                      },
                      [
                        _c(
                          VListItemContent,
                          { staticClass: "base-template-in-list-style" },
                          [
                            _c(VListItemTitle, [
                              _vm._v(_vm._s(repo.display)),
                            ]),
                            _c(
                              VListItemSubtitle,
                              { staticStyle: { "font-size": "small" } },
                              [
                                _c(
                                  VIcon,
                                  {
                                    staticStyle: { "margin-right": "5px" },
                                    attrs: { small: "" },
                                  },
                                  [_vm._v("mdi-star-outline")]
                                ),
                                _vm._v(
                                  "Star: " +
                                    _vm._s(repo.starCount) +
                                    "\n                            "
                                ),
                                _c(
                                  VIcon,
                                  {
                                    staticStyle: {
                                      "margin-left": "10px",
                                      "margin-right": "5px",
                                    },
                                    attrs: { small: "" },
                                  },
                                  [_vm._v("mdi-source-fork")]
                                ),
                                _vm._v(
                                  "Fork: " +
                                    _vm._s(repo.forkCount) +
                                    "\n                            "
                                ),
                                _c(
                                  VIcon,
                                  {
                                    staticStyle: {
                                      "margin-left": "10px",
                                      "margin-right": "5px",
                                    },
                                    attrs: { small: "" },
                                  },
                                  [_vm._v("mdi-update")]
                                ),
                                _vm._v(
                                  "Update: " +
                                    _vm._s(repo.updateTimeStamp) +
                                    "\n                        "
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          VListItemAction,
                          { staticStyle: { "flex-flow": "row" } },
                          [
                            _c(
                              VChip,
                              {
                                style:
                                  _vm.forkedRepoList.length > 0
                                    ? "margin-bottom: 8.5px;"
                                    : "margin-bottom: 0px;",
                                attrs: { "x-small": "", variant: "elevated" },
                                on: {
                                  click: function ($event) {
                                    return _vm.selectTemplate(repo)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            Select\n                        "
                                ),
                              ]
                            ),
                            _c(
                              VBtn,
                              {
                                attrs: { icon: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.openForkedRepo(repo.template)
                                  },
                                },
                              },
                              [_c(VIcon, [_vm._v("mdi-open-in-new")])],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
              ]
            }),
          ],
          2
        ),
      ]),
      _c(
        VDialog,
        {
          model: {
            value: _vm.showLoginCard,
            callback: function ($$v) {
              _vm.showLoginCard = $$v
            },
            expression: "showLoginCard",
          },
        },
        [
          _c("Login", {
            attrs: { onlyGitLogin: true },
            on: {
              login: function ($event) {
                _vm.showLoginCard = false
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }