import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRating } from 'vuetify/lib/components/VRating';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "85vh", "background-color": "white" } },
    [
      _c(
        VRow,
        { staticStyle: { margin: "0px", padding: "0px" } },
        [
          _c(
            VCol,
            { attrs: { cols: "auto" } },
            [
              _vm.detailMarketMode
                ? _c(
                    VIcon,
                    {
                      staticClass: "marketplace-details-page-back-btn",
                      on: {
                        click: function ($event) {
                          return _vm.closeDetailDialog()
                        },
                      },
                    },
                    [_vm._v("mdi-arrow-left")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(VSpacer),
          _c(
            VCol,
            { attrs: { cols: "auto" } },
            [
              _c(
                VIcon,
                {
                  on: {
                    click: function ($event) {
                      return _vm.closeDialog()
                    },
                  },
                },
                [_vm._v("mdi-close")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      !_vm.detailMarketMode
        ? _c(
            "div",
            [
              _c("h1", { staticStyle: { "text-align": "center" } }, [
                _vm._v("Marketplace"),
              ]),
              _vm.templateLists.length > 1
                ? _c(
                    VTabs,
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        "bg-color": "primary",
                        "show-arrows": "",
                        centered: "",
                      },
                      model: {
                        value: _vm.tab,
                        callback: function ($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab",
                      },
                    },
                    [
                      _c(
                        VTab,
                        {
                          staticClass: "marketplace-margin-place-tab",
                          attrs: { value: "templates" },
                        },
                        [_vm._v("Templates")]
                      ),
                      _c(
                        VTab,
                        {
                          staticClass: "marketplace-margin-place-tab",
                          attrs: { value: "toppings" },
                        },
                        [_vm._v("Toppings")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.templateLists.length < 1
                ? _c(
                    VRow,
                    { staticClass: "ma-0" },
                    [
                      _c(
                        VCol,
                        {
                          staticStyle: { padding: "25px" },
                          attrs: { cols: "2" },
                        },
                        _vm._l(_vm.templateTags.length, function (n) {
                          return _c(VSkeletonLoader, {
                            key: n,
                            staticClass:
                              "mx-auto border marketplace-loding-menu",
                            attrs: { type: "avatar, text" },
                          })
                        }),
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { cols: "10" } },
                        [
                          _c(
                            VContainer,
                            { staticClass: "marketplace-skeleton-container" },
                            [
                              _c(
                                VRow,
                                _vm._l(9, function (n) {
                                  return _c(
                                    VCol,
                                    {
                                      key: n,
                                      attrs: {
                                        cols: "10",
                                        lg: "2",
                                        md: "3",
                                        sm: "6",
                                      },
                                    },
                                    [
                                      _c(VSkeletonLoader, {
                                        staticClass:
                                          "mx-auto border marketplace-loding-card",
                                        attrs: {
                                          "max-width": "300",
                                          type: "image, text@2",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                VTabsItems,
                {
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _c(
                    VTabItem,
                    { key: "templates" },
                    [
                      _c(
                        VRow,
                        { staticClass: "ma-0" },
                        [
                          _c(
                            VCol,
                            { attrs: { cols: "2" } },
                            [
                              _c(
                                VList,
                                { attrs: { dense: "" } },
                                [
                                  _c(
                                    VListItemGroup,
                                    { attrs: { color: "primary" } },
                                    _vm._l(
                                      _vm.templateTags,
                                      function (item, i) {
                                        return _c(
                                          VListItem,
                                          {
                                            key: i,
                                            on: {
                                              click: function ($event) {
                                                return _vm.clickFilteredTemplate(
                                                  i
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              VListItemIcon,
                                              {
                                                staticClass:
                                                  "marketplace-list-icon-box",
                                              },
                                              [
                                                _c("Icon", {
                                                  staticClass:
                                                    "gs-icon-style marketplace-list-icon",
                                                  attrs: { icon: item.icon },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              VListItemContent,
                                              [
                                                _c(VListItemTitle, [
                                                  _vm._v(_vm._s(item.name)),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            VCol,
                            { attrs: { cols: "10" } },
                            [
                              _c(
                                VRow,
                                { staticClass: "marketplace-card-row ma-0" },
                                [
                                  _vm._l(
                                    _vm.filteredTemplateLists,
                                    function (temp) {
                                      return _c(
                                        VCol,
                                        {
                                          key: temp.id,
                                          attrs: {
                                            cols: "12",
                                            lg: "2",
                                            md: "3",
                                            sm: "6",
                                          },
                                        },
                                        [
                                          _c(
                                            VCard,
                                            {
                                              staticClass: "marketplace-card",
                                              attrs: { outlined: "" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "marketplace-image-text-container",
                                                },
                                                [
                                                  _c(VImg, {
                                                    staticClass:
                                                      "marketplace-card-image",
                                                    attrs: {
                                                      src: temp.imageUrl,
                                                    },
                                                  }),
                                                  _c(
                                                    VCardSubtitle,
                                                    {
                                                      staticClass:
                                                        "marketplace-card-subtitle",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.openTempDetailDialog(
                                                            temp
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          temp.description
                                                        ) +
                                                          "\n                                        "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                VCardTitle,
                                                {
                                                  staticClass:
                                                    "marketplace-card-title",
                                                },
                                                [_vm._v(_vm._s(temp.name))]
                                              ),
                                              _c(
                                                VRating,
                                                {
                                                  staticClass:
                                                    "marketplace-rating",
                                                  attrs: {
                                                    "bg-color": "gray",
                                                    color: "#231813",
                                                    "half-increments": "",
                                                    readonly: "",
                                                    size: "18",
                                                  },
                                                  model: {
                                                    value: temp.rating,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        temp,
                                                        "rating",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "temp.rating",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        Rating: " +
                                                      _vm._s(temp.rating) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  _vm.isTemplateLoding
                                    ? _c(
                                        VCol,
                                        {
                                          attrs: {
                                            cols: "10",
                                            lg: "2",
                                            md: "3",
                                            sm: "6",
                                          },
                                        },
                                        [
                                          _c(VSkeletonLoader, {
                                            staticClass:
                                              "mx-auto border marketplace-loding-card",
                                            attrs: {
                                              "max-width": "300",
                                              type: "image, text@2",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    VTabItem,
                    { key: "toppings" },
                    [
                      _c(
                        VRow,
                        { staticClass: "ma-0" },
                        [
                          _c(
                            VCol,
                            { attrs: { cols: "2" } },
                            [
                              _c(
                                VList,
                                { attrs: { dense: "" } },
                                [
                                  _c(
                                    VListItemGroup,
                                    { attrs: { color: "primary" } },
                                    _vm._l(_vm.toppingTags, function (item, i) {
                                      return _c(
                                        VListItem,
                                        {
                                          key: i,
                                          on: {
                                            click: function ($event) {
                                              return _vm.clickFilteredTopping(i)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            VListItemIcon,
                                            {
                                              staticClass:
                                                "marketplace-list-icon-box",
                                            },
                                            [
                                              _c("Icon", {
                                                staticClass:
                                                  "gs-icon-style marketplace-list-icon",
                                                attrs: { icon: item.icon },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            VListItemContent,
                                            [
                                              _c(VListItemTitle, [
                                                _vm._v(_vm._s(item.name)),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            VCol,
                            { attrs: { cols: "10" } },
                            [
                              _c(
                                VRow,
                                { staticClass: "marketplace-card-row" },
                                [
                                  _vm._l(
                                    _vm.filteredToppingLists,
                                    function (topping) {
                                      return _c(
                                        VCol,
                                        {
                                          key: topping.id,
                                          attrs: {
                                            cols: "12",
                                            lg: "2",
                                            md: "3",
                                            sm: "6",
                                          },
                                        },
                                        [
                                          _c(
                                            VCard,
                                            {
                                              staticClass: "marketplace-card",
                                              attrs: { outlined: "" },
                                            },
                                            [
                                              _c(
                                                "div",
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "marketplace-image-text-container",
                                                    },
                                                    [
                                                      _c(VImg, {
                                                        staticClass:
                                                          "marketplace-card-image",
                                                        attrs: {
                                                          src: topping.imageUrl,
                                                        },
                                                      }),
                                                      _c(
                                                        VCardSubtitle,
                                                        {
                                                          staticClass:
                                                            "marketplace-card-subtitle",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.openToppingDetailDialog(
                                                                topping
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              topping.description
                                                            ) +
                                                              "\n                                            "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    VCardTitle,
                                                    {
                                                      staticClass:
                                                        "marketplace-card-title",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(topping.name)
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    VRating,
                                                    {
                                                      staticClass:
                                                        "marketplace-rating",
                                                      attrs: {
                                                        "bg-color": "gray",
                                                        color: "#231813",
                                                        "half-increments": "",
                                                        readonly: "",
                                                        size: "18",
                                                      },
                                                      model: {
                                                        value: topping.rating,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            topping,
                                                            "rating",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "topping.rating",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                            Rating: " +
                                                          _vm._s(
                                                            topping.rating
                                                          ) +
                                                          "\n                                        "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                  _vm.isToppingLoding
                                    ? _c(
                                        VCol,
                                        {
                                          attrs: {
                                            cols: "10",
                                            lg: "2",
                                            md: "3",
                                            sm: "6",
                                          },
                                        },
                                        [
                                          _c(VSkeletonLoader, {
                                            staticClass:
                                              "mx-auto border marketplace-loding-card",
                                            attrs: {
                                              "max-width": "300",
                                              type: "image, text@2",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c("div", { staticClass: "marketplace-details-page-box" }, [
            _vm.selectedTemplate
              ? _c("div", [
                  _c(
                    "div",
                    [
                      _c(
                        VRow,
                        { staticClass: "marketplace-details-page-row" },
                        [
                          _c(
                            VCol,
                            {
                              staticClass: "marketplace-details-page-col",
                              attrs: { cols: "5", lg: "3", md: "4", sm: "5" },
                            },
                            [
                              _c(VImg, {
                                attrs: { src: _vm.selectedTemplate.imageUrl },
                              }),
                              _c(VCardTitle, [
                                _vm._v(_vm._s(_vm.selectedTemplate.name)),
                              ]),
                              _c(VCardSubtitle, [
                                _vm._v(
                                  _vm._s(_vm.selectedTemplate.description)
                                ),
                              ]),
                              _c(
                                VRating,
                                {
                                  staticClass:
                                    "marketplace-details-page-rating",
                                  attrs: {
                                    "bg-color": "gray",
                                    color: "#231813",
                                    "half-increments": "",
                                    readonly: "",
                                    size: "16",
                                  },
                                  model: {
                                    value: _vm.selectedTemplate.rating,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.selectedTemplate,
                                        "rating",
                                        $$v
                                      )
                                    },
                                    expression: "selectedTemplate.rating",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            Rating: " +
                                      _vm._s(_vm.selectedTemplate.rating) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "marketplace-chip-box" },
                                [
                                  _c("div", [_vm._v("tags:")]),
                                  _vm._l(
                                    _vm.selectedTemplate.tags,
                                    function (tag) {
                                      return _c(VChip, { key: tag }, [
                                        _vm._v(
                                          _vm._s(tag) +
                                            "\n                            "
                                        ),
                                      ])
                                    }
                                  ),
                                ],
                                2
                              ),
                              _c(
                                VBtn,
                                {
                                  staticClass:
                                    "marketplace-details-page-apply-btn",
                                  attrs: { color: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.applyTemplate(
                                        _vm.selectedTemplate
                                      )
                                    },
                                  },
                                },
                                [_vm._v("apply\n                        ")]
                              ),
                            ],
                            1
                          ),
                          _c(VDivider, { attrs: { vertical: "" } }),
                          _c(
                            VCol,
                            { attrs: { cols: "7", lg: "9", md: "8", sm: "7" } },
                            [
                              _vm.selectedTemplate &&
                              _vm.selectedTemplate.instruction
                                ? _c("vue-markdown", {
                                    staticClass:
                                      "markdown-body marketplace-markdown",
                                    attrs: {
                                      source: _vm.selectedTemplate.instruction,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ])
              : _vm.selectedTopping
              ? _c("div", [
                  _c(
                    "div",
                    [
                      _c(
                        VRow,
                        { staticClass: "marketplace-details-page-row" },
                        [
                          _c(
                            VCol,
                            {
                              staticClass: "marketplace-details-page-col",
                              attrs: { cols: "5", lg: "3", md: "4", sm: "5" },
                            },
                            [
                              _c(VImg, {
                                attrs: { src: _vm.selectedTopping.imageUrl },
                              }),
                              _c(VCardTitle, [
                                _vm._v(_vm._s(_vm.selectedTopping.name)),
                              ]),
                              _c(VCardSubtitle, [
                                _vm._v(_vm._s(_vm.selectedTopping.description)),
                              ]),
                              _c(
                                VRating,
                                {
                                  staticClass:
                                    "marketplace-details-page-rating",
                                  attrs: {
                                    "bg-color": "gray",
                                    color: "#231813",
                                    "half-increments": "",
                                    readonly: "",
                                    size: "16",
                                  },
                                  model: {
                                    value: _vm.selectedTopping.rating,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.selectedTopping,
                                        "rating",
                                        $$v
                                      )
                                    },
                                    expression: "selectedTopping.rating",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            Rating: " +
                                      _vm._s(_vm.selectedTopping.rating) +
                                      "\n                        "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "marketplace-chip-box" },
                                [
                                  _c(
                                    "div",
                                    [
                                      _vm._v(
                                        "\n                                tags:\n                                "
                                      ),
                                      _vm._l(
                                        _vm.selectedTopping.tags,
                                        function (tag) {
                                          return _c(VChip, { key: tag }, [
                                            _vm._v(_vm._s(tag)),
                                          ])
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                  !_vm.isToppingCompatible(_vm.selectedTopping)
                                    ? _c(
                                        "div",
                                        [
                                          _vm._v(
                                            "\n                                depends:\n                                "
                                          ),
                                          _vm._l(
                                            _vm.selectedTopping.depends,
                                            function (depends) {
                                              return _c(VChip, [
                                                _vm._v(_vm._s(depends)),
                                              ])
                                            }
                                          ),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _c(
                                VBtn,
                                {
                                  staticClass:
                                    "marketplace-details-page-apply-btn",
                                  attrs: {
                                    color: "primary",
                                    disabled: !_vm.isToppingCompatible(
                                      _vm.selectedTopping
                                    ),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.applyTopping(
                                        _vm.selectedTopping
                                      )
                                    },
                                  },
                                },
                                [_vm._v("apply\n                        ")]
                              ),
                            ],
                            1
                          ),
                          _c(VDivider, { attrs: { vertical: "" } }),
                          _c(
                            VCol,
                            { attrs: { cols: "7", lg: "9", md: "8", sm: "7" } },
                            [
                              _vm.selectedTopping &&
                              _vm.selectedTopping.instruction
                                ? _c("vue-markdown", {
                                    staticClass:
                                      "markdown-body marketplace-markdown",
                                    attrs: {
                                      source: _vm.selectedTopping.instruction,
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }