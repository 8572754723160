import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "splitPane",
      staticClass: "split-pane",
      class: _vm.direction,
      style: { flexDirection: _vm.direction },
    },
    [
      _c(
        "div",
        {
          staticClass: "pane pane-one",
          staticStyle: { "min-width": "0px" },
          style: _vm.lengthType + ":" + _vm.paneLengthValue,
        },
        [_vm._t("one")],
        2
      ),
      _vm.paneLengthPercent < 99
        ? _c(
            "div",
            {
              class: _vm.inBoundSeparatePanel
                ? "in-bound-pane-trigger"
                : "pane-trigger",
              style: _vm.lengthType + ":" + _vm.triggerLengthValue,
              on: { mousedown: _vm.handleMouseDown },
            },
            [
              !_vm.inBoundSeparatePanel
                ? _c(
                    VTooltip,
                    {
                      attrs: { right: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  VBtn,
                                  _vm._g(
                                    {
                                      staticClass: "close-separate-panel-btn",
                                      staticStyle: {
                                        "z-index": "3",
                                        "margin-left": "-6px",
                                        top: "50%",
                                        border: "none",
                                        "background-color": "white",
                                      },
                                      attrs: {
                                        outlined: "",
                                        "x-small": "",
                                        icon: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.closeSeparate()
                                        },
                                      },
                                    },
                                    on
                                  ),
                                  [
                                    _c(
                                      VIcon,
                                      { staticStyle: { "font-size": "22px" } },
                                      [_vm._v("mdi-play")]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        417826945
                      ),
                    },
                    [_c("span", [_vm._v("Code Preview Close")])]
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.paneLengthPercent < 99
        ? _c(
            "div",
            {
              staticClass: "pane pane-two",
              staticStyle: { "min-width": "0px" },
            },
            [_vm._t("two")],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }