<template>
   <v-divider></v-divider>
</template>


<script>
    export default {
        name: 'divider',
        props: {},
    };
</script>