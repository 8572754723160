import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { style: _vm.isSIgpt ? "display: none;" : "" },
    [
      _c(
        VSnackbar,
        {
          staticClass: "snackbar-style",
          attrs: {
            "auto-height": "",
            color: _vm.gitSnackBar.Color,
            "multi-line": "",
          },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function ({ attrs }) {
                return [
                  _vm.gitSnackBar.title == "Warning" &&
                  _vm.gitSnackBar.Text == "Nothing changed" &&
                  !_vm.isOnPrem
                    ? _c(
                        VBtn,
                        _vm._b(
                          {
                            attrs: { color: "white", outlined: "", small: "" },
                            on: {
                              click: function ($event) {
                                return _vm.forcePush()
                              },
                            },
                          },
                          "v-btn",
                          attrs,
                          false
                        ),
                        [_vm._v("\n                force push\n            ")]
                      )
                    : _vm._e(),
                  _c(
                    VBtn,
                    _vm._b(
                      {
                        attrs: { color: "white", text: "", small: "" },
                        on: {
                          click: function ($event) {
                            ;(_vm.gitSnackBar.show = false),
                              (_vm.gitSnackBar.multiLineText = null)
                          },
                        },
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [_vm._v("\n                Close\n            ")]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.gitSnackBar.show,
            callback: function ($$v) {
              _vm.$set(_vm.gitSnackBar, "show", $$v)
            },
            expression: "gitSnackBar.show",
          },
        },
        [
          _c(
            VLayout,
            {
              staticStyle: { top: "-170px" },
              attrs: { "align-center": "", "pr-4": "" },
            },
            [
              _vm.gitSnackBar.icon
                ? _c(
                    VIcon,
                    { staticClass: "pr-3", attrs: { dark: "", large: "" } },
                    [_vm._v(_vm._s(_vm.gitSnackBar.icon))]
                  )
                : _vm._e(),
              _c(VLayout, { attrs: { column: "" } }, [
                _vm.gitSnackBar.title
                  ? _c("div", [
                      _c("strong", [_vm._v(_vm._s(_vm.gitSnackBar.title))]),
                    ])
                  : _vm._e(),
                _c("div", [_vm._v(_vm._s(_vm.gitSnackBar.Text))]),
                _vm.gitSnackBar.multiLineText
                  ? _c("div", [_vm._v(_vm._s(_vm.gitSnackBar.multiLineText))])
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm.gitMenuMode == "push"
        ? _c(
            VCard,
            {
              staticStyle: { width: "666px" },
              attrs: { disabled: !_vm.isListSettingDone },
            },
            [
              _c(
                VIcon,
                {
                  staticStyle: {
                    "font-size": "18px",
                    "margin-right": "3px",
                    "margin-top": "3px",
                    position: "absolute",
                    right: "0px",
                    "z-index": "1",
                  },
                  attrs: { small: "" },
                  on: {
                    click: function ($event) {
                      return _vm.closeMenu()
                    },
                  },
                },
                [_vm._v("mdi-close")]
              ),
              _c(
                VTabs,
                {
                  key: _vm.gitTabKey,
                  attrs: { vertical: "" },
                  model: {
                    value: _vm.gitTab,
                    callback: function ($$v) {
                      _vm.gitTab = $$v
                    },
                    expression: "gitTab",
                  },
                },
                [
                  _vm._l(_vm.gitTabItems, function (item) {
                    return _c(
                      VTab,
                      {
                        key: item.tab,
                        staticStyle: { "justify-content": "left" },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(item.tab) +
                            "\n                "
                        ),
                        _c(
                          VTooltip,
                          {
                            attrs: { right: "" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on }) {
                                    return [
                                      item.tabKey == "setAccount" &&
                                      _vm.githubTokenError
                                        ? _c(
                                            VIcon,
                                            _vm._g(
                                              {
                                                staticStyle: {
                                                  "font-size": "18px",
                                                  "margin-left": "3px",
                                                },
                                                attrs: {
                                                  end: "",
                                                  color: "error",
                                                },
                                              },
                                              on
                                            ),
                                            [
                                              _vm._v(
                                                "\n                            mdi-alert\n                        "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _vm._v(
                              "\n                    Make sure your github token is correct\n                "
                            ),
                          ]
                        ),
                      ],
                      1
                    )
                  }),
                  _c(
                    VTabsItems,
                    {
                      model: {
                        value: _vm.gitTab,
                        callback: function ($$v) {
                          _vm.gitTab = $$v
                        },
                        expression: "gitTab",
                      },
                    },
                    _vm._l(_vm.gitTabItems, function (item) {
                      return _c(
                        VTabItem,
                        {
                          key: item.tab,
                          staticStyle: {
                            "border-left": "1px solid rgba(0,0,0,0.54)",
                            padding: "10px",
                          },
                        },
                        [
                          _c(
                            VCard,
                            {
                              staticStyle: { padding: "10px" },
                              attrs: { flat: "" },
                            },
                            [
                              _c(
                                "div",
                                [
                                  item.tabKey == "setFirstRepo"
                                    ? _c(
                                        VRadioGroup,
                                        {
                                          attrs: { mandatory: "" },
                                          model: {
                                            value: _vm.gitRadios,
                                            callback: function ($$v) {
                                              _vm.gitRadios = $$v
                                            },
                                            expression: "gitRadios",
                                          },
                                        },
                                        [
                                          _vm.gitRefRepoUrl
                                            ? _c(
                                                VRow,
                                                { attrs: { "no-gutters": "" } },
                                                [
                                                  _c(
                                                    VCol,
                                                    { attrs: { cols: "3" } },
                                                    [
                                                      _c(VRadio, {
                                                        attrs: {
                                                          disabled:
                                                            _vm.isPrivilegedUser &&
                                                            !_vm.information
                                                              .gitOrgName,
                                                          label: "Fork from",
                                                          value: "fork",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    VCol,
                                                    { attrs: { cols: "9" } },
                                                    [
                                                      _c(VTextField, {
                                                        attrs: {
                                                          readonly: "",
                                                          dense: "",
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            !_vm.isFirstCommit ||
                                                            _vm.isExistRepo
                                                              ? {
                                                                  key: "append-outer",
                                                                  fn: function () {
                                                                    return [
                                                                      _c(
                                                                        VTooltip,
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              left: "",
                                                                            },
                                                                          scopedSlots:
                                                                            _vm._u(
                                                                              [
                                                                                {
                                                                                  key: "activator",
                                                                                  fn: function ({
                                                                                    on,
                                                                                  }) {
                                                                                    return [
                                                                                      _c(
                                                                                        VIcon,
                                                                                        _vm._g(
                                                                                          {
                                                                                            on: {
                                                                                              click:
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  return _vm.openRefGitRepo()
                                                                                                },
                                                                                            },
                                                                                          },
                                                                                          on
                                                                                        ),
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                                                            mdi-open-in-new\n                                                        "
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  },
                                                                                },
                                                                              ],
                                                                              null,
                                                                              true
                                                                            ),
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                                    open Git Repository\n                                                "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  },
                                                                  proxy: true,
                                                                }
                                                              : null,
                                                          ],
                                                          null,
                                                          true
                                                        ),
                                                        model: {
                                                          value:
                                                            _vm.gitRefRepoUrl,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.gitRefRepoUrl =
                                                              $$v
                                                          },
                                                          expression:
                                                            "gitRefRepoUrl",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          !_vm.isExistRepo
                                            ? _c(VRadio, {
                                                style:
                                                  _vm.gitRadios == "fork"
                                                    ? "position:absolute; top:115px;"
                                                    : "",
                                                attrs: {
                                                  label:
                                                    "Create New Repository",
                                                  value: "createNewRepo",
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    VRow,
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        VCol,
                                        {
                                          style:
                                            _vm.gitRadios == "createNewRepo"
                                              ? "margin-top: -10px;"
                                              : "margin-top: -10px;",
                                          attrs: { cols: "5" },
                                        },
                                        [
                                          item.tabKey == "setFirstRepo" ||
                                          item.tabKey == "changeRepo"
                                            ? _c(VAutocomplete, {
                                                attrs: {
                                                  items: _vm.gitOrganizations,
                                                  label: "Organization Name",
                                                  outlined: "",
                                                  dense: "",
                                                  "auto-select-first": "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.getGitOrganizations()
                                                  },
                                                  change: function ($event) {
                                                    return _vm.OrgNameChanged()
                                                  },
                                                },
                                                model: {
                                                  value: _vm.gitOrgName,
                                                  callback: function ($$v) {
                                                    _vm.gitOrgName = $$v
                                                  },
                                                  expression: "gitOrgName",
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        VCol,
                                        {
                                          key: _vm.repoFieldRenderKey,
                                          style:
                                            _vm.gitRadios == "createNewRepo"
                                              ? "margin-top: -10px;"
                                              : "margin-top: -10px;",
                                          attrs: { cols: "7" },
                                        },
                                        [
                                          item.tabKey == "setFirstRepo" ||
                                          item.tabKey == "changeRepo"
                                            ? _c(VTextField, {
                                                staticStyle: {
                                                  "margin-left": "10px",
                                                  "margin-top": "-5px",
                                                },
                                                attrs: {
                                                  rules: [
                                                    _vm.gitInfoRules.required,
                                                  ],
                                                  label: "Repository Name",
                                                  error: _vm.isExistRepo,
                                                  "error-messages":
                                                    _vm.isExistRepoMessage,
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    !_vm.isFirstCommit ||
                                                    _vm.isExistRepo
                                                      ? {
                                                          key: "append-outer",
                                                          fn: function () {
                                                            return [
                                                              _c(
                                                                VTooltip,
                                                                {
                                                                  attrs: {
                                                                    left: "",
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "activator",
                                                                          fn: function ({
                                                                            on,
                                                                          }) {
                                                                            return [
                                                                              _c(
                                                                                VIcon,
                                                                                _vm._g(
                                                                                  {
                                                                                    on: {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.openGitRepo()
                                                                                        },
                                                                                    },
                                                                                  },
                                                                                  on
                                                                                ),
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                                        mdi-open-in-new\n                                                    "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      true
                                                                    ),
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                                open Git Repository\n                                            "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                          proxy: true,
                                                        }
                                                      : null,
                                                  ],
                                                  null,
                                                  true
                                                ),
                                                model: {
                                                  value: _vm.gitRepoName,
                                                  callback: function ($$v) {
                                                    _vm.gitRepoName = $$v
                                                  },
                                                  expression: "gitRepoName",
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  item.tabKey == "info"
                                    ? _c(
                                        "div",
                                        {
                                          key: _vm.copyInfoKey,
                                          staticStyle: { margin: "-10px" },
                                        },
                                        [
                                          _c(
                                            "div",
                                            [
                                              [
                                                _c(
                                                  VTabs,
                                                  {
                                                    attrs: { grow: "" },
                                                    model: {
                                                      value: _vm.githubIdeTabs,
                                                      callback: function ($$v) {
                                                        _vm.githubIdeTabs = $$v
                                                      },
                                                      expression:
                                                        "githubIdeTabs",
                                                    },
                                                  },
                                                  [
                                                    _c(VTab, [
                                                      _vm._v(
                                                        "\n                                            Cloud IDE\n                                        "
                                                      ),
                                                    ]),
                                                    _c(VTab, [
                                                      _vm._v(
                                                        "\n                                            Local IDE\n                                        "
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              _c(
                                                VTabsItems,
                                                {
                                                  staticStyle: {
                                                    padding: "10px",
                                                  },
                                                  model: {
                                                    value: _vm.githubIdeTabs,
                                                    callback: function ($$v) {
                                                      _vm.githubIdeTabs = $$v
                                                    },
                                                    expression: "githubIdeTabs",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    VTabItem,
                                                    [
                                                      _c(
                                                        VCard,
                                                        {
                                                          key: _vm.gitPodError,
                                                          attrs: { flat: "" },
                                                        },
                                                        [
                                                          [
                                                            _c(
                                                              VTooltip,
                                                              {
                                                                attrs: {
                                                                  bottom: "",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "activator",
                                                                        fn: function ({
                                                                          on,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              VBtn,
                                                                              _vm._g(
                                                                                {
                                                                                  staticStyle:
                                                                                    {
                                                                                      "text-align":
                                                                                        "center",
                                                                                      height:
                                                                                        "40px",
                                                                                      "line-height":
                                                                                        "40px",
                                                                                      margin:
                                                                                        "10px 0px 10px 0px",
                                                                                      width:
                                                                                        "48%",
                                                                                      "margin-right":
                                                                                        "10px",
                                                                                    },
                                                                                  attrs:
                                                                                    {
                                                                                      disabled:
                                                                                        !_vm.isReleasedTag &&
                                                                                        _vm.projectVersion,
                                                                                      color:
                                                                                        "primary",
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.openIDE(
                                                                                          "gitpod"
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                on
                                                                              ),
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                                            Open Gitpod\n                                                        "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _vm.gitPodError
                                                                              ? _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticStyle:
                                                                                      {
                                                                                        "margin-top":
                                                                                          "-10px",
                                                                                        "margin-bottom":
                                                                                          "20px",
                                                                                        "font-size":
                                                                                          "12px",
                                                                                        color:
                                                                                          "#E5393B",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "This tag(version) has not been released."
                                                                                    ),
                                                                                  ]
                                                                                )
                                                                              : _vm._e(),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                    Go to the " +
                                                                    _vm._s(
                                                                      _vm.IdeType
                                                                    ) +
                                                                    " Link\n                                                "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              VTooltip,
                                                              {
                                                                attrs: {
                                                                  bottom: "",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "activator",
                                                                        fn: function ({
                                                                          on,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              VBtn,
                                                                              _vm._g(
                                                                                {
                                                                                  staticStyle:
                                                                                    {
                                                                                      "text-align":
                                                                                        "center",
                                                                                      height:
                                                                                        "40px",
                                                                                      "line-height":
                                                                                        "40px",
                                                                                      margin:
                                                                                        "10px 0px 10px 0px",
                                                                                      width:
                                                                                        "49%",
                                                                                    },
                                                                                  attrs:
                                                                                    {
                                                                                      color:
                                                                                        "primary",
                                                                                    },
                                                                                  on: {
                                                                                    click:
                                                                                      function (
                                                                                        $event
                                                                                      ) {
                                                                                        return _vm.openIDE(
                                                                                          "codespace"
                                                                                        )
                                                                                      },
                                                                                  },
                                                                                },
                                                                                on
                                                                              ),
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                                            Open Codespaces\n                                                        "
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                    Go to the CodeSpace Link\n                                                "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "font-weight":
                                                                  "700",
                                                                "font-size":
                                                                  "14px",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Update from model code"
                                                              ),
                                                            ]
                                                          ),
                                                          _c(VTextField, {
                                                            attrs: {
                                                              id: "copy-gitMerge-command",
                                                              label:
                                                                "To update the code, enter following command in your IDE:",
                                                              value:
                                                                _vm.gitMergeCommand,
                                                              "prepend-inner-icon":
                                                                "mdi-chevron-right",
                                                              solo: "",
                                                              readonly: "",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "append",
                                                                  fn: function () {
                                                                    return [
                                                                      _vm.isCopied ==
                                                                      "gitMerge_Command"
                                                                        ? _c(
                                                                            VIcon,
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  color:
                                                                                    "success",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                                        mdi-check\n                                                    "
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _c(
                                                                            VIcon,
                                                                            {
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.copytoClipBoard(
                                                                                      "gitMerge_Command"
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                                        mdi-content-copy\n                                                    "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                    ]
                                                                  },
                                                                  proxy: true,
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          }),
                                                        ],
                                                        2
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    VTabItem,
                                                    [
                                                      _c(
                                                        VCard,
                                                        { attrs: { flat: "" } },
                                                        [
                                                          _vm.isPrivilegedUser
                                                            ? _c(VSwitch, {
                                                                attrs: {
                                                                  label:
                                                                    "Show Origin",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.showOriginMode,
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.showOriginMode =
                                                                        $$v
                                                                    },
                                                                  expression:
                                                                    "showOriginMode",
                                                                },
                                                              })
                                                            : _vm._e(),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "font-weight":
                                                                  "700",
                                                                "font-size":
                                                                  "14px",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "1. Clone Repo"
                                                              ),
                                                            ]
                                                          ),
                                                          _c(VTextField, {
                                                            attrs: {
                                                              id: "copy-gitClone-command",
                                                              label:
                                                                "Local IDE",
                                                              value:
                                                                _vm.showOriginMode
                                                                  ? _vm.gitCloneCommandOrigin
                                                                  : _vm.gitCloneCommand,
                                                              "prepend-inner-icon":
                                                                "mdi-chevron-right",
                                                              solo: "",
                                                              readonly: "",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "append",
                                                                  fn: function () {
                                                                    return [
                                                                      _vm.isCopied ==
                                                                      "gitClone_Command"
                                                                        ? _c(
                                                                            VIcon,
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  color:
                                                                                    "success",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                                        mdi-check\n                                                    "
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _c(
                                                                            VIcon,
                                                                            {
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.copytoClipBoard(
                                                                                      "gitClone_Command"
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                                        mdi-content-copy\n                                                    "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                    ]
                                                                  },
                                                                  proxy: true,
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          }),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticStyle: {
                                                                "font-weight":
                                                                  "700",
                                                                "font-size":
                                                                  "14px",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "2. Update"
                                                              ),
                                                            ]
                                                          ),
                                                          _c(VTextField, {
                                                            attrs: {
                                                              id: "copy-gitMerge-command",
                                                              label:
                                                                "To update the code, enter following command in your IDE:",
                                                              value:
                                                                _vm.gitMergeCommand,
                                                              "prepend-inner-icon":
                                                                "mdi-chevron-right",
                                                              solo: "",
                                                              readonly: "",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "append",
                                                                  fn: function () {
                                                                    return [
                                                                      _vm.isCopied ==
                                                                      "gitMerge_Command"
                                                                        ? _c(
                                                                            VIcon,
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  color:
                                                                                    "success",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                                        mdi-check\n                                                    "
                                                                              ),
                                                                            ]
                                                                          )
                                                                        : _c(
                                                                            VIcon,
                                                                            {
                                                                              on: {
                                                                                click:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.copytoClipBoard(
                                                                                      "gitMerge_Command"
                                                                                    )
                                                                                  },
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                                        mdi-content-copy\n                                                    "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                    ]
                                                                  },
                                                                  proxy: true,
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            2
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  item.tabKey == "setAccount"
                                    ? _c(
                                        "div",
                                        {
                                          attrs: {
                                            disabled: !_vm.isListSettingDone,
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticStyle: { margin: "10px" } },
                                            [
                                              _vm.isGitLogin
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        width: "100%",
                                                        "text-align": "right",
                                                        "margin-bottom":
                                                          "-30px",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        VBtn,
                                                        {
                                                          attrs: {
                                                            small: "",
                                                            text: "",
                                                            color: "primary",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.logoutToGit()
                                                            },
                                                          },
                                                        },
                                                        [_vm._v("sign out")]
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              !_vm.isGitLogin
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        width: "100%",
                                                        "text-align": "center",
                                                        "font-size": "20px",
                                                      },
                                                    },
                                                    [
                                                      _vm.isOnPrem
                                                        ? _c("login-by-gitlab")
                                                        : _c("Login", {
                                                            attrs: {
                                                              isGitMenu: true,
                                                            },
                                                            on: {
                                                              isGitLogin:
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.isGitLoginComplete()
                                                                },
                                                            },
                                                          }),
                                                    ],
                                                    1
                                                  )
                                                : _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        width: "100%",
                                                        "text-align": "center",
                                                        "margin-bottom": "10px",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        VAvatar,
                                                        { staticClass: "ma-0" },
                                                        [
                                                          _c("img", {
                                                            attrs: {
                                                              src: _vm.gitPicture,
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                              !_vm.isGitLogin
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        width: "100%",
                                                        "text-align": "center",
                                                        "margin-top": "10px",
                                                        "margin-bottom":
                                                          "-15px",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "b",
                                                        {
                                                          staticStyle: {
                                                            color: "grey",
                                                          },
                                                        },
                                                        [_vm._v("or")]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _c(VTextField, {
                                                attrs: {
                                                  readonly: _vm.isGitLogin,
                                                  rules: [
                                                    _vm.gitInfoRules.required,
                                                  ],
                                                  label: "userName",
                                                },
                                                model: {
                                                  value: _vm.gitUserName,
                                                  callback: function ($$v) {
                                                    _vm.gitUserName = $$v
                                                  },
                                                  expression: "gitUserName",
                                                },
                                              }),
                                              _c(VTextField, {
                                                attrs: {
                                                  "append-icon": _vm.showToken
                                                    ? "mdi-eye"
                                                    : "mdi-eye-off",
                                                  type: _vm.showToken
                                                    ? "text"
                                                    : "password",
                                                  readonly: _vm.isGitLogin,
                                                  error: _vm.githubTokenError,
                                                  rules: [
                                                    _vm.gitInfoRules.required,
                                                    !_vm.githubTokenError ||
                                                      "Make sure your github token is correct",
                                                  ],
                                                  label: "token",
                                                },
                                                on: {
                                                  "click:append": function (
                                                    $event
                                                  ) {
                                                    _vm.showToken =
                                                      !_vm.showToken
                                                  },
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _vm._v(
                                                            "\n                                        Access Token\n                                        "
                                                          ),
                                                          _c(
                                                            VIcon,
                                                            {
                                                              staticStyle: {
                                                                "vertical-align":
                                                                  "middle",
                                                                "margin-top":
                                                                  "-3px",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.openGuide()
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                            mdi-help-circle-outline\n                                        "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                                model: {
                                                  value: _vm.gitToken,
                                                  callback: function ($$v) {
                                                    _vm.gitToken = $$v
                                                  },
                                                  expression: "gitToken",
                                                },
                                              }),
                                              _c(VSwitch, {
                                                attrs: {
                                                  label:
                                                    "Using gitpod to open Project IDE",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.changedUseGitPodStatus()
                                                  },
                                                },
                                                model: {
                                                  value: _vm.usegitPod,
                                                  callback: function ($$v) {
                                                    _vm.usegitPod = $$v
                                                  },
                                                  expression: "usegitPod",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  item.tabKey == "push" ||
                                  item.tabKey == "openGithubEditor"
                                    ? _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-weight": "500",
                                            "font-size": "16px",
                                          },
                                        },
                                        [_vm._v("Target Repo")]
                                      )
                                    : _vm._e(),
                                  item.tabKey == "push"
                                    ? _c(VTextField, {
                                        key: _vm.copyPushKey,
                                        attrs: {
                                          readonly: "",
                                          id: "copy-gitRepo-link",
                                          solo: "",
                                          label: "gitRepo Link",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            !_vm.isFirstCommit ||
                                            _vm.isExistRepo
                                              ? {
                                                  key: "append-outer",
                                                  fn: function () {
                                                    return [
                                                      _c(
                                                        VTooltip,
                                                        {
                                                          attrs: { left: "" },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "activator",
                                                                fn: function ({
                                                                  on,
                                                                }) {
                                                                  return [
                                                                    _c(
                                                                      VIcon,
                                                                      _vm._g(
                                                                        {
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.openGitRepo()
                                                                              },
                                                                          },
                                                                        },
                                                                        on
                                                                      ),
                                                                      [
                                                                        _vm._v(
                                                                          "\n                                            mdi-open-in-new\n                                        "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                },
                                                              },
                                                            ],
                                                            null,
                                                            true
                                                          ),
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                    open Git Repository\n                                "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                  proxy: true,
                                                }
                                              : null,
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: _vm.gitRepoUrl,
                                          callback: function ($$v) {
                                            _vm.gitRepoUrl = $$v
                                          },
                                          expression: "gitRepoUrl",
                                        },
                                      })
                                    : _vm._e(),
                                  item.tabKey == "openGithubEditor"
                                    ? _c(VTextField, {
                                        key: _vm.copyPushKey,
                                        attrs: {
                                          readonly: "",
                                          "prepend-inner-icon":
                                            "mdi-link-variant",
                                          solo: "",
                                          label: "Editor",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "append",
                                              fn: function () {
                                                return [
                                                  _c(
                                                    VTooltip,
                                                    {
                                                      attrs: { bottom: "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              on,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  VIcon,
                                                                  _vm._g(
                                                                    {
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.openGithubEditorRepo()
                                                                          },
                                                                      },
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                            mdi-open-in-new\n                                        "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        true
                                                      ),
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                    Open Git Editor\n                                "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: _vm.openGithubEditorUrl,
                                          callback: function ($$v) {
                                            _vm.openGithubEditorUrl = $$v
                                          },
                                          expression: "openGithubEditorUrl",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c("div", { staticStyle: { float: "right" } }, [
                                (!_vm.isPushing &&
                                  item.tabKey == "setFirstRepo" &&
                                  _vm.gitRadios == "createNewRepo") ||
                                (item.tabKey == "changeRepo" &&
                                  _vm.isExistRepoMessage == null)
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          VBtn,
                                          {
                                            attrs: {
                                              disabled:
                                                !_vm.gitOrgName ||
                                                !_vm.gitRepoName ||
                                                _vm.isExistRepo,
                                              color: "primary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.startCommit()
                                              },
                                            },
                                          },
                                          [_vm._v("Create")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  [
                                    (item.tabKey == "changeRepo" &&
                                      _vm.isExistRepoMessage) ||
                                    (item.tabKey == "setFirstRepo" &&
                                      _vm.isExistRepo)
                                      ? _c(
                                          VBtn,
                                          {
                                            attrs: {
                                              disabled: !(
                                                _vm.gitOrgName &&
                                                _vm.gitRepoName
                                              ),
                                              color: "primary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                _vm.gitTab = 1
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "next\n                                "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    !_vm.isPushing &&
                                    item.tabKey == "setFirstRepo" &&
                                    _vm.gitRadios == "fork"
                                      ? _c(
                                          VBtn,
                                          {
                                            attrs: {
                                              disabled:
                                                !_vm.isListSettingDone ||
                                                !(
                                                  _vm.gitOrgName &&
                                                  _vm.gitRepoName
                                                ) ||
                                                _vm.isExistRepo ||
                                                (_vm.isPrivilegedUser &&
                                                  !_vm.information.gitOrgName),
                                              text: "",
                                              color: "primary",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.startFork()
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "fork\n                                "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    !_vm.isPushing && item.tabKey == "push"
                                      ? _c(
                                          VBtnToggle,
                                          [
                                            _vm.editTemplateMode &&
                                            _vm.isTemplate
                                              ? _c(VCheckbox, {
                                                  staticClass:
                                                    "shrink mr-2 mt-0",
                                                  attrs: {
                                                    label:
                                                      "Apply this template after push",
                                                    "hide-details": "",
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.autoApplyTemplate,
                                                    callback: function ($$v) {
                                                      _vm.autoApplyTemplate =
                                                        $$v
                                                    },
                                                    expression:
                                                      "autoApplyTemplate",
                                                  },
                                                })
                                              : _vm._e(),
                                            _c(
                                              VBtn,
                                              {
                                                attrs: {
                                                  disabled:
                                                    !_vm.isListSettingDone ||
                                                    !(
                                                      _vm.gitOrgName &&
                                                      _vm.gitRepoName
                                                    ),
                                                  color: "primary",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.startCommit()
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.pushBtnName) +
                                                    "\n                                    "
                                                ),
                                              ]
                                            ),
                                            !_vm.editTemplateMode
                                              ? _c(
                                                  VMenu,
                                                  {
                                                    attrs: { "offset-y": "" },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function ({
                                                            on,
                                                            attrs,
                                                          }) {
                                                            return [
                                                              _c(
                                                                VBtn,
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      attrs: {
                                                                        icon: "",
                                                                      },
                                                                    },
                                                                    "v-btn",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _c(VIcon, [
                                                                    _vm._v(
                                                                      "mdi-chevron-down"
                                                                    ),
                                                                  ]),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _c(
                                                      VList,
                                                      _vm._l(
                                                        _vm.pushBtnitems,
                                                        function (item, index) {
                                                          return _c(
                                                            VListItem,
                                                            {
                                                              key: index,
                                                              attrs: {
                                                                disabled:
                                                                  _vm.pushType ==
                                                                  item.title,
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    _vm.pushType =
                                                                      item.title
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                VListItemTitle,
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.title
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.isPushing &&
                                    (item.tabKey == "push" ||
                                      item.tabKey == "setFirstRepo")
                                      ? _c(
                                          "span",
                                          [
                                            _vm.commitStepText != null &&
                                            _vm.commitStep != null
                                              ? _c(
                                                  "b",
                                                  { key: _vm.commitStep },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.commitStepText
                                                      ) +
                                                        "(" +
                                                        _vm._s(_vm.commitStep) +
                                                        "%) ... "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(VProgressCircular, {
                                              attrs: {
                                                indeterminate: "",
                                                color: "primary",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        VDialog,
        {
          model: {
            value: _vm.showLoginCard,
            callback: function ($$v) {
              _vm.showLoginCard = $$v
            },
            expression: "showLoginCard",
          },
        },
        [
          _c("Login", {
            attrs: { onlyGitLogin: true },
            on: {
              login: function ($event) {
                _vm.showLoginCard = false
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }