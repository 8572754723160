import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        VCard,
        {
          staticStyle: { height: "90vh", "z-index": "2", overflow: "hidden" },
          attrs: { flat: "" },
        },
        [
          _c(
            VIcon,
            {
              staticStyle: {
                "font-size": "18px",
                position: "absolute",
                right: "5px",
                top: "5px",
                "z-index": "1",
              },
              attrs: { small: "" },
              on: {
                click: function ($event) {
                  return _vm.closeGitActionDialog()
                },
              },
            },
            [_vm._v("mdi-close")]
          ),
          _c(
            "div",
            { staticStyle: { display: "flex", "max-height": "100%" } },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    width: "400px",
                    height: "88vh",
                    "background-color": "#1e1e1e",
                  },
                },
                [
                  _c(
                    VCardTitle,
                    {
                      staticStyle: {
                        "margin-top": "-10px",
                        "margin-bottom": "-15px",
                        color: "white",
                      },
                    },
                    [
                      _vm._v(
                        "\n                    Auto Implementation\n                "
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      key: _vm.dialogRenderKey,
                      staticStyle: { height: "86vh", "overflow-y": "scroll" },
                    },
                    [
                      _c(
                        VList,
                        {
                          staticStyle: {
                            width: "105%",
                            "min-width": "390px",
                            margin: "-5px -30px 0px -10px",
                            "background-color": "#1e1e1e",
                          },
                          attrs: { nav: "", dense: "" },
                        },
                        [
                          _c(
                            VListGroup,
                            {
                              staticStyle: { "margin-left": "-15px" },
                              attrs: {
                                value: true,
                                "no-action": "",
                                "sub-group": "",
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function () {
                                    return [
                                      _c(
                                        "div",
                                        { staticStyle: { cursor: "pointer" } },
                                        [
                                          _c(
                                            VRow,
                                            [
                                              _c(
                                                VCol,
                                                {
                                                  staticStyle: {
                                                    "margin-left": "-15px",
                                                    "margin-right": "25px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    VListItemSubtitle,
                                                    {
                                                      staticStyle: {
                                                        "font-size": "x-small",
                                                        color: "lightgray",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                " +
                                                          _vm._s(
                                                            _vm.testFile.subPath
                                                          ) +
                                                          "\n                                            "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    VListItemTitle,
                                                    {
                                                      staticStyle: {
                                                        color: "white",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                " +
                                                          _vm._s(
                                                            _vm.testFile.name
                                                          ) +
                                                          " \n                                            "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            },
                            _vm._l(_vm.siTestResults, function (test, testIdx) {
                              return _c(
                                VListGroup,
                                {
                                  key: testIdx,
                                  staticStyle: { "margin-left": "15px" },
                                  attrs: {
                                    value: test && test.solution ? true : false,
                                    "no-action": "",
                                    "sub-group": "",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function () {
                                          return [
                                            _c(
                                              VListItemContent,
                                              {
                                                staticStyle: {
                                                  "margin-left": "-10px",
                                                },
                                              },
                                              [
                                                _c(
                                                  VListItemTitle,
                                                  {
                                                    staticStyle: {
                                                      color: "white",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                            " +
                                                        _vm._s(
                                                          test.solutionType
                                                        ) +
                                                        "\n                                            "
                                                    ),
                                                    _vm.isSolutionCreating &&
                                                    _vm.siTestResults
                                                      .lastIndex == testIdx
                                                      ? _c(
                                                          VProgressCircular,
                                                          {
                                                            staticStyle: {
                                                              "margin-left":
                                                                "5px",
                                                            },
                                                            attrs: {
                                                              indeterminate: "",
                                                              size: 15,
                                                              color: "primary",
                                                            },
                                                          }
                                                        )
                                                      : _vm._e(),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _vm._l(
                                    test.codeChanges,
                                    function (code, codeIdx) {
                                      return _c(
                                        VListItem,
                                        {
                                          key: codeIdx,
                                          style:
                                            `${testIdx}_${codeIdx}` ==
                                            _vm.selectedIdx
                                              ? "background-color: #000000;"
                                              : "",
                                          attrs: { dense: "" },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                cursor: "pointer",
                                                "margin-top": "-10px",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onSelected(
                                                    testIdx,
                                                    codeIdx
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                VRow,
                                                [
                                                  _c("Icon", {
                                                    staticStyle: {
                                                      color: "white",
                                                      position: "relative",
                                                      left: "-30px",
                                                      top: "20px",
                                                    },
                                                    attrs: {
                                                      icon: "mdi:file-document-alert-outline",
                                                      width: "20",
                                                      height: "20",
                                                    },
                                                  }),
                                                  _c(
                                                    VCol,
                                                    {
                                                      staticStyle: {
                                                        "margin-left": "-35px",
                                                        "margin-right": "25px",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        VListItemTitle,
                                                        {
                                                          staticStyle: {
                                                            "margin-top":
                                                              "10px",
                                                            "margin-left":
                                                              "10px",
                                                            color: "white",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                    " +
                                                              _vm._s(
                                                                code.fileName
                                                              ) +
                                                              " \n                                                "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  test.sha
                                    ? _c(
                                        "div",
                                        { staticClass: "rollBack" },
                                        [
                                          _c("div", { staticClass: "line" }),
                                          _c(
                                            VTooltip,
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({ on }) {
                                                      return [
                                                        test.sha
                                                          ? _c(
                                                              VChip,
                                                              _vm._g(
                                                                {
                                                                  staticStyle: {
                                                                    "margin-left":
                                                                      "-25px",
                                                                  },
                                                                  attrs: {
                                                                    small: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.rollBack(
                                                                          test.sha,
                                                                          testIdx
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c(
                                                                  VIcon,
                                                                  {
                                                                    attrs: {
                                                                      small: "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "mdi-undo-variant"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  "Roll back and start over from here"
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c("div", { staticClass: "line" }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                2
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(VDivider, { attrs: { vertical: "" } }),
              _c(
                VCardText,
                {
                  staticStyle: { "max-height": "100%", "overflow-y": "scroll" },
                  attrs: { id: "si_gpt" },
                },
                [
                  _vm.initConfettiCnt < 2
                    ? _c("canvas", {
                        ref: "canvas",
                        staticStyle: { position: "absolute" },
                      })
                    : _vm._e(),
                  _c("div", { staticStyle: { "margin-left": "150px" } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "font-weight": "bolder",
                          "font-size": ".875rem",
                          "margin-top": "30px",
                          "margin-bottom": "25px",
                        },
                      },
                      [
                        _c(
                          VAvatar,
                          {
                            staticStyle: { "margin-right": "5px" },
                            attrs: { size: "35", rounded: "" },
                          },
                          [
                            _c("img", {
                              attrs: {
                                src: "https://github.com/msa-ez/platform/assets/65217813/3d305118-565b-4ce7-a8b6-e6ca5d6eef49",
                                alt: "MSAEZ",
                              },
                            }),
                          ]
                        ),
                        _vm._v(
                          "\n                        Generating the business logic to pass the test ... \n                        "
                        ),
                        _vm.siTestResults.length == 0
                          ? _c(VProgressCircular, {
                              staticStyle: { "margin-left": "3px" },
                              attrs: {
                                indeterminate: "",
                                size: 15,
                                color: "primary",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _vm._l(_vm.siTestResults, function (result, resIdx) {
                    return _c(
                      "div",
                      {
                        key: resIdx,
                        staticStyle: { margin: "10px 150px", "z-index": "9" },
                      },
                      [
                        result.solution
                          ? _c(
                              "div",
                              [
                                _c(
                                  VCardText,
                                  { staticStyle: { "margin-left": "-15px" } },
                                  [
                                    _c(
                                      VAvatar,
                                      { attrs: { size: "35", rounded: "" } },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: "https://github.com/msa-ez/platform/assets/65217813/3d305118-565b-4ce7-a8b6-e6ca5d6eef49",
                                            alt: "MSAEZ",
                                          },
                                        }),
                                      ]
                                    ),
                                    _c(
                                      "b",
                                      {
                                        key: _vm.dialogRenderKey,
                                        staticStyle: { "margin-left": "9px" },
                                      },
                                      [_vm._v(_vm._s(result.solution))]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._l(
                          result.codeChanges,
                          function (changes, changesIdx) {
                            return _c(
                              "div",
                              {
                                key: changesIdx,
                                staticStyle: {
                                  "margin-left": "40px",
                                  "margin-right": "40px",
                                  "margin-bottom": "12px",
                                },
                              },
                              [
                                _c(
                                  VExpansionPanels,
                                  {
                                    model: {
                                      value: changes.expansionValue,
                                      callback: function ($$v) {
                                        _vm.$set(changes, "expansionValue", $$v)
                                      },
                                      expression: "changes.expansionValue",
                                    },
                                  },
                                  [
                                    _c(
                                      VExpansionPanel,
                                      [
                                        _c(
                                          VExpansionPanelHeader,
                                          {
                                            staticStyle: {
                                              "background-color": "#343541",
                                              color: "white",
                                            },
                                            attrs: {
                                              id: resIdx + "_" + changesIdx,
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { key: _vm.dialogRenderKey },
                                              [
                                                _c("b", [
                                                  _vm._v(
                                                    _vm._s(changes.fileName)
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          VExpansionPanelContent,
                                          {
                                            staticStyle: {
                                              "background-color": "#1e1e1e",
                                            },
                                          },
                                          [
                                            _vm.lastSolutionIdx &&
                                            _vm.lastSolutionIdx ==
                                              resIdx + "_" + changesIdx
                                              ? _c(
                                                  "div",
                                                  [
                                                    _c("code-viewer", {
                                                      staticClass:
                                                        "gs-git-action-code-viewer",
                                                      staticStyle: {
                                                        padding: "0 !important",
                                                      },
                                                      attrs: {
                                                        editMode: true,
                                                        readOnly: false,
                                                        isGitActionDialog: true,
                                                      },
                                                      model: {
                                                        value:
                                                          changes.modifiedFile,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            changes,
                                                            "modifiedFile",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "changes.modifiedFile",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _c("code-viewer", {
                                                  staticClass:
                                                    "gs-git-action-code-viewer",
                                                  staticStyle: {
                                                    padding: "0 !important",
                                                  },
                                                  attrs: {
                                                    type: "diff",
                                                    "create-value":
                                                      changes.originFile,
                                                    editMode: true,
                                                    readOnly: false,
                                                    isGitActionDialog: true,
                                                  },
                                                  on: {
                                                    editCodeForActionDialog:
                                                      _vm.editCode,
                                                  },
                                                  model: {
                                                    value: changes.modifiedFile,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        changes,
                                                        "modifiedFile",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "changes.modifiedFile",
                                                  },
                                                }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }
                        ),
                        result.userMessage
                          ? _c("div", [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-weight": "bolder",
                                    "font-size": ".875rem",
                                    "margin-top": "30px",
                                    "margin-bottom": "25px",
                                  },
                                },
                                [
                                  _c(
                                    VAvatar,
                                    {
                                      staticStyle: { "margin-right": "5px" },
                                      attrs: { size: "35", rounded: "" },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: _vm.userImg,
                                          alt: "User",
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(result.userMessage) +
                                      "\n                        "
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                        result.compilerMessageIdx === 0 ||
                        result.compilerMessageIdx
                          ? _c(
                              "div",
                              { staticStyle: { "margin-left": "12.5px" } },
                              [
                                _c(
                                  VRow,
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "font-weight": "bolder",
                                          "font-size": ".875rem",
                                          "margin-top": "30px",
                                          "margin-bottom": "25px",
                                        },
                                      },
                                      [
                                        _c(
                                          VAvatar,
                                          {
                                            staticStyle: {
                                              "margin-right": "5px",
                                            },
                                            attrs: { size: "35", rounded: "" },
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: "https://github.com/msa-ez/platform/assets/65217813/a33fc1b6-6fc3-422a-8ae6-75d0248855d5",
                                                alt: "Compiler",
                                              },
                                            }),
                                          ]
                                        ),
                                        _vm._v(
                                          "\n                                Push code and testing in progress ... \n                                "
                                        ),
                                        _vm.startGitAction && !result.errorLog
                                          ? _c(VProgressCircular, {
                                              staticStyle: {
                                                "margin-left": "3px",
                                              },
                                              attrs: {
                                                indeterminate: "",
                                                size: 15,
                                                color: "primary",
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _vm.actionPathList[
                                      result.compilerMessageIdx
                                    ]
                                      ? _c(
                                          VTooltip,
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({ on }) {
                                                    return [
                                                      _c(
                                                        VBtn,
                                                        _vm._g(
                                                          {
                                                            staticStyle: {
                                                              "margin-left":
                                                                "-3px",
                                                              "margin-top":
                                                                "29px",
                                                            },
                                                            attrs: { icon: "" },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.jumpToActions(
                                                                  result.compilerMessageIdx
                                                                )
                                                              },
                                                            },
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            VIcon,
                                                            {
                                                              staticStyle: {
                                                                "font-size":
                                                                  "22px",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "mdi-open-in-new"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [_c("span", [_vm._v("See detail")])]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        result.errorLog
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  "margin-top": "25px",
                                  "margin-bottom": "10px",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      "font-weight": "bolder",
                                      "font-size": ".875rem",
                                    },
                                  },
                                  [
                                    _c(
                                      VAvatar,
                                      {
                                        staticStyle: { "margin-right": "5px" },
                                        attrs: { size: "35", rounded: "" },
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            src: "https://github.com/msa-ez/platform/assets/65217813/a33fc1b6-6fc3-422a-8ae6-75d0248855d5",
                                            alt: "Compiler",
                                          },
                                        }),
                                      ]
                                    ),
                                    _vm._v(
                                      "\n                            The following error occurred during testing\n                        "
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  VExpansionPanels,
                                  {
                                    staticStyle: {
                                      "margin-left": "40px",
                                      width: "92.9%",
                                      "margin-top": "10px",
                                    },
                                  },
                                  [
                                    _c(
                                      VExpansionPanel,
                                      [
                                        _c(
                                          VExpansionPanelHeader,
                                          {
                                            staticStyle: {
                                              color: "white",
                                              "background-color": "#343541",
                                            },
                                          },
                                          [
                                            _c(
                                              VRow,
                                              { attrs: { "no-gutters": "" } },
                                              _vm._l(
                                                result.errorLog,
                                                function (err, idx) {
                                                  return _c(
                                                    VCol,
                                                    {
                                                      key: idx,
                                                      staticStyle: {
                                                        "font-weight": "400",
                                                        "font-size": ".875rem",
                                                        "margin-bottom": "5px",
                                                      },
                                                      attrs: { cols: "10" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                            [ERROR] " +
                                                          _vm._s(err.fileName) +
                                                          ": " +
                                                          _vm._s(
                                                            err.errorDetails
                                                          )
                                                      ),
                                                      err.lineNumber
                                                        ? _c("span", [
                                                            _vm._v(
                                                              "[" +
                                                                _vm._s(
                                                                  err.lineNumber
                                                                ) +
                                                                "]"
                                                            ),
                                                          ])
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                }
                                              ),
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          VExpansionPanelContent,
                                          [
                                            _c(VTextarea, {
                                              staticStyle: {
                                                "font-size": "small",
                                                "margin-top": "10px",
                                              },
                                              attrs: {
                                                filled: "",
                                                "auto-grow": "",
                                                readonly: "",
                                              },
                                              model: {
                                                value: result.fullErrorLog,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    result,
                                                    "fullErrorLog",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "result.fullErrorLog",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        result.systemMessage
                          ? _c("div", [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-weight": "bolder",
                                    "font-size": ".875rem",
                                    "margin-top": "30px",
                                    "margin-bottom": "25px",
                                  },
                                },
                                [
                                  _c(
                                    VAvatar,
                                    {
                                      staticStyle: { "margin-right": "5px" },
                                      attrs: { size: "35", rounded: "" },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: "https://github.com/msa-ez/platform/assets/65217813/3d305118-565b-4ce7-a8b6-e6ca5d6eef49",
                                          alt: "MSAEZ",
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(
                                    "\n                            Generating a code fix for the file in error ...\n                            "
                                  ),
                                  !result.stopLoading
                                    ? _c(VProgressCircular, {
                                        staticStyle: { "margin-left": "3px" },
                                        attrs: {
                                          indeterminate: "",
                                          size: 15,
                                          color: "primary",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ],
                      2
                    )
                  }),
                  !_vm.allTestSucceeded
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-left": "161px",
                            "z-index": "99",
                            "margin-right": "202px",
                            "margin-top": "30px",
                          },
                        },
                        [
                          !_vm.startGitAction
                            ? _c(
                                "div",
                                [
                                  _c(
                                    VRow,
                                    [
                                      _c(
                                        VAvatar,
                                        {
                                          staticStyle: {
                                            "margin-right": "6px",
                                            "margin-top": "6px",
                                          },
                                          attrs: { size: "35", rounded: "" },
                                        },
                                        [
                                          _c("img", {
                                            attrs: {
                                              src: _vm.userImg,
                                              alt: "User",
                                            },
                                          }),
                                        ]
                                      ),
                                      !_vm.isAutoMode
                                        ? _c(VTextField, {
                                            attrs: {
                                              solo: "",
                                              label: "Enter your request",
                                              "append-icon": "mdi-send",
                                              clearable: "",
                                            },
                                            on: {
                                              "click:append": function (
                                                $event
                                              ) {
                                                return _vm.regenerate(
                                                  _vm.prompt
                                                )
                                              },
                                              keydown: function ($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                )
                                                  return null
                                                return _vm.regenerate(
                                                  _vm.prompt
                                                )
                                              },
                                            },
                                            model: {
                                              value: _vm.prompt,
                                              callback: function ($$v) {
                                                _vm.prompt = $$v
                                              },
                                              expression: "prompt",
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        float: "right",
                                        "margin-top": "-10px",
                                        "margin-right": "-12px",
                                      },
                                    },
                                    [
                                      _c(
                                        VBtn,
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.regenerate()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                Think again\n                            "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        VBtn,
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                          },
                                          attrs: { color: "primary" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.commitToGit()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                Go ahead\n                            "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "div",
                                {
                                  staticStyle: {
                                    position: "absolute",
                                    bottom: "10px",
                                    right: "15px",
                                  },
                                },
                                [
                                  _c(
                                    VRow,
                                    [
                                      _vm.commitCnt < 15
                                        ? _c(VSwitch, {
                                            staticStyle: {
                                              "margin-top": "1px",
                                            },
                                            attrs: { label: "Auto mode" },
                                            model: {
                                              value: _vm.isAutoMode,
                                              callback: function ($$v) {
                                                _vm.isAutoMode = $$v
                                              },
                                              expression: "isAutoMode",
                                            },
                                          })
                                        : _vm._e(),
                                      _c(
                                        VBtn,
                                        {
                                          staticStyle: {
                                            "margin-left": "10px",
                                            "margin-right": "10px",
                                          },
                                          attrs: {
                                            disabled: !_vm.isSolutionCreating,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.stop()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                Stop generating\n                            "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                        ]
                      )
                    : _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-left": "150px",
                            "margin-right": "150px",
                            "z-index": "9",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-top": "25px",
                                "margin-bottom": "10px",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-weight": "bolder",
                                    "font-size": ".875rem",
                                  },
                                },
                                [
                                  _c(
                                    VAvatar,
                                    {
                                      staticStyle: { "margin-right": "5px" },
                                      attrs: { size: "35", rounded: "" },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: "https://github.com/msa-ez/platform/assets/65217813/a33fc1b6-6fc3-422a-8ae6-75d0248855d5",
                                          alt: "Compiler",
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(
                                    "\n                            All tests passed\n                        "
                                  ),
                                ],
                                1
                              ),
                              _c(
                                VExpansionPanels,
                                {
                                  staticStyle: {
                                    "margin-left": "40px",
                                    width: "92.9%",
                                    "margin-top": "10px",
                                  },
                                  model: {
                                    value: _vm.successLogPanel,
                                    callback: function ($$v) {
                                      _vm.successLogPanel = $$v
                                    },
                                    expression: "successLogPanel",
                                  },
                                },
                                [
                                  _c(
                                    VExpansionPanel,
                                    [
                                      _c(
                                        VExpansionPanelHeader,
                                        {
                                          staticStyle: {
                                            color: "white",
                                            "background-color": "#343541",
                                          },
                                        },
                                        [
                                          _c(VRow, [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  width: "55%",
                                                  "margin-top": "19px",
                                                  "font-weight": "bolder",
                                                  "font-size": ".875rem",
                                                },
                                              },
                                              [
                                                _c(
                                                  VIcon,
                                                  {
                                                    staticStyle: {
                                                      "margin-right": "10px",
                                                      "margin-left": "5px",
                                                    },
                                                    attrs: { color: "green" },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "mdi-checkbox-marked-circle-outline"
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(
                                                  "Test success !\n                                        "
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticStyle: { width: "43%" } },
                                              [
                                                _c(
                                                  VBtn,
                                                  _vm._g(
                                                    {
                                                      staticStyle: {
                                                        "text-align": "center",
                                                        height: "40px",
                                                        "line-height": "40px",
                                                        margin:
                                                          "10px 0px 10px 0px",
                                                        width: "40%",
                                                        "margin-right": "10px",
                                                      },
                                                      attrs: {
                                                        color: "primary",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.openIDE(
                                                            "gitpod"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    _vm.on
                                                  ),
                                                  [_vm._v("Open Gitpod")]
                                                ),
                                                _c(
                                                  VBtn,
                                                  _vm._g(
                                                    {
                                                      staticStyle: {
                                                        "text-align": "center",
                                                        height: "40px",
                                                        "line-height": "40px",
                                                        margin:
                                                          "10px 0px 10px 0px",
                                                        width: "55%",
                                                      },
                                                      attrs: {
                                                        color: "primary",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.openIDE(
                                                            "codespace"
                                                          )
                                                        },
                                                      },
                                                    },
                                                    _vm.on
                                                  ),
                                                  [_vm._v("Open Codespaces")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _c(
                                        VExpansionPanelContent,
                                        {
                                          staticStyle: {
                                            height: "450px",
                                            "overflow-y": "scroll",
                                          },
                                        },
                                        [
                                          _c(VTextarea, {
                                            staticStyle: {
                                              "font-size": "small",
                                              "margin-top": "10px",
                                            },
                                            attrs: {
                                              filled: "",
                                              "auto-grow": "",
                                              readonly: "",
                                            },
                                            model: {
                                              value: _vm.successLog,
                                              callback: function ($$v) {
                                                _vm.successLog = $$v
                                              },
                                              expression: "successLog",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            VSnackbar,
            {
              staticStyle: { bottom: "8%" },
              attrs: {
                timeout: _vm.gitActionSnackBar.timeout,
                "auto-height": "",
                color: _vm.gitActionSnackBar.Color,
                "multi-line": "",
              },
              scopedSlots: _vm._u([
                {
                  key: "action",
                  fn: function ({ attrs }) {
                    return [
                      _c(
                        VBtn,
                        _vm._b(
                          {
                            attrs: { color: "white", text: "", small: "" },
                            on: {
                              click: function ($event) {
                                _vm.gitActionSnackBar.show = false
                              },
                            },
                          },
                          "v-btn",
                          attrs,
                          false
                        ),
                        [
                          _vm._v(
                            "\n                    Close\n                "
                          ),
                        ]
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.gitActionSnackBar.show,
                callback: function ($$v) {
                  _vm.$set(_vm.gitActionSnackBar, "show", $$v)
                },
                expression: "gitActionSnackBar.show",
              },
            },
            [
              _c(
                VLayout,
                { attrs: { "align-center": "", "pr-4": "" } },
                [
                  _vm.gitActionSnackBar.icon
                    ? _c(
                        VIcon,
                        { staticClass: "pr-3", attrs: { dark: "", large: "" } },
                        [_vm._v(_vm._s(_vm.gitActionSnackBar.icon))]
                      )
                    : _vm._e(),
                  _c(VLayout, { attrs: { column: "" } }, [
                    _vm.gitActionSnackBar.title
                      ? _c("div", [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.gitActionSnackBar.title)),
                          ]),
                        ])
                      : _vm._e(),
                    _c("div", [_vm._v(_vm._s(_vm.gitActionSnackBar.Text))]),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }