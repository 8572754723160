import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VRadioGroup,
    {
      attrs: { column: _vm.column },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    _vm._l(Object.keys(_vm.items), function (key) {
      return _c(VRadio, {
        key: key,
        attrs: { label: key, value: _vm.items[key] },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }