import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.isHighlightMode
      ? _c("div", {
          staticStyle: { "max-height": "100%", height: "92vh !important" },
          attrs: { id: "monaco-editor-id" },
        })
      : _vm.codeObj && _vm.codeValue
      ? _c(
          "div",
          { staticStyle: { width: "99.8%" } },
          [
            _c(
              VCardText,
              { attrs: { id: "scroll-target" } },
              [
                !_vm.editMode
                  ? _c(
                      VBtn,
                      {
                        staticClass: "cp-explain-code",
                        staticStyle: {
                          position: "absolute",
                          top: "70px",
                          right: "15px",
                          "z-index": "999",
                        },
                        attrs: {
                          small: "",
                          text: "",
                          loading: _vm.isExplainLoading,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.explain()
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          [
                            _vm._v("Explain code"),
                            _c(VIcon, { attrs: { "x-small": "" } }, [
                              _vm._v("mdi-send"),
                            ]),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                !_vm.showGpt
                  ? _c(
                      "div",
                      [
                        !_vm.isExpectedTemplate &&
                        _vm.codeLanguage == "markdown" &&
                        (!_vm.editMode || (_vm.editMode && _vm.readOnly))
                          ? _c("vue-markdown", {
                              staticClass:
                                "markdown-body gs-inside-markdown-body",
                              staticStyle: {
                                overflow: "auto",
                                padding: "0px 10px 10px 0px",
                              },
                              attrs: { source: _vm.codeValue },
                              on: { editorDidMount: _vm.editorDidMount },
                            })
                          : _c("MonacoEditor", {
                              key: _vm.monacoIds,
                              ref: "monaco-editor",
                              staticClass:
                                "monaco-editor gs-inside-monaco-editor",
                              style: _vm.isExpectedTemplate ? "" : "",
                              attrs: {
                                options: _vm.options,
                                theme: _vm.isGitActionDialog
                                  ? "vs-dark"
                                  : "light",
                                diffEditor: _vm.isDiffEditor,
                                language: _vm.codeLanguage,
                                original: _vm.getOriginal,
                              },
                              on: {
                                editorDidMount: _vm.editorDidMount,
                                change: _vm.onCmCodeChange,
                              },
                              model: {
                                value: _vm.codeValue,
                                callback: function ($$v) {
                                  _vm.codeValue = $$v
                                },
                                expression: "codeValue",
                              },
                            }),
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.showGpt && _vm.explainedResult != ""
                  ? _c(
                      "div",
                      [
                        _c(
                          VCard,
                          {
                            staticStyle: {
                              width: "350px",
                              "z-index": "1002",
                              "background-color": "#FFFFFF",
                              "margin-top": "-4px",
                              position: "absolute",
                              top: "100px",
                              right: "25px",
                            },
                            attrs: { id: "scroll-text" },
                            on: { scroll: _vm.handleScroll },
                          },
                          [
                            _c(
                              VRow,
                              {
                                staticClass: "ma-0 pa-2",
                                staticStyle: { "z-index": "999" },
                              },
                              [
                                _c(
                                  VBtn,
                                  {
                                    attrs: {
                                      icon: "",
                                      width: "24",
                                      height: "24",
                                    },
                                    on: { click: _vm.explaineToggleEvent },
                                  },
                                  [
                                    _c(VIcon, { attrs: { size: 16 } }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.explaineToggle
                                            ? "mdi-chevron-up"
                                            : "mdi-chevron-down"
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(VSpacer),
                                _vm.generationStopped
                                  ? _c(
                                      VBtn,
                                      {
                                        staticStyle: { "z-index": "999" },
                                        attrs: {
                                          icon: "",
                                          width: "24",
                                          height: "24",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.explain(true)
                                          },
                                        },
                                      },
                                      [
                                        _c(VIcon, { attrs: { size: 16 } }, [
                                          _vm._v("mdi-refresh"),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _c(
                                      VBtn,
                                      {
                                        attrs: {
                                          icon: "",
                                          width: "24",
                                          height: "24",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.stopExplainCode()
                                          },
                                        },
                                      },
                                      [
                                        _c(VIcon, { attrs: { size: 16 } }, [
                                          _vm._v("mdi-stop-circle-outline"),
                                        ]),
                                      ],
                                      1
                                    ),
                                _vm.explainedResult != ""
                                  ? _c(
                                      VBtn,
                                      {
                                        staticClass: "cp-explain-code-close",
                                        staticStyle: {
                                          "z-index": "999",
                                          color: "black",
                                        },
                                        attrs: {
                                          icon: "",
                                          width: "24",
                                          height: "24",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.closeExplainCode()
                                          },
                                        },
                                      },
                                      [
                                        _c(VIcon, { attrs: { size: 16 } }, [
                                          _vm._v("mdi-close"),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm.explaineToggle
                              ? _c(
                                  "div",
                                  [
                                    _c("vue-markdown", {
                                      staticClass:
                                        "markdown-body pl-2 pb-2 pr-2",
                                      staticStyle: {
                                        "font-size": "13px",
                                        color: "#434853",
                                        "max-height": "68vh",
                                        overflow: "auto",
                                      },
                                      attrs: { source: _vm.explainedResult },
                                    }),
                                    _c(VTextField, {
                                      staticClass:
                                        "delete-input-detail cp-explain-code-text pl-2 pr-2",
                                      staticStyle: {
                                        width: "100%",
                                        "background-color": "#FFFFFF",
                                        color: "white",
                                      },
                                      attrs: {
                                        solo: "",
                                        outlined: "",
                                        "append-icon": "mdi-send",
                                      },
                                      on: {
                                        "click:append":
                                          _vm.removeDuplicateChatPrompt,
                                        keydown: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          )
                                            return null
                                          return _vm.removeDuplicateChatPrompt.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.chatPrompt,
                                        callback: function ($$v) {
                                          _vm.chatPrompt = $$v
                                        },
                                        expression: "chatPrompt",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.showGpt
                  ? _c(
                      VCol,
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          VRow,
                          [
                            _c(
                              VCard,
                              {
                                staticClass: "pa-2",
                                staticStyle: {
                                  width: "100%",
                                  "max-height": "86vh",
                                  "z-index": "1000",
                                  "overflow-y": "scroll",
                                  "background-color": "#FFFFFF",
                                  "margin-top": "-4px",
                                },
                                attrs: { id: "scroll-text" },
                                on: { scroll: _vm.handleScroll },
                              },
                              [
                                _c(
                                  VRow,
                                  { staticClass: "ma-0 pa-0 pr-8" },
                                  [
                                    _c(
                                      VCol,
                                      { staticClass: "ma-0 pa-0" },
                                      [
                                        !_vm.explainError && _vm.showGpt
                                          ? _c(
                                              VAlert,
                                              {
                                                attrs: {
                                                  closable: "",
                                                  title: "Ask ChatGPT",
                                                  icon: "mdi-auto-fix",
                                                  type: "info",
                                                  outlined: "",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                    Ask anything about the code below this selection. i.e. How can I run this app? Where is the port number? how can I change the database product to MySQL.\n                                "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm.explainError && _vm.showGpt
                                          ? _c(
                                              VAlert,
                                              {
                                                attrs: {
                                                  closable: "",
                                                  title: "OOps",
                                                  type: "error",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                    " +
                                                    _vm._s(_vm.explainError) +
                                                    "\n                                    "
                                                ),
                                                _c(
                                                  VBtn,
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.explainError = null
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("DISMISS")]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _vm.showGpt
                                      ? _c(
                                          VIcon,
                                          {
                                            staticStyle: {
                                              position: "absolute",
                                              right: "10px",
                                              top: "10px",
                                              "z-index": "999",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.closeExplainCode()
                                              },
                                            },
                                          },
                                          [_vm._v("mdi-close")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _c("vue-markdown", {
                                  staticClass: "markdown-body",
                                  staticStyle: {
                                    padding: "15px",
                                    "font-size": "13px",
                                    color: "#434853",
                                  },
                                  attrs: { source: _vm.explainedResult },
                                }),
                                _vm.explainedResult != ""
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          position: "absolute",
                                          "z-index": "1001",
                                          top: "65px",
                                          right: "35px",
                                          display: "flex",
                                          "justify-content": "center",
                                          "align-items": "center",
                                        },
                                      },
                                      [
                                        _vm.generationStopped
                                          ? _c(
                                              VBtn,
                                              {
                                                staticStyle: {
                                                  "z-index": "999",
                                                  "margin-top": "15px",
                                                  color: "black",
                                                },
                                                attrs: { icon: "" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.explain(true)
                                                  },
                                                },
                                              },
                                              [
                                                _c(VIcon, [
                                                  _vm._v("mdi-refresh"),
                                                ]),
                                              ],
                                              1
                                            )
                                          : _c(
                                              VBtn,
                                              {
                                                staticStyle: {
                                                  "z-index": "999",
                                                  "margin-top": "15px",
                                                  color: "black",
                                                },
                                                attrs: { icon: "" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.stopExplainCode()
                                                  },
                                                },
                                              },
                                              [
                                                _c(VIcon, [
                                                  _vm._v(
                                                    "mdi-stop-circle-outline"
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "align-items": "center",
                                    },
                                  },
                                  [
                                    _c(VTextField, {
                                      ref: "input",
                                      staticClass:
                                        "question-box cp-explain-project-text",
                                      staticStyle: {
                                        width: "100%",
                                        "background-color": "#FFFFFF",
                                        color: "white",
                                      },
                                      attrs: {
                                        solo: "",
                                        outlined: "",
                                        autofocus: "",
                                        "append-icon": "mdi-send",
                                      },
                                      on: {
                                        "click:append":
                                          _vm.removeDuplicateChatPrompt,
                                        keydown: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          )
                                            return null
                                          return _vm.removeDuplicateChatPrompt.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                      model: {
                                        value: _vm.chatPrompt,
                                        callback: function ($$v) {
                                          _vm.chatPrompt = $$v
                                        },
                                        expression: "chatPrompt",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _vm.isRootFolder
                                  ? _c(
                                      "div",
                                      {
                                        staticStyle: { "text-align": "center" },
                                      },
                                      [
                                        _c(
                                          VRow,
                                          {
                                            staticStyle: {
                                              "margin-top": "15px",
                                              "margin-bottom": "15px",
                                            },
                                            attrs: { "no-gutters": "" },
                                          },
                                          [
                                            _c(
                                              VCol,
                                              { attrs: { cols: "12" } },
                                              [
                                                _c(
                                                  "b",
                                                  {
                                                    staticStyle: {
                                                      "font-size": "large",
                                                    },
                                                  },
                                                  [_vm._v("or")]
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          VBtn,
                                          {
                                            staticStyle: {
                                              "margin-bottom": "10px",
                                            },
                                            attrs: { color: "primary" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.openImplDialog()
                                              },
                                            },
                                          },
                                          [_vm._v("Auto implement")]
                                        ),
                                        _c(
                                          VDialog,
                                          {
                                            attrs: {
                                              width: "800",
                                              persistent: "",
                                            },
                                            model: {
                                              value: _vm.isOpenImplDialog,
                                              callback: function ($$v) {
                                                _vm.isOpenImplDialog = $$v
                                              },
                                              expression: "isOpenImplDialog",
                                            },
                                          },
                                          [
                                            _c(
                                              VCard,
                                              { staticClass: "mx-auto" },
                                              [
                                                _c(
                                                  VIcon,
                                                  {
                                                    staticStyle: {
                                                      position: "absolute",
                                                      right: "10px",
                                                      top: "10px",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.isOpenImplDialog = false
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("mdi-close")]
                                                ),
                                                _c(VCardTitle, [
                                                  _vm._v(
                                                    "Choose the test to implement by AI"
                                                  ),
                                                ]),
                                                _c(
                                                  VCardText,
                                                  [
                                                    _c(VAutocomplete, {
                                                      attrs: {
                                                        label:
                                                          "Choose the test",
                                                        "item-text": "name",
                                                        items: _vm.testFileList,
                                                        error:
                                                          !_vm.isExistRules,
                                                        "error-messages":
                                                          _vm.errorMsg,
                                                        clearable: "",
                                                        "return-object": "",
                                                        dense: "",
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          return _vm.changedTestFile()
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.selectedTestFile,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.selectedTestFile =
                                                            $$v
                                                        },
                                                        expression:
                                                          "selectedTestFile",
                                                      },
                                                    }),
                                                    _c(VCheckbox, {
                                                      staticClass:
                                                        "shrink mr-2 mt-0",
                                                      attrs: {
                                                        label:
                                                          "Continuing from the last commit",
                                                      },
                                                      model: {
                                                        value: _vm.isUseMain,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.isUseMain = $$v
                                                        },
                                                        expression: "isUseMain",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  VCardActions,
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "font-size": "small",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          VIcon,
                                                          {
                                                            staticStyle: {
                                                              "margin-right":
                                                                "5px",
                                                            },
                                                            attrs: {
                                                              small: "",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "mdi-help-circle-outline"
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "a",
                                                          {
                                                            attrs: {
                                                              href: "https://beta.openai.com/account/api-keys",
                                                              target: "_blank",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "how to create test or example"
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(VSpacer),
                                                    _c(
                                                      VBtn,
                                                      {
                                                        attrs: {
                                                          loading:
                                                            _vm.startImpl,
                                                          disabled:
                                                            !_vm.selectedTestFile ||
                                                            !_vm.isExistRules,
                                                          color: "primary",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.startImplWithAI()
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("Start")]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }