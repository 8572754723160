import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
import { VTreeview } from 'vuetify/lib/components/VTreeview';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VCard,
    { staticStyle: { height: "100vh" } },
    [
      _c(
        VDialog,
        {
          key: _vm.gitActionDialogRenderKey,
          attrs: { persistent: "" },
          model: {
            value: _vm.openGitActionDialog,
            callback: function ($$v) {
              _vm.openGitActionDialog = $$v
            },
            expression: "openGitActionDialog",
          },
        },
        [
          _c("GitActionDialog", {
            attrs: {
              testFile: _vm.selectedTestFile,
              selectedCodeList: _vm.selectedCodeList,
            },
            on: {
              closeGitActionDialog: _vm.closeGitActionDialog,
              startCommitWithSigpt: _vm.startCommit,
              openIDE: _vm.openIDE,
              rollBack: _vm.rollBack,
            },
          }),
        ],
        1
      ),
      _c(
        VDialog,
        {
          attrs: { "max-width": "90%", persistent: "" },
          model: {
            value: _vm.marketplaceDialog,
            callback: function ($$v) {
              _vm.marketplaceDialog = $$v
            },
            expression: "marketplaceDialog",
          },
        },
        [
          _c("MarketPlace", {
            attrs: {
              marketplaceDialog: _vm.marketplaceDialog,
              selectedBaseTemplateName: _vm.selectedBaseTemplateName,
              toppingPlatforms: _vm.toppingPlatforms,
              marketplaceType: _vm.marketplaceType,
              templateList: _vm.templateList,
            },
            on: {
              applyTemplate: _vm.applyTemplateInMarket,
              applyTopping: _vm.applyToppingInMarket,
              closeMarketplaceDialog: function ($event) {
                _vm.marketplaceDialog = false
              },
            },
          }),
        ],
        1
      ),
      _c(
        VCard,
        {
          staticStyle: {
            "z-index": "2",
            margin: "0px",
            "border-radius": "0px",
            height: "100%",
          },
        },
        [
          _c(
            "div",
            { staticStyle: { padding: "5px", height: "64px" } },
            [
              _c(VRow, { staticStyle: { margin: "0px 0px 0px 2px" } }, [
                _vm.changedModifying
                  ? _c("span", { staticClass: "headline" }, [
                      _vm._v("Merge with existing changes"),
                    ])
                  : _vm._e(),
                _c("div", [
                  !_vm.isGeneratorDone
                    ? _c(
                        "div",
                        [
                          _c(VProgressCircular, {
                            attrs: {
                              size: "15",
                              width: 3,
                              indeterminate: "",
                              color: "primary",
                            },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c(
                            VTooltip,
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        VIcon,
                                        _vm._g(
                                          {
                                            staticStyle: { "z-index": "1" },
                                            attrs: { icon: "", size: "22" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.refreshCallGenerate()
                                              },
                                            },
                                          },
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            "mdi-refresh\n                                "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [_c("span", [_vm._v("Refresh")])]
                          ),
                        ],
                        1
                      ),
                ]),
                _vm.isGeneratorDone && _vm.openCodeFileName
                  ? _c("div", { staticClass: "gs-code-title" }, [
                      _vm._v(" - " + _vm._s(_vm.openCodeFileName)),
                    ])
                  : _vm._e(),
              ]),
              _vm.isGeneratorDone
                ? _c(
                    VRow,
                    { staticStyle: { "z-index": "1", margin: "0px" } },
                    [
                      _c(
                        VTooltip,
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      VBtn,
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "code-preview-btn",
                                            attrs: { fab: "", icon: "" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.codePreviewLeftReSize()
                                              },
                                            },
                                          },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c(
                                          VIcon,
                                          { attrs: { size: "22" } },
                                          [_vm._v("mdi-menu")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3537025374
                          ),
                        },
                        [_c("span", [_vm._v("left menu fold & unfold")])]
                      ),
                      _c(
                        "div",
                        [
                          _c(
                            VMenu,
                            {
                              attrs: {
                                "close-on-click": false,
                                "close-on-content-click": false,
                                "offset-y": "",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on: menu, attrs }) {
                                      return [
                                        _c(
                                          VTooltip,
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({
                                                    on: tooltip,
                                                  }) {
                                                    return [
                                                      _c(
                                                        VBtn,
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                "cp-git-btn",
                                                              staticStyle: {
                                                                "font-size":
                                                                  "16px",
                                                                "margin-right":
                                                                  "5px",
                                                                "margin-top":
                                                                  "1px",
                                                                padding:
                                                                  "0px 5px",
                                                              },
                                                              attrs: {
                                                                disabled:
                                                                  !_vm.isGeneratorDone,
                                                                icon: "",
                                                                small: "",
                                                                color:
                                                                  _vm.gitMenu
                                                                    ? ""
                                                                    : "primary",
                                                              },
                                                            },
                                                            "v-btn",
                                                            attrs,
                                                            false
                                                          ),
                                                          {
                                                            ...tooltip,
                                                            ...menu,
                                                          }
                                                        ),
                                                        [
                                                          _c(
                                                            VIcon,
                                                            {
                                                              staticStyle: {
                                                                float: "right",
                                                              },
                                                              style: _vm.gitMenu
                                                                ? "color:gray"
                                                                : "",
                                                              attrs: {
                                                                size: "22",
                                                              },
                                                            },
                                                            [_vm._v("mdi-git")]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [_c("span", [_vm._v("Push to Git")])]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1571074028
                              ),
                              model: {
                                value: _vm.gitMenu,
                                callback: function ($$v) {
                                  _vm.gitMenu = $$v
                                },
                                expression: "gitMenu",
                              },
                            },
                            [
                              _vm.gitMenu
                                ? _c(
                                    "div",
                                    { key: _vm.gitMenuRenderKey },
                                    [
                                      _c("gitAPIMenu", {
                                        attrs: {
                                          editTemplateMode: false,
                                          information: _vm.projectInformation,
                                          isOnPrem: _vm.isOnPrem,
                                          projectId: _vm.modelingProjectId,
                                          projectName: _vm.projectName,
                                          "git-users": _vm.gitUsers,
                                          isListSettingDone:
                                            _vm.isGeneratorDone,
                                          isOwnModel: _vm.isOwnModel,
                                          changedPathListsForGit:
                                            _vm.changedPathListsForGit,
                                          generateCodeLists:
                                            _vm.filteredPrettierCodeLists,
                                          ShowCreateRepoTab:
                                            _vm.ShowCreateRepoTab,
                                          isServerModel: _vm.isServerModel,
                                          projectVersion: _vm.projectVersion,
                                          githubTokenError:
                                            _vm.githubTokenError,
                                          isOneBCModel: _vm.isOneBCModel,
                                          onlyOneBcId: _vm.onlyOneBcId,
                                          isSIgpt: _vm.isSIgpt,
                                          commitMsg: _vm.commitMsg,
                                          selectedTestFile:
                                            _vm.selectedTestFile,
                                          usedTemplates: _vm.usedTemplates,
                                          usedToppings: _vm.usedToppings,
                                          canvas: _vm.canvas,
                                        },
                                        on: {
                                          closeMenu: function ($event) {
                                            _vm.gitMenu = false
                                          },
                                          openIDE: function ($event) {
                                            return _vm.openProjectIDE($event)
                                          },
                                          settingDone: function ($event) {
                                            _vm.ShowCreateRepoTab = false
                                          },
                                          closeGitMenu: _vm.closeGitMenu,
                                          "update:git-users": (val) =>
                                            (_vm.gitUsers = val),
                                          setGitInfoToModel:
                                            _vm.setGitInfoToModel,
                                        },
                                        model: {
                                          value: _vm.value.scm,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.value, "scm", $$v)
                                          },
                                          expression: "value.scm",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        VTooltip,
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          VBtn,
                                          _vm._g(
                                            {
                                              staticClass:
                                                "code-preview-btn cp-download-btn",
                                              attrs: { icon: "", fab: "" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.downloadArchive()
                                                },
                                              },
                                            },
                                            on
                                          ),
                                          [
                                            _vm._t(
                                              "downloadArchive",
                                              function () {
                                                return [
                                                  _c(
                                                    VIcon,
                                                    { attrs: { size: "22" } },
                                                    [
                                                      _vm._v(
                                                        "\n                                        mdi-folder-download\n                                    "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [_c("span", [_vm._v("Download Archive")])]
                      ),
                      _c(
                        VTooltip,
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      VBtn,
                                      _vm._g(
                                        {
                                          staticClass: "code-preview-btn",
                                          attrs: { icon: "", fab: "" },
                                          on: {
                                            click: function ($event) {
                                              _vm.searchForContent.onOff =
                                                !_vm.searchForContent.onOff
                                            },
                                          },
                                        },
                                        on
                                      ),
                                      [
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              VIcon,
                                              { attrs: { size: "22" } },
                                              [_vm._v("mdi-magnify")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3460007173
                          ),
                        },
                        [_c("span", [_vm._v("Search")])]
                      ),
                      _vm.editableTemplate
                        ? _c(
                            VTooltip,
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          VBtn,
                                          _vm._g(
                                            {
                                              staticClass:
                                                "code-preview-btn cp-edit-template-btn",
                                              attrs: {
                                                icon: "",
                                                fab: "",
                                                color: _vm.editTemplateMode
                                                  ? "primary"
                                                  : "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openTemplateEditor()
                                                },
                                              },
                                            },
                                            on
                                          ),
                                          [
                                            _c(
                                              VIcon,
                                              { attrs: { size: "22" } },
                                              [_vm._v("mdi-code-braces")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1929698827
                              ),
                            },
                            [_c("span", [_vm._v("Edit Template")])]
                          )
                        : _vm._e(),
                      _vm.editableTemplate
                        ? _c(
                            VTooltip,
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          VBtn,
                                          _vm._g(
                                            {
                                              staticClass: "code-preview-btn",
                                              attrs: {
                                                disabled:
                                                  _vm.isLoadingExpectedTemplate,
                                                icon: "",
                                                fab: "",
                                                color:
                                                  _vm.openExpectedTemplateTestDialog
                                                    ? "primary"
                                                    : "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.testTemplateModel()
                                                },
                                              },
                                            },
                                            on
                                          ),
                                          [
                                            !_vm.startCheckDiff
                                              ? _c(
                                                  VIcon,
                                                  { attrs: { size: "22" } },
                                                  [
                                                    _vm._v(
                                                      "mdi-code-tags-check"
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  VIcon,
                                                  { attrs: { size: "22" } },
                                                  [
                                                    _vm._v(
                                                      "mdi-spin mdi-loading"
                                                    ),
                                                  ]
                                                ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3011473302
                              ),
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  "Test actual template using expected template"
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        VTooltip,
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      VBtn,
                                      _vm._g(
                                        {
                                          staticClass: "code-preview-btn",
                                          attrs: {
                                            disabled:
                                              !_vm.existChangedFile ||
                                              !_vm.isGeneratorDone,
                                            icon: "",
                                            fab: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onOffChangedPathLists()
                                            },
                                          },
                                        },
                                        on
                                      ),
                                      [
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              VIcon,
                                              {
                                                attrs: {
                                                  size: "22",
                                                  color:
                                                    _vm.showChangedPathLists
                                                      ? "primary"
                                                      : "",
                                                },
                                              },
                                              [_vm._v("mdi-filter")]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            542852566
                          ),
                        },
                        [_c("span", [_vm._v("Changed File")])]
                      ),
                      _c(
                        VTooltip,
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      VBtn,
                                      _vm._g(
                                        {
                                          staticClass: "code-preview-btn",
                                          attrs: { icon: "", fab: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onOffDesignPatterns()
                                            },
                                          },
                                        },
                                        on
                                      ),
                                      [
                                        _c("Icon", {
                                          attrs: {
                                            color: _vm.showDesignPatterns
                                              ? "rgb(25,118,210)"
                                              : "",
                                            icon: "mdi:file-document-check-outline",
                                            size: "22",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2309032869
                          ),
                        },
                        [_c("span", [_vm._v("Design Patterns")])]
                      ),
                      _c(
                        VTooltip,
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      VBtn,
                                      _vm._g(
                                        {
                                          staticClass: "code-preview-btn",
                                          attrs: { icon: "", fab: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.onDiffMode()
                                            },
                                          },
                                        },
                                        on
                                      ),
                                      [
                                        _c("Icon", {
                                          attrs: {
                                            size: "22",
                                            icon: "codicon:diff",
                                            color: _vm.diffMode
                                              ? "rgb(25,118,210)"
                                              : "",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3776896709
                          ),
                        },
                        [_c("span", [_vm._v("Diff Mode")])]
                      ),
                      _c(VSpacer),
                      _c(
                        VMenu,
                        {
                          staticStyle: { "overflow-y": "hidden !important" },
                          attrs: {
                            left: "",
                            "close-on-content-click": false,
                            "close-on-click": false,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.onClickToppingBox(true)
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      VBtn,
                                      _vm._g(
                                        _vm._b(
                                          { attrs: { text: "", small: "" } },
                                          "v-btn",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          "\n                            Toppings\n                            "
                                        ),
                                        _c(VIcon, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.showTopping
                                                ? " mdi-menu-up"
                                                : "mdi-menu-down"
                                            )
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2437760668
                          ),
                        },
                        [
                          _vm.showTopping
                            ? _c(
                                VCard,
                                {
                                  staticStyle: {
                                    width: "390px",
                                    height: "87vh",
                                  },
                                },
                                [
                                  _c(
                                    VBtn,
                                    {
                                      staticStyle: {
                                        position: "absolute",
                                        right: "5px",
                                        top: "5px",
                                      },
                                      attrs: { small: "", icon: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onClickToppingBox(false)
                                        },
                                      },
                                    },
                                    [_c(VIcon, [_vm._v("mdi-close")])],
                                    1
                                  ),
                                  _c(
                                    VCardText,
                                    { staticStyle: { height: "100%" } },
                                    [
                                      _vm._v(
                                        "\n                            Java/Spring Version\n                            "
                                      ),
                                      _c(
                                        VBtn,
                                        {
                                          staticStyle: {
                                            "margin-bottom": "1px",
                                            color: "gray",
                                          },
                                          attrs: { text: "" },
                                          on: {
                                            click: function ($event) {
                                              _vm.marketplaceDialog = true
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            VIcon,
                                            {
                                              staticStyle: {
                                                "margin-right": "5px",
                                                color: "gray",
                                              },
                                              attrs: { small: "" },
                                            },
                                            [_vm._v("mdi-cart")]
                                          ),
                                          _vm._v(
                                            "\n                                Marketplace\n                            "
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(VDivider),
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            VRadioGroup,
                                            {
                                              staticStyle: {
                                                "font-size": "10px",
                                                height: "35px",
                                              },
                                              attrs: { row: "" },
                                              model: {
                                                value: _vm.selectedVersion,
                                                callback: function ($$v) {
                                                  _vm.selectedVersion = $$v
                                                },
                                                expression: "selectedVersion",
                                              },
                                            },
                                            [
                                              _c(VRadio, {
                                                attrs: {
                                                  label: "JAVA 8",
                                                  value: "java8",
                                                  disabled: _vm.onlyJava15,
                                                },
                                              }),
                                              _c(VRadio, {
                                                attrs: {
                                                  label: "JAVA 15",
                                                  value: "java15",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "topping-radio-group",
                                          staticStyle: {
                                            height:
                                              "calc(100% - 125px) !important",
                                            "overflow-y": "auto",
                                            "overflow-x": "hidden",
                                          },
                                        },
                                        [
                                          _vm._l(
                                            Object.keys(
                                              _vm.baseToppingPlatforms
                                            ),
                                            function (baseToppingGroup) {
                                              return _c(
                                                "div",
                                                [
                                                  _vm._v(
                                                    "\n                                    " +
                                                      _vm._s(baseToppingGroup) +
                                                      "\n                                    "
                                                  ),
                                                  _c(VDivider),
                                                  _vm._l(
                                                    _vm.baseToppingPlatforms[
                                                      baseToppingGroup
                                                    ],
                                                    function (baseTopping) {
                                                      return _c(
                                                        "div",
                                                        [
                                                          _c(
                                                            VRow,
                                                            {
                                                              staticStyle: {
                                                                margin: "1px",
                                                                "align-items":
                                                                  "center",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                VTooltip,
                                                                {
                                                                  attrs: {
                                                                    left: "",
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "activator",
                                                                          fn: function ({
                                                                            on,
                                                                            attrs,
                                                                          }) {
                                                                            return [
                                                                              _c(
                                                                                VRow,
                                                                                _vm._g(
                                                                                  _vm._b(
                                                                                    {
                                                                                      staticStyle:
                                                                                        {
                                                                                          "margin-top":
                                                                                            "1px",
                                                                                          "margin-left":
                                                                                            "1px",
                                                                                        },
                                                                                    },
                                                                                    "v-row",
                                                                                    attrs,
                                                                                    false
                                                                                  ),
                                                                                  on
                                                                                ),
                                                                                [
                                                                                  _c(
                                                                                    VCheckbox,
                                                                                    {
                                                                                      staticClass:
                                                                                        "topping-checkbox",
                                                                                      attrs:
                                                                                        {
                                                                                          dense:
                                                                                            "",
                                                                                          disabled:
                                                                                            baseTopping.disable,
                                                                                          label:
                                                                                            _vm.getPreferredPlatformName(
                                                                                              baseTopping.label,
                                                                                              false,
                                                                                              true
                                                                                            ),
                                                                                          "input-value":
                                                                                            _vm.isUsedTopping(
                                                                                              baseTopping.value
                                                                                            ),
                                                                                        },
                                                                                      on: {
                                                                                        click:
                                                                                          function (
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.changedTopping(
                                                                                              baseTopping.value
                                                                                            )
                                                                                          },
                                                                                      },
                                                                                    }
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ]
                                                                          },
                                                                        },
                                                                      ],
                                                                      null,
                                                                      true
                                                                    ),
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        `${_vm.toppingBaseUrl}${baseTopping.value}`
                                                                      )
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                              _vm.isExistConfTemplate(
                                                                "TOPPING",
                                                                `${_vm.toppingBaseUrl}${baseTopping.value}`
                                                              )
                                                                ? _c(
                                                                    VMenu,
                                                                    {
                                                                      attrs: {
                                                                        "offset-y":
                                                                          "",
                                                                        top: "",
                                                                        "close-on-click": false,
                                                                        "close-on-content-click": false,
                                                                      },
                                                                      scopedSlots:
                                                                        _vm._u(
                                                                          [
                                                                            {
                                                                              key: "activator",
                                                                              fn: function ({
                                                                                on,
                                                                              }) {
                                                                                return [
                                                                                  _c(
                                                                                    VBtn,
                                                                                    _vm._g(
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            "x-small":
                                                                                              "",
                                                                                            outlined:
                                                                                              "",
                                                                                          },
                                                                                      },
                                                                                      on
                                                                                    ),
                                                                                    [
                                                                                      _vm._v(
                                                                                        "\n                                                        Configuration\n                                                    "
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              },
                                                                            },
                                                                          ],
                                                                          null,
                                                                          true
                                                                        ),
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .menuOpen[
                                                                            baseTopping
                                                                          ],
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.menuOpen,
                                                                              baseTopping,
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "menuOpen[baseTopping]",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm
                                                                        .menuOpen[
                                                                        baseTopping
                                                                      ]
                                                                        ? _c(
                                                                            VCard,
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  width:
                                                                                    "300px",
                                                                                },
                                                                              attrs:
                                                                                {
                                                                                  flat: "",
                                                                                },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "CodeConfiguration",
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      instruction:
                                                                                        _vm.configurationTemplate(
                                                                                          "TOPPING",
                                                                                          `${_vm.toppingBaseUrl}${baseTopping.value}`
                                                                                        ),
                                                                                    },
                                                                                  on: {
                                                                                    apply:
                                                                                      _vm.applyCodeConfiguration,
                                                                                    close:
                                                                                      _vm.closeCodeConfiguration,
                                                                                  },
                                                                                }
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                ],
                                                2
                                              )
                                            }
                                          ),
                                          _c("div", [
                                            _vm._v("Custom Toppings"),
                                          ]),
                                          _c(VDivider),
                                          _vm._l(
                                            Object.keys(
                                              _vm.filteredCustomToppingLists
                                            ),
                                            function (customToppingPath) {
                                              return _c(
                                                "div",
                                                _vm._l(
                                                  _vm
                                                    .filteredCustomToppingLists[
                                                    customToppingPath
                                                  ],
                                                  function (customTopping) {
                                                    return _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          VRow,
                                                          {
                                                            staticStyle: {
                                                              margin: "1px",
                                                              "align-items":
                                                                "center",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              VTooltip,
                                                              {
                                                                attrs: {
                                                                  left: "",
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "activator",
                                                                        fn: function ({
                                                                          on,
                                                                          attrs,
                                                                        }) {
                                                                          return [
                                                                            _c(
                                                                              VRow,
                                                                              _vm._g(
                                                                                _vm._b(
                                                                                  {
                                                                                    staticStyle:
                                                                                      {
                                                                                        "margin-top":
                                                                                          "1px",
                                                                                        "margin-left":
                                                                                          "1px",
                                                                                      },
                                                                                  },
                                                                                  "v-row",
                                                                                  attrs,
                                                                                  false
                                                                                ),
                                                                                on
                                                                              ),
                                                                              [
                                                                                _c(
                                                                                  VCheckbox,
                                                                                  {
                                                                                    staticClass:
                                                                                      "topping-checkbox",
                                                                                    attrs:
                                                                                      {
                                                                                        dense:
                                                                                          "",
                                                                                        label:
                                                                                          _vm.getPreferredPlatformName(
                                                                                            customTopping,
                                                                                            false,
                                                                                            true
                                                                                          ),
                                                                                        "input-value":
                                                                                          _vm.isUsedTopping(
                                                                                            customTopping
                                                                                          ),
                                                                                      },
                                                                                    on: {
                                                                                      click:
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.changedTopping(
                                                                                            customTopping
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        },
                                                                      },
                                                                    ],
                                                                    null,
                                                                    true
                                                                  ),
                                                              },
                                                              [
                                                                _c("span", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      customTopping
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                            _vm.isExistConfTemplate(
                                                              "TOPPING",
                                                              customTopping
                                                            )
                                                              ? _c(
                                                                  VMenu,
                                                                  {
                                                                    attrs: {
                                                                      "offset-y":
                                                                        "",
                                                                      top: "",
                                                                      "close-on-click": false,
                                                                      "close-on-content-click": false,
                                                                    },
                                                                    scopedSlots:
                                                                      _vm._u(
                                                                        [
                                                                          {
                                                                            key: "activator",
                                                                            fn: function ({
                                                                              on,
                                                                            }) {
                                                                              return [
                                                                                _c(
                                                                                  VBtn,
                                                                                  _vm._g(
                                                                                    {
                                                                                      attrs:
                                                                                        {
                                                                                          "x-small":
                                                                                            "",
                                                                                          outlined:
                                                                                            "",
                                                                                        },
                                                                                    },
                                                                                    on
                                                                                  ),
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\n                                                        Configuration\n                                                    "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            },
                                                                          },
                                                                        ],
                                                                        null,
                                                                        true
                                                                      ),
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .menuOpen[
                                                                          customTopping
                                                                        ],
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.menuOpen,
                                                                            customTopping,
                                                                            $$v
                                                                          )
                                                                        },
                                                                      expression:
                                                                        "menuOpen[customTopping]",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm
                                                                      .menuOpen[
                                                                      customTopping
                                                                    ]
                                                                      ? _c(
                                                                          VCard,
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                width:
                                                                                  "300px",
                                                                              },
                                                                            attrs:
                                                                              {
                                                                                flat: "",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "CodeConfiguration",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    instruction:
                                                                                      _vm.configurationTemplate(
                                                                                        "TOPPING",
                                                                                        customTopping
                                                                                      ),
                                                                                  },
                                                                                on: {
                                                                                  apply:
                                                                                    _vm.applyCodeConfiguration,
                                                                                  close:
                                                                                    _vm.closeCodeConfiguration,
                                                                                },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e(),
                                                            _c(
                                                              VBtn,
                                                              {
                                                                staticStyle: {
                                                                  "align-self":
                                                                    "center",
                                                                },
                                                                attrs: {
                                                                  small: "",
                                                                  icon: "",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.removeCustomTopping(
                                                                        customTopping
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  VIcon,
                                                                  {
                                                                    attrs: {
                                                                      small: "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "mdi-close"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  }
                                                ),
                                                0
                                              )
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                      _c(
                                        VCardActions,
                                        [
                                          _c(
                                            VBtn,
                                            {
                                              attrs: { block: "", dark: "" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openTemplateDialog(
                                                    "TOPPING"
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(" Custom Topping ")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(VDivider),
          _c(
            VCardText,
            { staticStyle: { "padding-bottom": "0px" } },
            [
              _c("separate-panel-components", {
                staticClass: "fill-height",
                attrs: {
                  min: _vm.separatePanelInfo.min,
                  max: _vm.separatePanelInfo.max,
                  triggerLength: 2,
                  paneLengthPercent: _vm.separatePanelInfo.current,
                  inBoundSeparatePanel: true,
                },
                on: {
                  "update:paneLengthPercent": function ($event) {
                    return _vm.$set(_vm.separatePanelInfo, "current", $event)
                  },
                  "update:pane-length-percent": function ($event) {
                    return _vm.$set(_vm.separatePanelInfo, "current", $event)
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "one",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              VCol,
                              {
                                staticClass: "code-preview-left-re-size",
                                staticStyle: {
                                  display: "flex",
                                  height: "calc(100vh - 72px)",
                                  margin: "0",
                                  padding: "0",
                                  overflow: "auto",
                                },
                                style: _vm.editTemplateMode
                                  ? "overflow-x:hidden !important;"
                                  : "",
                                attrs: { id: "scroll-target" },
                              },
                              [
                                _vm.changedModifying
                                  ? _c(
                                      "div",
                                      [
                                        _c(VTreeview, {
                                          staticStyle: {
                                            height: "100%",
                                            overflow: "auto",
                                          },
                                          attrs: {
                                            hoverable: "",
                                            items: _vm.changedTmp,
                                            activatable: "",
                                            "item-key": "hash",
                                            "return-object": "",
                                            "open-all": "",
                                            transition: "",
                                            dense: "",
                                          },
                                          on: {
                                            "update:items": function ($event) {
                                              _vm.changedTmp = $event
                                            },
                                            "update:active": _vm.onSelected,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "prepend",
                                                fn: function ({ item, open }) {
                                                  return [
                                                    item.changed > 0
                                                      ? _c(VIcon, [
                                                          _vm._v(
                                                            "\n                                            mdi-autorenew\n                                        "
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                    item.isNew
                                                      ? _c(VIcon, [
                                                          _vm._v(
                                                            "\n                                            mdi-plus\n                                        "
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "append",
                                                fn: function ({ item, open }) {
                                                  return [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(
                                                          item.changed == 0
                                                            ? ""
                                                            : item.changed
                                                        ) +
                                                        "\n                                    "
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            121892727
                                          ),
                                        }),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      {
                                        staticClass:
                                          "event-storming-treeview-height",
                                        staticStyle: {
                                          "min-width": "0px",
                                          width: "100%",
                                        },
                                      },
                                      [
                                        _vm.searchForContent.onOff
                                          ? _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "margin-left": "12px",
                                                  width: "100%",
                                                },
                                              },
                                              [
                                                _c(VTextField, {
                                                  attrs: {
                                                    "append-outer-icon":
                                                      "mdi-close",
                                                    label: "Search",
                                                    type: "text",
                                                    filled: "",
                                                    sole: "",
                                                    dense: "",
                                                    autofocus: "",
                                                    "hide-details": "",
                                                  },
                                                  on: {
                                                    "click:append-outer":
                                                      function ($event) {
                                                        return _vm.closeSearchForContents()
                                                      },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.searchForContent
                                                        .search,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.searchForContent,
                                                        "search",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "searchForContent.search",
                                                  },
                                                }),
                                                _vm.searchForContent.search &&
                                                _vm.filteredTreeLists &&
                                                _vm.filteredTreeLists.length ==
                                                  0
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "margin-left": "10px",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                        No results found.\n                                    "
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.searchForFile.onOff
                                          ? _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  position: "absolute",
                                                  top: "0",
                                                  left: "50%",
                                                  width: "40%",
                                                  "margin-top": "20px",
                                                },
                                              },
                                              [
                                                _c(VAutocomplete, {
                                                  staticStyle: {
                                                    "text-overflow":
                                                      "clip !important",
                                                    "margin-right": "-50px",
                                                  },
                                                  style: _vm.editTemplateMode
                                                    ? "max-height: 70%;"
                                                    : "",
                                                  attrs: {
                                                    label:
                                                      "Search for files by name",
                                                    items:
                                                      _vm.filteredCodeLists,
                                                    "item-text": "fullPath",
                                                    "return-object": "",
                                                    "append-outer-icon":
                                                      "mdi-close",
                                                    autofocus: "",
                                                    dense: "",
                                                  },
                                                  on: {
                                                    "update:search-input":
                                                      _vm.searchForFiles,
                                                    "click:append-outer":
                                                      function ($event) {
                                                        return _vm.closeSearchForFiles()
                                                      },
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "item",
                                                        fn: function ({
                                                          item,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "14px",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.fileName
                                                                  ) + " "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  "font-size":
                                                                    "10px",
                                                                  color: "gray",
                                                                  "margin-left":
                                                                    "7px",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                                                " +
                                                                    _vm._s(
                                                                      item.fullPath.substring(
                                                                        0,
                                                                        item.fullPath.lastIndexOf(
                                                                          "/"
                                                                        )
                                                                      )
                                                                    ) +
                                                                    "\n                                            "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    2972631550
                                                  ),
                                                  model: {
                                                    value:
                                                      _vm.searchForFile.search,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.searchForFile,
                                                        "search",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "searchForFile.search",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.showBaseTemplate
                                          ? _c(
                                              VMenu,
                                              {
                                                attrs: {
                                                  "offset-y": "",
                                                  "close-on-content-click": false,
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function ({
                                                        on,
                                                        attrs,
                                                      }) {
                                                        return [
                                                          _c(
                                                            VRow,
                                                            {
                                                              staticStyle: {
                                                                color: "gray",
                                                                position:
                                                                  "relative",
                                                                margin:
                                                                  "5px 0px 0px 13px",
                                                              },
                                                            },
                                                            [
                                                              _c("div", [
                                                                _vm._v("Base:"),
                                                              ]),
                                                              _c(
                                                                VChip,
                                                                _vm._g(
                                                                  _vm._b(
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          margin:
                                                                            "3px 0px 0px 38px",
                                                                          position:
                                                                            "absolute",
                                                                        },
                                                                      attrs: {
                                                                        "x-small":
                                                                          "",
                                                                      },
                                                                      on: {
                                                                        mouseenter:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            _vm.showFullNameforBaseTemplate = true
                                                                          },
                                                                        mouseleave:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            _vm.showFullNameforBaseTemplate = false
                                                                          },
                                                                      },
                                                                    },
                                                                    "v-chip",
                                                                    attrs,
                                                                    false
                                                                  ),
                                                                  on
                                                                ),
                                                                [
                                                                  _vm._v(
                                                                    "\n                                                " +
                                                                      _vm._s(
                                                                        _vm.selectedBaseTemplateName
                                                                      ) +
                                                                      "\n                                            "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  4281257504
                                                ),
                                                model: {
                                                  value: _vm.menuOpen.BASE,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.menuOpen,
                                                      "BASE",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "menuOpen.BASE",
                                                },
                                              },
                                              [
                                                _c(
                                                  VTabs,
                                                  [
                                                    _c(VTab, [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.editableTemplate
                                                              ? "Change Template"
                                                              : "Cannot be changed."
                                                          ) +
                                                          " "
                                                      ),
                                                    ]),
                                                    _c(
                                                      VBtn,
                                                      {
                                                        staticStyle: {
                                                          margin:
                                                            "5px 0px 0px 10px",
                                                        },
                                                        attrs: { text: "" },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.openMarketplaceDialog(
                                                              "BASE"
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          VIcon,
                                                          {
                                                            staticStyle: {
                                                              "margin-right":
                                                                "5px",
                                                            },
                                                            attrs: {
                                                              small: "",
                                                            },
                                                          },
                                                          [_vm._v("mdi-cart")]
                                                        ),
                                                        _vm._v(
                                                          "\n                                            Marketplace\n                                        "
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm.isExistConfTemplate(
                                                      "BASE",
                                                      _vm.basePlatform
                                                    )
                                                      ? _c(VTab, [
                                                          _vm._v(
                                                            " Configuration "
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                    _c(
                                                      VTabItem,
                                                      [
                                                        _vm.editableTemplate
                                                          ? _c(
                                                              VList,
                                                              _vm._l(
                                                                _vm.baseTemplateList,
                                                                function (
                                                                  tempItem,
                                                                  index
                                                                ) {
                                                                  return _c(
                                                                    VListItem,
                                                                    {
                                                                      key: index,
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "subMenu",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              templateInfo:
                                                                                tempItem,
                                                                              isBaseTemplate: true,
                                                                            },
                                                                          on: {
                                                                            selectTemplate:
                                                                              _vm.emitEventChangedTemplate,
                                                                          },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      VTabItem,
                                                      [
                                                        _c(
                                                          "CodeConfiguration",
                                                          {
                                                            attrs: {
                                                              instruction:
                                                                _vm.configurationTemplate(
                                                                  "BASE",
                                                                  _vm.basePlatform
                                                                ),
                                                            },
                                                            on: {
                                                              apply:
                                                                _vm.applyCodeConfiguration,
                                                              close:
                                                                _vm.closeCodeConfiguration,
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm.isGeneratorDone
                                          ? _c(
                                              "div",
                                              [
                                                _vm.embeddedK8s &&
                                                _vm.selectedElementIds.length ==
                                                  1
                                                  ? _c(
                                                      "div",
                                                      [
                                                        _c(
                                                          VListItem,
                                                          [
                                                            _c(
                                                              VListItemContent,
                                                              [
                                                                _c(
                                                                  VListItemTitle,
                                                                  {
                                                                    staticClass:
                                                                      "text-h6",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                    Docs Navigator\n                                                "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(VDivider),
                                                        _c(
                                                          VList,
                                                          {
                                                            attrs: {
                                                              dense: "",
                                                              nav: "",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              VListItemGroup,
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "primary",
                                                                },
                                                              },
                                                              _vm._l(
                                                                _vm.docsNavigator,
                                                                function (
                                                                  item,
                                                                  i
                                                                ) {
                                                                  return _c(
                                                                    VListItem,
                                                                    {
                                                                      key: i,
                                                                      attrs: {
                                                                        link: "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "a",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              href: item.anchor,
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            VListItemContent,
                                                                            [
                                                                              _c(
                                                                                VListItemTitle,
                                                                                [
                                                                                  _vm._v(
                                                                                    "\n                                                                " +
                                                                                      _vm._s(
                                                                                        item.text
                                                                                      ) +
                                                                                      "\n                                                            "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  )
                                                                }
                                                              ),
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(VDivider),
                                                      ],
                                                      1
                                                    )
                                                  : _c(
                                                      VList,
                                                      {
                                                        staticStyle: {
                                                          padding: "0px",
                                                        },
                                                        attrs: {
                                                          nav: "",
                                                          dense: "",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          VListGroup,
                                                          {
                                                            class:
                                                              _vm.editTemplateMode
                                                                ? "gs-source-tree-v-list-group"
                                                                : "",
                                                            attrs: {
                                                              value: true,
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "activator",
                                                                  fn: function () {
                                                                    return [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              width:
                                                                                "100%",
                                                                              "min-width":
                                                                                "0px",
                                                                              "margin-left":
                                                                                "6px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            VListItemTitle,
                                                                            [
                                                                              _vm._v(
                                                                                "Source Tree"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  },
                                                                  proxy: true,
                                                                },
                                                              ],
                                                              null,
                                                              false,
                                                              3592907891
                                                            ),
                                                          },
                                                          [
                                                            _vm.filteredTreeLists
                                                              ? _c(
                                                                  VTreeview,
                                                                  {
                                                                    ref: "codeTrees",
                                                                    staticClass:
                                                                      "gs-v-treeview-width",
                                                                    staticStyle:
                                                                      {
                                                                        "text-overflow":
                                                                          "clip !important",
                                                                      },
                                                                    style:
                                                                      _vm.editTemplateMode
                                                                        ? "max-height: 70%;"
                                                                        : "",
                                                                    attrs: {
                                                                      items:
                                                                        _vm.filteredTreeLists,
                                                                      open: _vm.filteredTreeOpenList,
                                                                      active:
                                                                        _vm.selectedFileList,
                                                                      activatable:
                                                                        "",
                                                                      "item-key":
                                                                        "hash",
                                                                      "return-object":
                                                                        "",
                                                                      dense: "",
                                                                    },
                                                                    on: {
                                                                      "update:items":
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          _vm.filteredTreeLists =
                                                                            $event
                                                                        },
                                                                      "update:active":
                                                                        _vm.onSelected,
                                                                    },
                                                                    scopedSlots:
                                                                      _vm._u(
                                                                        [
                                                                          {
                                                                            key: "prepend",
                                                                            fn: function ({
                                                                              item,
                                                                              open,
                                                                            }) {
                                                                              return [
                                                                                _vm.isChangedCode(
                                                                                  item
                                                                                )
                                                                                  ? _c(
                                                                                      VIcon,
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            position:
                                                                                              "absolute",
                                                                                            "z-index":
                                                                                              "9999",
                                                                                            "font-size":
                                                                                              "1px",
                                                                                          },
                                                                                        attrs:
                                                                                          {
                                                                                            color:
                                                                                              "#00B0FF",
                                                                                            "x-small":
                                                                                              "",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          "fiber_manual_record\n                                                    "
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e(),
                                                                                _vm.isNotFolderIcon
                                                                                  ? _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticClass:
                                                                                          "cp-code",
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          VIcon,
                                                                                          {
                                                                                            style:
                                                                                              _vm.templatePathStyle(
                                                                                                item
                                                                                              ),
                                                                                            attrs:
                                                                                              {
                                                                                                size: "22",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                                            " +
                                                                                                _vm._s(
                                                                                                  _vm
                                                                                                    .icon[
                                                                                                    item
                                                                                                      .file
                                                                                                  ]
                                                                                                    ? _vm
                                                                                                        .icon[
                                                                                                        item
                                                                                                          .file
                                                                                                      ]
                                                                                                    : "mdi-folder"
                                                                                                ) +
                                                                                                "\n                                                        "
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  : _c(
                                                                                      "div",
                                                                                      [
                                                                                        item.children &&
                                                                                        item
                                                                                          .children
                                                                                          .length >
                                                                                          0
                                                                                          ? _c(
                                                                                              VIcon,
                                                                                              {
                                                                                                style:
                                                                                                  _vm.templatePathStyle(
                                                                                                    item
                                                                                                  ),
                                                                                                attrs:
                                                                                                  {
                                                                                                    size: "22",
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "\n                                                            " +
                                                                                                    _vm._s(
                                                                                                      open
                                                                                                        ? "mdi-folder-open"
                                                                                                        : "mdi-folder"
                                                                                                    ) +
                                                                                                    "\n                                                        "
                                                                                                ),
                                                                                              ]
                                                                                            )
                                                                                          : _c(
                                                                                              VIcon,
                                                                                              {
                                                                                                style:
                                                                                                  _vm.templatePathStyle(
                                                                                                    item
                                                                                                  ),
                                                                                                attrs:
                                                                                                  {
                                                                                                    size: "22",
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "\n                                                            " +
                                                                                                    _vm._s(
                                                                                                      _vm
                                                                                                        .icon[
                                                                                                        item
                                                                                                          .file
                                                                                                      ]
                                                                                                        ? _vm
                                                                                                            .icon[
                                                                                                            item
                                                                                                              .file
                                                                                                          ]
                                                                                                        : "mdi-folder"
                                                                                                    ) +
                                                                                                    "\n                                                        "
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                              ]
                                                                            },
                                                                          },
                                                                          {
                                                                            key: "label",
                                                                            fn: function ({
                                                                              item,
                                                                              open,
                                                                            }) {
                                                                              return [
                                                                                _vm.isNotFolderIcon
                                                                                  ? _c(
                                                                                      "div",
                                                                                      [
                                                                                        item.children &&
                                                                                        item
                                                                                          .children
                                                                                          .length >
                                                                                          0
                                                                                          ? _c(
                                                                                              "div",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  " " +
                                                                                                    _vm._s(
                                                                                                      item.name
                                                                                                    ) +
                                                                                                    " "
                                                                                                ),
                                                                                              ]
                                                                                            )
                                                                                          : _c(
                                                                                              "div",
                                                                                              {
                                                                                                staticStyle:
                                                                                                  {
                                                                                                    "font-size":
                                                                                                      "13px",
                                                                                                    cursor:
                                                                                                      "pointer",
                                                                                                  },
                                                                                                style:
                                                                                                  _vm.templatePathStyle(
                                                                                                    item
                                                                                                  ),
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  " " +
                                                                                                    _vm._s(
                                                                                                      item.searchContentLine
                                                                                                    ) +
                                                                                                    " "
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                      ]
                                                                                    )
                                                                                  : _c(
                                                                                      "div",
                                                                                      [
                                                                                        _vm.showTemplatePath ||
                                                                                        _vm.showChangedPathLists
                                                                                          ? _c(
                                                                                              "div",
                                                                                              {
                                                                                                staticStyle:
                                                                                                  {
                                                                                                    "font-size":
                                                                                                      "1px",
                                                                                                    "margin-top":
                                                                                                      "5px",
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "\n                                                            " +
                                                                                                    _vm._s(
                                                                                                      _vm.convertTemplatePath(
                                                                                                        item
                                                                                                      )
                                                                                                    ) +
                                                                                                    "\n                                                        "
                                                                                                ),
                                                                                              ]
                                                                                            )
                                                                                          : _vm._e(),
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticStyle:
                                                                                              {
                                                                                                "font-size":
                                                                                                  "13px",
                                                                                                cursor:
                                                                                                  "pointer",
                                                                                              },
                                                                                            style:
                                                                                              _vm.templatePathStyle(
                                                                                                item
                                                                                              ),
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              " " +
                                                                                                _vm._s(
                                                                                                  item.name
                                                                                                ) +
                                                                                                " "
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                              ]
                                                                            },
                                                                          },
                                                                          {
                                                                            key: "append",
                                                                            fn: function ({
                                                                              item,
                                                                              open,
                                                                            }) {
                                                                              return [
                                                                                _c(
                                                                                  VRow,
                                                                                  {
                                                                                    staticStyle:
                                                                                      {
                                                                                        "align-items":
                                                                                          "center",
                                                                                        "justify-content":
                                                                                          "flex-end",
                                                                                        "margin-right":
                                                                                          "0px",
                                                                                      },
                                                                                  },
                                                                                  [
                                                                                    _vm.showChangedPathLists &&
                                                                                    !item.file &&
                                                                                    item.name ==
                                                                                      "Changed Files"
                                                                                      ? _c(
                                                                                          "div",
                                                                                          [
                                                                                            _c(
                                                                                              VIcon,
                                                                                              {
                                                                                                staticStyle:
                                                                                                  {
                                                                                                    "font-size":
                                                                                                      "16px",
                                                                                                    position:
                                                                                                      "absolute",
                                                                                                    left: "270px",
                                                                                                    top: "15px",
                                                                                                  },
                                                                                                on: {
                                                                                                  click:
                                                                                                    function (
                                                                                                      $event
                                                                                                    ) {
                                                                                                      return _vm.clearChangedPathListsBucket()
                                                                                                    },
                                                                                                },
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "mdi-delete\n                                                            "
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      : _c(
                                                                                          "div",
                                                                                          [
                                                                                            item.divisionLine &&
                                                                                            _vm.showBaseTemplate &&
                                                                                            !_vm.isOneBCModel
                                                                                              ? _c(
                                                                                                  VDivider,
                                                                                                  {
                                                                                                    staticStyle:
                                                                                                      {
                                                                                                        "margin-left":
                                                                                                          "-100%",
                                                                                                        width:
                                                                                                          "100%",
                                                                                                        position:
                                                                                                          "absolute",
                                                                                                        "margin-top":
                                                                                                          "-4px",
                                                                                                      },
                                                                                                  }
                                                                                                )
                                                                                              : _vm._e(),
                                                                                            item.showTemplateChip &&
                                                                                            !_vm.isOneBCModel
                                                                                              ? _c(
                                                                                                  VMenu,
                                                                                                  {
                                                                                                    attrs:
                                                                                                      {
                                                                                                        "offset-y":
                                                                                                          "",
                                                                                                        "close-on-content-click": false,
                                                                                                        direction:
                                                                                                          "top",
                                                                                                      },
                                                                                                    scopedSlots:
                                                                                                      _vm._u(
                                                                                                        [
                                                                                                          {
                                                                                                            key: "activator",
                                                                                                            fn: function ({
                                                                                                              on,
                                                                                                              attrs,
                                                                                                            }) {
                                                                                                              return [
                                                                                                                _c(
                                                                                                                  VChip,
                                                                                                                  _vm._g(
                                                                                                                    _vm._b(
                                                                                                                      {
                                                                                                                        staticStyle:
                                                                                                                          {
                                                                                                                            position:
                                                                                                                              "absolute",
                                                                                                                            left: "190px",
                                                                                                                            top: "7px",
                                                                                                                          },
                                                                                                                        attrs:
                                                                                                                          {
                                                                                                                            "x-small":
                                                                                                                              "",
                                                                                                                          },
                                                                                                                        on: {
                                                                                                                          mouseenter:
                                                                                                                            function (
                                                                                                                              $event
                                                                                                                            ) {
                                                                                                                              _vm.showFullNameforSelectedTemplateKey =
                                                                                                                                item.key
                                                                                                                            },
                                                                                                                          mouseleave:
                                                                                                                            function (
                                                                                                                              $event
                                                                                                                            ) {
                                                                                                                              _vm.showFullNameforSelectedTemplateKey =
                                                                                                                                null
                                                                                                                            },
                                                                                                                        },
                                                                                                                      },
                                                                                                                      "v-chip",
                                                                                                                      attrs,
                                                                                                                      false
                                                                                                                    ),
                                                                                                                    on
                                                                                                                  ),
                                                                                                                  [
                                                                                                                    _vm._v(
                                                                                                                      "\n                                                                        " +
                                                                                                                        _vm._s(
                                                                                                                          item.preferredPlatform
                                                                                                                            ? _vm.showFullNameforSelectedTemplateKey ==
                                                                                                                              item.key
                                                                                                                              ? _vm.getPreferredPlatformName(
                                                                                                                                  item.preferredPlatform,
                                                                                                                                  true
                                                                                                                                )
                                                                                                                              : _vm.getPreferredPlatformName(
                                                                                                                                  item.preferredPlatform,
                                                                                                                                  false
                                                                                                                                )
                                                                                                                            : "selected"
                                                                                                                        ) +
                                                                                                                        "\n                                                                    "
                                                                                                                    ),
                                                                                                                  ]
                                                                                                                ),
                                                                                                              ]
                                                                                                            },
                                                                                                          },
                                                                                                        ],
                                                                                                        null,
                                                                                                        true
                                                                                                      ),
                                                                                                    model:
                                                                                                      {
                                                                                                        value:
                                                                                                          _vm
                                                                                                            .menuOpen[
                                                                                                            item
                                                                                                              .bcId
                                                                                                          ],
                                                                                                        callback:
                                                                                                          function (
                                                                                                            $$v
                                                                                                          ) {
                                                                                                            _vm.$set(
                                                                                                              _vm.menuOpen,
                                                                                                              item.bcId,
                                                                                                              $$v
                                                                                                            )
                                                                                                          },
                                                                                                        expression:
                                                                                                          "menuOpen[item.bcId]",
                                                                                                      },
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      VTabs,
                                                                                                      {
                                                                                                        staticStyle:
                                                                                                          {
                                                                                                            "max-height":
                                                                                                              "450px",
                                                                                                            "overflow-x":
                                                                                                              "scroll",
                                                                                                          },
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          VTab,
                                                                                                          [
                                                                                                            _vm._v(
                                                                                                              " " +
                                                                                                                _vm._s(
                                                                                                                  _vm.editableTemplate
                                                                                                                    ? "Change Template"
                                                                                                                    : "Cannot be changed."
                                                                                                                ) +
                                                                                                                " "
                                                                                                            ),
                                                                                                          ]
                                                                                                        ),
                                                                                                        _c(
                                                                                                          VBtn,
                                                                                                          {
                                                                                                            staticStyle:
                                                                                                              {
                                                                                                                margin:
                                                                                                                  "5px 0px 0px 10px",
                                                                                                              },
                                                                                                            attrs:
                                                                                                              {
                                                                                                                text: "",
                                                                                                              },
                                                                                                            on: {
                                                                                                              click:
                                                                                                                function (
                                                                                                                  $event
                                                                                                                ) {
                                                                                                                  return _vm.openMarketplaceDialog(
                                                                                                                    "TEMPLATE",
                                                                                                                    item
                                                                                                                  )
                                                                                                                },
                                                                                                            },
                                                                                                          },
                                                                                                          [
                                                                                                            _c(
                                                                                                              VIcon,
                                                                                                              {
                                                                                                                staticStyle:
                                                                                                                  {
                                                                                                                    "margin-right":
                                                                                                                      "5px",
                                                                                                                  },
                                                                                                                attrs:
                                                                                                                  {
                                                                                                                    small:
                                                                                                                      "",
                                                                                                                  },
                                                                                                              },
                                                                                                              [
                                                                                                                _vm._v(
                                                                                                                  "mdi-cart"
                                                                                                                ),
                                                                                                              ]
                                                                                                            ),
                                                                                                            _vm._v(
                                                                                                              "\n                                                                        Marketplace\n                                                                    "
                                                                                                            ),
                                                                                                          ],
                                                                                                          1
                                                                                                        ),
                                                                                                        _vm.isExistConfTemplate(
                                                                                                          "TEMPLATE",
                                                                                                          item.preferredPlatform
                                                                                                        )
                                                                                                          ? _c(
                                                                                                              VTab,
                                                                                                              [
                                                                                                                _vm._v(
                                                                                                                  " Configuration "
                                                                                                                ),
                                                                                                              ]
                                                                                                            )
                                                                                                          : _vm._e(),
                                                                                                        _c(
                                                                                                          VTabItem,
                                                                                                          [
                                                                                                            _vm.editableTemplate
                                                                                                              ? _c(
                                                                                                                  VList,
                                                                                                                  _vm._l(
                                                                                                                    _vm.templateList,
                                                                                                                    function (
                                                                                                                      tempItem,
                                                                                                                      index
                                                                                                                    ) {
                                                                                                                      return _c(
                                                                                                                        VListItem,
                                                                                                                        {
                                                                                                                          key: index,
                                                                                                                        },
                                                                                                                        [
                                                                                                                          _c(
                                                                                                                            "subMenu",
                                                                                                                            {
                                                                                                                              attrs:
                                                                                                                                {
                                                                                                                                  templateInfo:
                                                                                                                                    tempItem,
                                                                                                                                  isBaseTemplate: true,
                                                                                                                                },
                                                                                                                              on: {
                                                                                                                                selectTemplate:
                                                                                                                                  function (
                                                                                                                                    $event
                                                                                                                                  ) {
                                                                                                                                    return _vm.openTemplateDialog(
                                                                                                                                      "TEMPLATE",
                                                                                                                                      $event.tmp,
                                                                                                                                      item
                                                                                                                                    )
                                                                                                                                  },
                                                                                                                              },
                                                                                                                            }
                                                                                                                          ),
                                                                                                                        ],
                                                                                                                        1
                                                                                                                      )
                                                                                                                    }
                                                                                                                  ),
                                                                                                                  1
                                                                                                                )
                                                                                                              : _vm._e(),
                                                                                                          ],
                                                                                                          1
                                                                                                        ),
                                                                                                        _c(
                                                                                                          VTabItem,
                                                                                                          [
                                                                                                            _c(
                                                                                                              "CodeConfiguration",
                                                                                                              {
                                                                                                                attrs:
                                                                                                                  {
                                                                                                                    instruction:
                                                                                                                      _vm.configurationTemplate(
                                                                                                                        "TEMPLATE",
                                                                                                                        item
                                                                                                                      ),
                                                                                                                  },
                                                                                                                on: {
                                                                                                                  apply:
                                                                                                                    _vm.applyCodeConfiguration,
                                                                                                                  close:
                                                                                                                    _vm.closeCodeConfiguration,
                                                                                                                },
                                                                                                              }
                                                                                                            ),
                                                                                                          ],
                                                                                                          1
                                                                                                        ),
                                                                                                      ],
                                                                                                      1
                                                                                                    ),
                                                                                                  ],
                                                                                                  1
                                                                                                )
                                                                                              : _vm._e(),
                                                                                            _c(
                                                                                              "div",
                                                                                              {
                                                                                                staticStyle:
                                                                                                  {
                                                                                                    color:
                                                                                                      "red",
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "\n                                                                " +
                                                                                                    _vm._s(
                                                                                                      item.changed ==
                                                                                                        0
                                                                                                        ? ""
                                                                                                        : item.changed
                                                                                                    ) +
                                                                                                    "\n                                                            "
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            },
                                                                          },
                                                                        ],
                                                                        null,
                                                                        false,
                                                                        2087050935
                                                                      ),
                                                                  }
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "two",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              VRow,
                              [
                                _c(
                                  VCol,
                                  [
                                    _vm.editTemplateMode
                                      ? _c(
                                          VDialog,
                                          {
                                            attrs: {
                                              persistent: "",
                                              "no-click-animation": "",
                                            },
                                            model: {
                                              value: _vm.editTemplateMode,
                                              callback: function ($$v) {
                                                _vm.editTemplateMode = $$v
                                              },
                                              expression: "editTemplateMode",
                                            },
                                          },
                                          [
                                            _vm.editTemplateMode
                                              ? _c(
                                                  "div",
                                                  {
                                                    key: _vm.editModeCodeViewerRenderKey,
                                                  },
                                                  [
                                                    _c(
                                                      VCard,
                                                      {
                                                        staticStyle: {
                                                          "z-index": "2",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              display: "flex",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticStyle: {
                                                                  width:
                                                                    "400px",
                                                                  "overflow-y":
                                                                    "scroll",
                                                                  height:
                                                                    "88vh",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  VCardTitle,
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                        Model Explorer\n                                                    "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    key: _vm.modelDataTreeKey,
                                                                  },
                                                                  [
                                                                    _c(
                                                                      VTreeview,
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "text-overflow":
                                                                              "clip",
                                                                            "margin-right":
                                                                              "-50px",
                                                                            "max-height":
                                                                              "70%",
                                                                            "font-size":
                                                                              "12px",
                                                                            cursor:
                                                                              "pointer",
                                                                          },
                                                                        attrs: {
                                                                          active:
                                                                            _vm.active_tree,
                                                                          items:
                                                                            _vm.treeData,
                                                                          "load-children":
                                                                            _vm.fetchChildren,
                                                                          open: _vm.open_tree,
                                                                          activatable:
                                                                            "",
                                                                          dense:
                                                                            "",
                                                                          "open-on-click":
                                                                            "",
                                                                          transition:
                                                                            "",
                                                                        },
                                                                        on: {
                                                                          "update:active":
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              _vm.active_tree =
                                                                                $event
                                                                            },
                                                                          "update:open":
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              _vm.open_tree =
                                                                                $event
                                                                            },
                                                                        },
                                                                        scopedSlots:
                                                                          _vm._u(
                                                                            [
                                                                              {
                                                                                key: "label",
                                                                                fn: function ({
                                                                                  item,
                                                                                }) {
                                                                                  return [
                                                                                    _c(
                                                                                      "span",
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            item.name
                                                                                          )
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                    item.value
                                                                                      ? _c(
                                                                                          "span",
                                                                                          {
                                                                                            style:
                                                                                              item.value ==
                                                                                                "true" ||
                                                                                              item.value ==
                                                                                                "false"
                                                                                                ? "color: #e28e9c"
                                                                                                : item.value ==
                                                                                                  "null"
                                                                                                ? "color: #70b1ca"
                                                                                                : /[0-9]/.test(
                                                                                                    item.value
                                                                                                  )
                                                                                                ? "color: #9682f8"
                                                                                                : "color: #0f7f12",
                                                                                            attrs:
                                                                                              {
                                                                                                id: "treeValue",
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                                                    " +
                                                                                                _vm._s(
                                                                                                  item.value
                                                                                                ) +
                                                                                                "\n                                                                "
                                                                                            ),
                                                                                          ]
                                                                                        )
                                                                                      : _vm._e(),
                                                                                    item.value &&
                                                                                    _vm.active_tree &&
                                                                                    _vm
                                                                                      .active_tree[0] ==
                                                                                      item.id
                                                                                      ? _c(
                                                                                          VBtn,
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                icon: "",
                                                                                              },
                                                                                            on: {
                                                                                              click:
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  return _vm.pasteModelValue(
                                                                                                    item
                                                                                                  )
                                                                                                },
                                                                                            },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              VIcon,
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    small:
                                                                                                      "",
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "mdi-share"
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      : _vm._e(),
                                                                                  ]
                                                                                },
                                                                              },
                                                                            ],
                                                                            null,
                                                                            false,
                                                                            809688483
                                                                          ),
                                                                      }
                                                                    ),
                                                                    _vm.editTemplateMode
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                width:
                                                                                  "100%",
                                                                                position:
                                                                                  "relative",
                                                                                "margin-top":
                                                                                  "10px",
                                                                                "margin-bottom":
                                                                                  "10px",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              VDivider
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  VList,
                                                                  {
                                                                    attrs: {
                                                                      nav: "",
                                                                      dense: "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      VListGroup,
                                                                      {
                                                                        attrs: {
                                                                          value: true,
                                                                        },
                                                                        scopedSlots:
                                                                          _vm._u(
                                                                            [
                                                                              {
                                                                                key: "activator",
                                                                                fn: function () {
                                                                                  return [
                                                                                    _c(
                                                                                      VListItemTitle,
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            display:
                                                                                              "flex",
                                                                                            "align-items":
                                                                                              "center",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "Template Explorer"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          VBtn,
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                icon: "",
                                                                                              },
                                                                                            on: {
                                                                                              click:
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  return _vm.openTemplateTreeEditor(
                                                                                                    null,
                                                                                                    "add New"
                                                                                                  )
                                                                                                },
                                                                                            },
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              VIcon,
                                                                                              {
                                                                                                attrs:
                                                                                                  {
                                                                                                    small:
                                                                                                      "",
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "mdi-folder-plus"
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ]
                                                                                },
                                                                                proxy: true,
                                                                              },
                                                                            ],
                                                                            null,
                                                                            false,
                                                                            2540106336
                                                                          ),
                                                                      },
                                                                      _vm._l(
                                                                        Object.keys(
                                                                          _vm.templateTreeList
                                                                        ),
                                                                        function (
                                                                          platform,
                                                                          index
                                                                        ) {
                                                                          return _c(
                                                                            "div",
                                                                            {
                                                                              key: index,
                                                                            },
                                                                            [
                                                                              _c(
                                                                                VListGroup,
                                                                                {
                                                                                  attrs:
                                                                                    {
                                                                                      value: true,
                                                                                      "no-action":
                                                                                        "",
                                                                                      "sub-group":
                                                                                        "",
                                                                                    },
                                                                                  scopedSlots:
                                                                                    _vm._u(
                                                                                      [
                                                                                        {
                                                                                          key: "activator",
                                                                                          fn: function () {
                                                                                            return [
                                                                                              _c(
                                                                                                VListItemContent,
                                                                                                [
                                                                                                  platform.includes(
                                                                                                    "https://github.com/"
                                                                                                  )
                                                                                                    ? _c(
                                                                                                        VListItemSubtitle,
                                                                                                        {
                                                                                                          staticStyle:
                                                                                                            {
                                                                                                              "margin-top":
                                                                                                                "-20px",
                                                                                                              "font-size":
                                                                                                                "x-small",
                                                                                                            },
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            "https://github.com/"
                                                                                                          ),
                                                                                                        ]
                                                                                                      )
                                                                                                    : _vm._e(),
                                                                                                  platform.includes(
                                                                                                    "https://github.com/"
                                                                                                  )
                                                                                                    ? _c(
                                                                                                        VListItemTitle,
                                                                                                        {
                                                                                                          staticStyle:
                                                                                                            {
                                                                                                              "margin-left":
                                                                                                                "-100%",
                                                                                                              "margin-top":
                                                                                                                "5px",
                                                                                                            },
                                                                                                        },
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            _vm._s(
                                                                                                              platform.replace(
                                                                                                                "https://github.com/",
                                                                                                                ""
                                                                                                              )
                                                                                                            )
                                                                                                          ),
                                                                                                        ]
                                                                                                      )
                                                                                                    : _c(
                                                                                                        VListItemTitle,
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            _vm._s(
                                                                                                              platform
                                                                                                            )
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                ],
                                                                                                1
                                                                                              ),
                                                                                              _c(
                                                                                                VBtn,
                                                                                                {
                                                                                                  attrs:
                                                                                                    {
                                                                                                      icon: "",
                                                                                                    },
                                                                                                  on: {
                                                                                                    click:
                                                                                                      function (
                                                                                                        $event
                                                                                                      ) {
                                                                                                        return _vm.openTemplateTreeEditor(
                                                                                                          platform,
                                                                                                          "add file"
                                                                                                        )
                                                                                                      },
                                                                                                  },
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    VIcon,
                                                                                                    {
                                                                                                      attrs:
                                                                                                        {
                                                                                                          small:
                                                                                                            "",
                                                                                                        },
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "mdi-file-plus"
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                ],
                                                                                                1
                                                                                              ),
                                                                                              _c(
                                                                                                VBtn,
                                                                                                {
                                                                                                  attrs:
                                                                                                    {
                                                                                                      icon: "",
                                                                                                    },
                                                                                                  on: {
                                                                                                    click:
                                                                                                      function (
                                                                                                        $event
                                                                                                      ) {
                                                                                                        return _vm.openTemplateTreeEditor(
                                                                                                          platform,
                                                                                                          "add folder"
                                                                                                        )
                                                                                                      },
                                                                                                  },
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    VIcon,
                                                                                                    {
                                                                                                      attrs:
                                                                                                        {
                                                                                                          small:
                                                                                                            "",
                                                                                                        },
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        "mdi-folder-plus"
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                                ],
                                                                                                1
                                                                                              ),
                                                                                            ]
                                                                                          },
                                                                                          proxy: true,
                                                                                        },
                                                                                      ],
                                                                                      null,
                                                                                      true
                                                                                    ),
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    VListItem,
                                                                                    [
                                                                                      _c(
                                                                                        "div",
                                                                                        {
                                                                                          key: _vm.templateTreeRenderkey,
                                                                                        },
                                                                                        [
                                                                                          _vm
                                                                                            .templateTreeList[
                                                                                            platform
                                                                                          ]
                                                                                            ? _c(
                                                                                                VTreeview,
                                                                                                {
                                                                                                  ref: "codeTrees",
                                                                                                  refInFor: true,
                                                                                                  staticStyle:
                                                                                                    {
                                                                                                      "text-overflow":
                                                                                                        "clip",
                                                                                                      "margin-right":
                                                                                                        "-50px",
                                                                                                      "max-height":
                                                                                                        "70%",
                                                                                                      "margin-top":
                                                                                                        "-5px",
                                                                                                      "margin-left":
                                                                                                        "-55px",
                                                                                                      "font-size":
                                                                                                        "12px",
                                                                                                      cursor:
                                                                                                        "pointer",
                                                                                                    },
                                                                                                  attrs:
                                                                                                    {
                                                                                                      items:
                                                                                                        _vm
                                                                                                          .templateTreeList[
                                                                                                          platform
                                                                                                        ],
                                                                                                      open: _vm
                                                                                                        .selectedTemplateTreePathList[
                                                                                                        platform
                                                                                                      ],
                                                                                                      active:
                                                                                                        _vm
                                                                                                          .selectedTemplateFileName[
                                                                                                          platform
                                                                                                        ],
                                                                                                      activatable:
                                                                                                        "",
                                                                                                      "item-key":
                                                                                                        "currentPath",
                                                                                                      "return-object":
                                                                                                        "",
                                                                                                      hoverable:
                                                                                                        "",
                                                                                                      dense:
                                                                                                        "",
                                                                                                      "open-on-click":
                                                                                                        "",
                                                                                                    },
                                                                                                  on: {
                                                                                                    "update:items":
                                                                                                      function (
                                                                                                        $event
                                                                                                      ) {
                                                                                                        return _vm.$set(
                                                                                                          _vm.templateTreeList,
                                                                                                          platform,
                                                                                                          $event
                                                                                                        )
                                                                                                      },
                                                                                                    "update:active":
                                                                                                      [
                                                                                                        function (
                                                                                                          $event
                                                                                                        ) {
                                                                                                          return _vm.$set(
                                                                                                            _vm.selectedTemplateFileName,
                                                                                                            platform,
                                                                                                            $event
                                                                                                          )
                                                                                                        },
                                                                                                        _vm.setTemplateFramework,
                                                                                                      ],
                                                                                                    "update:open":
                                                                                                      _vm.setOpenedFolderList,
                                                                                                  },
                                                                                                  scopedSlots:
                                                                                                    _vm._u(
                                                                                                      [
                                                                                                        {
                                                                                                          key: "prepend",
                                                                                                          fn: function ({
                                                                                                            item,
                                                                                                            open,
                                                                                                          }) {
                                                                                                            return [
                                                                                                              _vm.isNotFolderIcon
                                                                                                                ? _c(
                                                                                                                    "div",
                                                                                                                    [
                                                                                                                      _c(
                                                                                                                        VIcon,
                                                                                                                        {
                                                                                                                          style:
                                                                                                                            _vm.templatePathStyle(
                                                                                                                              item
                                                                                                                            ),
                                                                                                                          attrs:
                                                                                                                            {
                                                                                                                              size: "22",
                                                                                                                            },
                                                                                                                        },
                                                                                                                        [
                                                                                                                          _vm._v(
                                                                                                                            "\n                                                                                            " +
                                                                                                                              _vm._s(
                                                                                                                                _vm
                                                                                                                                  .icon[
                                                                                                                                  item
                                                                                                                                    .file
                                                                                                                                ]
                                                                                                                                  ? _vm
                                                                                                                                      .icon[
                                                                                                                                      item
                                                                                                                                        .file
                                                                                                                                    ]
                                                                                                                                  : "mdi-folder"
                                                                                                                              ) +
                                                                                                                              "\n                                                                                        "
                                                                                                                          ),
                                                                                                                        ]
                                                                                                                      ),
                                                                                                                    ],
                                                                                                                    1
                                                                                                                  )
                                                                                                                : _c(
                                                                                                                    "div",
                                                                                                                    [
                                                                                                                      item.children &&
                                                                                                                      item
                                                                                                                        .children
                                                                                                                        .length >
                                                                                                                        0
                                                                                                                        ? _c(
                                                                                                                            VIcon,
                                                                                                                            {
                                                                                                                              style:
                                                                                                                                _vm.templatePathStyle(
                                                                                                                                  item
                                                                                                                                ),
                                                                                                                              attrs:
                                                                                                                                {
                                                                                                                                  size: "20",
                                                                                                                                },
                                                                                                                            },
                                                                                                                            [
                                                                                                                              _vm._v(
                                                                                                                                "\n                                                                                            " +
                                                                                                                                  _vm._s(
                                                                                                                                    open
                                                                                                                                      ? "mdi-folder-open"
                                                                                                                                      : "mdi-folder"
                                                                                                                                  ) +
                                                                                                                                  "\n                                                                                        "
                                                                                                                              ),
                                                                                                                            ]
                                                                                                                          )
                                                                                                                        : _c(
                                                                                                                            VIcon,
                                                                                                                            {
                                                                                                                              style:
                                                                                                                                _vm.templatePathStyle(
                                                                                                                                  item
                                                                                                                                ),
                                                                                                                              attrs:
                                                                                                                                {
                                                                                                                                  size: "22",
                                                                                                                                },
                                                                                                                            },
                                                                                                                            [
                                                                                                                              _vm._v(
                                                                                                                                "\n                                                                                            " +
                                                                                                                                  _vm._s(
                                                                                                                                    _vm
                                                                                                                                      .icon[
                                                                                                                                      item
                                                                                                                                        .file
                                                                                                                                    ]
                                                                                                                                      ? _vm
                                                                                                                                          .icon[
                                                                                                                                          item
                                                                                                                                            .file
                                                                                                                                        ]
                                                                                                                                      : "mdi-folder"
                                                                                                                                  ) +
                                                                                                                                  "\n                                                                                        "
                                                                                                                              ),
                                                                                                                            ]
                                                                                                                          ),
                                                                                                                    ],
                                                                                                                    1
                                                                                                                  ),
                                                                                                            ]
                                                                                                          },
                                                                                                        },
                                                                                                        {
                                                                                                          key: "label",
                                                                                                          fn: function ({
                                                                                                            item,
                                                                                                          }) {
                                                                                                            return [
                                                                                                              _c(
                                                                                                                "div",
                                                                                                                {
                                                                                                                  key: _vm.treeItemRenderKey,
                                                                                                                  style:
                                                                                                                    _vm.templatePathStyle(
                                                                                                                      item
                                                                                                                    ),
                                                                                                                  on: {
                                                                                                                    click:
                                                                                                                      function (
                                                                                                                        $event
                                                                                                                      ) {
                                                                                                                        return _vm.setSelectedTempTreePath(
                                                                                                                          item
                                                                                                                        )
                                                                                                                      },
                                                                                                                  },
                                                                                                                },
                                                                                                                [
                                                                                                                  _vm._v(
                                                                                                                    " " +
                                                                                                                      _vm._s(
                                                                                                                        item.name
                                                                                                                      ) +
                                                                                                                      " \n                                                                                        "
                                                                                                                  ),
                                                                                                                  _vm
                                                                                                                    .selectedTempTreePath[
                                                                                                                    platform
                                                                                                                  ] &&
                                                                                                                  _vm
                                                                                                                    .selectedTempTreePath[
                                                                                                                    platform
                                                                                                                  ] ==
                                                                                                                    item.currentPath
                                                                                                                    ? _c(
                                                                                                                        "span",
                                                                                                                        [
                                                                                                                          !item.children
                                                                                                                            ? _c(
                                                                                                                                VBtn,
                                                                                                                                {
                                                                                                                                  attrs:
                                                                                                                                    {
                                                                                                                                      icon: "",
                                                                                                                                    },
                                                                                                                                  on: {
                                                                                                                                    click:
                                                                                                                                      function (
                                                                                                                                        $event
                                                                                                                                      ) {
                                                                                                                                        return _vm.openTemplateTreeEditor(
                                                                                                                                          item,
                                                                                                                                          "edit"
                                                                                                                                        )
                                                                                                                                      },
                                                                                                                                  },
                                                                                                                                },
                                                                                                                                [
                                                                                                                                  _c(
                                                                                                                                    VIcon,
                                                                                                                                    {
                                                                                                                                      attrs:
                                                                                                                                        {
                                                                                                                                          small:
                                                                                                                                            "",
                                                                                                                                        },
                                                                                                                                    },
                                                                                                                                    [
                                                                                                                                      _vm._v(
                                                                                                                                        "mdi-pencil"
                                                                                                                                      ),
                                                                                                                                    ]
                                                                                                                                  ),
                                                                                                                                ],
                                                                                                                                1
                                                                                                                              )
                                                                                                                            : _vm._e(),
                                                                                                                          item.children
                                                                                                                            ? _c(
                                                                                                                                "span",
                                                                                                                                [
                                                                                                                                  _c(
                                                                                                                                    VBtn,
                                                                                                                                    {
                                                                                                                                      attrs:
                                                                                                                                        {
                                                                                                                                          icon: "",
                                                                                                                                        },
                                                                                                                                      on: {
                                                                                                                                        click:
                                                                                                                                          function (
                                                                                                                                            $event
                                                                                                                                          ) {
                                                                                                                                            return _vm.openTemplateTreeEditor(
                                                                                                                                              item,
                                                                                                                                              "add file"
                                                                                                                                            )
                                                                                                                                          },
                                                                                                                                      },
                                                                                                                                    },
                                                                                                                                    [
                                                                                                                                      _c(
                                                                                                                                        VIcon,
                                                                                                                                        {
                                                                                                                                          attrs:
                                                                                                                                            {
                                                                                                                                              small:
                                                                                                                                                "",
                                                                                                                                            },
                                                                                                                                        },
                                                                                                                                        [
                                                                                                                                          _vm._v(
                                                                                                                                            "mdi-file-plus"
                                                                                                                                          ),
                                                                                                                                        ]
                                                                                                                                      ),
                                                                                                                                    ],
                                                                                                                                    1
                                                                                                                                  ),
                                                                                                                                  _c(
                                                                                                                                    VBtn,
                                                                                                                                    {
                                                                                                                                      attrs:
                                                                                                                                        {
                                                                                                                                          icon: "",
                                                                                                                                        },
                                                                                                                                      on: {
                                                                                                                                        click:
                                                                                                                                          function (
                                                                                                                                            $event
                                                                                                                                          ) {
                                                                                                                                            return _vm.openTemplateTreeEditor(
                                                                                                                                              item,
                                                                                                                                              "add folder"
                                                                                                                                            )
                                                                                                                                          },
                                                                                                                                      },
                                                                                                                                    },
                                                                                                                                    [
                                                                                                                                      _c(
                                                                                                                                        VIcon,
                                                                                                                                        {
                                                                                                                                          attrs:
                                                                                                                                            {
                                                                                                                                              small:
                                                                                                                                                "",
                                                                                                                                            },
                                                                                                                                        },
                                                                                                                                        [
                                                                                                                                          _vm._v(
                                                                                                                                            "mdi-folder-plus"
                                                                                                                                          ),
                                                                                                                                        ]
                                                                                                                                      ),
                                                                                                                                    ],
                                                                                                                                    1
                                                                                                                                  ),
                                                                                                                                ],
                                                                                                                                1
                                                                                                                              )
                                                                                                                            : _vm._e(),
                                                                                                                          _c(
                                                                                                                            VBtn,
                                                                                                                            {
                                                                                                                              attrs:
                                                                                                                                {
                                                                                                                                  icon: "",
                                                                                                                                },
                                                                                                                              on: {
                                                                                                                                click:
                                                                                                                                  function (
                                                                                                                                    $event
                                                                                                                                  ) {
                                                                                                                                    return _vm.openTemplateTreeEditor(
                                                                                                                                      item,
                                                                                                                                      "delete"
                                                                                                                                    )
                                                                                                                                  },
                                                                                                                              },
                                                                                                                            },
                                                                                                                            [
                                                                                                                              _c(
                                                                                                                                VIcon,
                                                                                                                                {
                                                                                                                                  attrs:
                                                                                                                                    {
                                                                                                                                      small:
                                                                                                                                        "",
                                                                                                                                    },
                                                                                                                                },
                                                                                                                                [
                                                                                                                                  _vm._v(
                                                                                                                                    "mdi-delete"
                                                                                                                                  ),
                                                                                                                                ]
                                                                                                                              ),
                                                                                                                            ],
                                                                                                                            1
                                                                                                                          ),
                                                                                                                        ],
                                                                                                                        1
                                                                                                                      )
                                                                                                                    : _vm._e(),
                                                                                                                ]
                                                                                                              ),
                                                                                                            ]
                                                                                                          },
                                                                                                        },
                                                                                                      ],
                                                                                                      null,
                                                                                                      true
                                                                                                    ),
                                                                                                }
                                                                                              )
                                                                                            : _vm._e(),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        }
                                                                      ),
                                                                      0
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        height:
                                                                          "330px",
                                                                        width:
                                                                          "500px",
                                                                      },
                                                                  },
                                                                  [
                                                                    _vm.editTemplateMode
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                width:
                                                                                  "100%",
                                                                                position:
                                                                                  "relative",
                                                                                "margin-top":
                                                                                  "-10px",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              VDivider
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm.editTemplateMode
                                                                      ? _c(
                                                                          VList,
                                                                          {
                                                                            key: _vm.editTemplateListRenderKey,
                                                                            attrs:
                                                                              {
                                                                                nav: "",
                                                                                dense:
                                                                                  "",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              VListGroup,
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    value: true,
                                                                                  },
                                                                                scopedSlots:
                                                                                  _vm._u(
                                                                                    [
                                                                                      {
                                                                                        key: "activator",
                                                                                        fn: function () {
                                                                                          return [
                                                                                            _c(
                                                                                              "div",
                                                                                              {
                                                                                                staticStyle:
                                                                                                  {
                                                                                                    width:
                                                                                                      "100%",
                                                                                                  },
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  VListItemTitle,
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "Edited Template Files"
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ],
                                                                                              1
                                                                                            ),
                                                                                          ]
                                                                                        },
                                                                                        proxy: true,
                                                                                      },
                                                                                    ],
                                                                                    null,
                                                                                    false,
                                                                                    629367039
                                                                                  ),
                                                                              },
                                                                              _vm._l(
                                                                                Object.keys(
                                                                                  _vm.editTemplateFrameWorkList
                                                                                ),
                                                                                function (
                                                                                  platform,
                                                                                  index
                                                                                ) {
                                                                                  return _c(
                                                                                    "div",
                                                                                    {
                                                                                      key: index,
                                                                                    },
                                                                                    [
                                                                                      Object.keys(
                                                                                        _vm
                                                                                          .editTemplateFrameWorkList[
                                                                                          platform
                                                                                        ]
                                                                                      )
                                                                                        .length !=
                                                                                      0
                                                                                        ? _c(
                                                                                            VListGroup,
                                                                                            {
                                                                                              attrs:
                                                                                                {
                                                                                                  value: true,
                                                                                                  "no-action":
                                                                                                    "",
                                                                                                  "sub-group":
                                                                                                    "",
                                                                                                },
                                                                                              scopedSlots:
                                                                                                _vm._u(
                                                                                                  [
                                                                                                    {
                                                                                                      key: "activator",
                                                                                                      fn: function () {
                                                                                                        return [
                                                                                                          _c(
                                                                                                            VListItemContent,
                                                                                                            [
                                                                                                              platform.includes(
                                                                                                                "https://github.com/"
                                                                                                              )
                                                                                                                ? _c(
                                                                                                                    VListItemSubtitle,
                                                                                                                    {
                                                                                                                      staticStyle:
                                                                                                                        {
                                                                                                                          "margin-top":
                                                                                                                            "-20px",
                                                                                                                          "font-size":
                                                                                                                            "x-small",
                                                                                                                        },
                                                                                                                    },
                                                                                                                    [
                                                                                                                      _vm._v(
                                                                                                                        "https://github.com/"
                                                                                                                      ),
                                                                                                                    ]
                                                                                                                  )
                                                                                                                : _vm._e(),
                                                                                                              platform.includes(
                                                                                                                "https://github.com/"
                                                                                                              )
                                                                                                                ? _c(
                                                                                                                    VListItemTitle,
                                                                                                                    {
                                                                                                                      staticStyle:
                                                                                                                        {
                                                                                                                          "margin-left":
                                                                                                                            "-100%",
                                                                                                                          "margin-top":
                                                                                                                            "5px",
                                                                                                                        },
                                                                                                                    },
                                                                                                                    [
                                                                                                                      _vm._v(
                                                                                                                        _vm._s(
                                                                                                                          platform.replace(
                                                                                                                            "https://github.com/",
                                                                                                                            ""
                                                                                                                          )
                                                                                                                        )
                                                                                                                      ),
                                                                                                                    ]
                                                                                                                  )
                                                                                                                : _c(
                                                                                                                    VListItemTitle,
                                                                                                                    [
                                                                                                                      _vm._v(
                                                                                                                        _vm._s(
                                                                                                                          platform
                                                                                                                        )
                                                                                                                      ),
                                                                                                                    ]
                                                                                                                  ),
                                                                                                            ],
                                                                                                            1
                                                                                                          ),
                                                                                                          _c(
                                                                                                            "div",
                                                                                                            [
                                                                                                              _c(
                                                                                                                VMenu,
                                                                                                                {
                                                                                                                  attrs:
                                                                                                                    {
                                                                                                                      "close-on-click": false,
                                                                                                                      "close-on-content-click": false,
                                                                                                                      "offset-x":
                                                                                                                        "",
                                                                                                                      transition:
                                                                                                                        "slide-x-transition",
                                                                                                                    },
                                                                                                                  scopedSlots:
                                                                                                                    _vm._u(
                                                                                                                      [
                                                                                                                        {
                                                                                                                          key: "activator",
                                                                                                                          fn: function ({
                                                                                                                            on: menu,
                                                                                                                            attrs,
                                                                                                                          }) {
                                                                                                                            return [
                                                                                                                              _c(
                                                                                                                                VTooltip,
                                                                                                                                {
                                                                                                                                  attrs:
                                                                                                                                    {
                                                                                                                                      bottom:
                                                                                                                                        "",
                                                                                                                                    },
                                                                                                                                  scopedSlots:
                                                                                                                                    _vm._u(
                                                                                                                                      [
                                                                                                                                        {
                                                                                                                                          key: "activator",
                                                                                                                                          fn: function ({
                                                                                                                                            on: tooltip,
                                                                                                                                          }) {
                                                                                                                                            return [
                                                                                                                                              _c(
                                                                                                                                                VBtn,
                                                                                                                                                _vm._g(
                                                                                                                                                  _vm._b(
                                                                                                                                                    {
                                                                                                                                                      attrs:
                                                                                                                                                        {
                                                                                                                                                          icon: "",
                                                                                                                                                        },
                                                                                                                                                      on: {
                                                                                                                                                        click:
                                                                                                                                                          function (
                                                                                                                                                            $event
                                                                                                                                                          ) {
                                                                                                                                                            return _vm.pushTemplateToGit(
                                                                                                                                                              platform
                                                                                                                                                            )
                                                                                                                                                          },
                                                                                                                                                      },
                                                                                                                                                    },
                                                                                                                                                    "v-btn",
                                                                                                                                                    attrs,
                                                                                                                                                    false
                                                                                                                                                  ),
                                                                                                                                                  {
                                                                                                                                                    ...tooltip,
                                                                                                                                                    ...menu,
                                                                                                                                                  }
                                                                                                                                                ),
                                                                                                                                                [
                                                                                                                                                  _c(
                                                                                                                                                    VIcon,
                                                                                                                                                    {
                                                                                                                                                      attrs:
                                                                                                                                                        {
                                                                                                                                                          color:
                                                                                                                                                            _vm.oldPlatform ==
                                                                                                                                                            platform
                                                                                                                                                              ? "primary"
                                                                                                                                                              : "",
                                                                                                                                                          size: "22",
                                                                                                                                                        },
                                                                                                                                                    },
                                                                                                                                                    [
                                                                                                                                                      _vm._v(
                                                                                                                                                        "mdi-git"
                                                                                                                                                      ),
                                                                                                                                                    ]
                                                                                                                                                  ),
                                                                                                                                                ],
                                                                                                                                                1
                                                                                                                                              ),
                                                                                                                                            ]
                                                                                                                                          },
                                                                                                                                        },
                                                                                                                                      ],
                                                                                                                                      null,
                                                                                                                                      true
                                                                                                                                    ),
                                                                                                                                },
                                                                                                                                [
                                                                                                                                  _c(
                                                                                                                                    "span",
                                                                                                                                    [
                                                                                                                                      _vm._v(
                                                                                                                                        "open git Menu"
                                                                                                                                      ),
                                                                                                                                    ]
                                                                                                                                  ),
                                                                                                                                ]
                                                                                                                              ),
                                                                                                                            ]
                                                                                                                          },
                                                                                                                        },
                                                                                                                      ],
                                                                                                                      null,
                                                                                                                      true
                                                                                                                    ),
                                                                                                                  model:
                                                                                                                    {
                                                                                                                      value:
                                                                                                                        _vm.templatePushDialog,
                                                                                                                      callback:
                                                                                                                        function (
                                                                                                                          $$v
                                                                                                                        ) {
                                                                                                                          _vm.templatePushDialog =
                                                                                                                            $$v
                                                                                                                        },
                                                                                                                      expression:
                                                                                                                        "templatePushDialog",
                                                                                                                    },
                                                                                                                },
                                                                                                                [
                                                                                                                  _vm.templatePushDialog &&
                                                                                                                  _vm.oldPlatform ==
                                                                                                                    platform
                                                                                                                    ? _c(
                                                                                                                        "div",
                                                                                                                        {
                                                                                                                          key: _vm.gitMenuRenderKey,
                                                                                                                        },
                                                                                                                        [
                                                                                                                          _vm.templatePushDialog
                                                                                                                            ? _c(
                                                                                                                                "gitAPIMenu",
                                                                                                                                {
                                                                                                                                  attrs:
                                                                                                                                    {
                                                                                                                                      templateRepoName:
                                                                                                                                        _vm.templateRepoName,
                                                                                                                                      isCustomTemplate:
                                                                                                                                        _vm.isCustomTemplate,
                                                                                                                                      editTemplateMode: true,
                                                                                                                                      editTemplateList:
                                                                                                                                        _vm.editTemplateList,
                                                                                                                                      information:
                                                                                                                                        _vm.projectInformation,
                                                                                                                                      isOnPrem:
                                                                                                                                        _vm.isOnPrem,
                                                                                                                                      projectId:
                                                                                                                                        _vm.modelingProjectId,
                                                                                                                                      projectName:
                                                                                                                                        _vm.projectName,
                                                                                                                                      "git-users":
                                                                                                                                        _vm.gitUsers,
                                                                                                                                      isListSettingDone:
                                                                                                                                        _vm.isGeneratorDone,
                                                                                                                                      isOwnModel:
                                                                                                                                        _vm.isOwnModel,
                                                                                                                                      changedPathListsForGit:
                                                                                                                                        _vm.changedPathListsForGit,
                                                                                                                                      generateCodeLists:
                                                                                                                                        _vm.filteredPrettierCodeLists,
                                                                                                                                      ShowCreateRepoTab:
                                                                                                                                        _vm.ShowCreateRepoTab,
                                                                                                                                      isServerModel:
                                                                                                                                        _vm.isServerModel,
                                                                                                                                      usedTemplates:
                                                                                                                                        _vm.usedTemplates,
                                                                                                                                      usedToppings:
                                                                                                                                        _vm.usedToppings,
                                                                                                                                      canvas:
                                                                                                                                        _vm.canvas,
                                                                                                                                    },
                                                                                                                                  on: {
                                                                                                                                    "update:git-users":
                                                                                                                                      (
                                                                                                                                        val
                                                                                                                                      ) =>
                                                                                                                                        (_vm.gitUsers =
                                                                                                                                          val),
                                                                                                                                    closeMenu:
                                                                                                                                      function (
                                                                                                                                        $event
                                                                                                                                      ) {
                                                                                                                                        _vm.templatePushDialog = false
                                                                                                                                      },
                                                                                                                                    successToPush:
                                                                                                                                      _vm.changePlatform,
                                                                                                                                    closeGitMenu:
                                                                                                                                      _vm.closeGitMenu,
                                                                                                                                    pushSuccessed:
                                                                                                                                      _vm.pushSuccessed,
                                                                                                                                  },
                                                                                                                                  model:
                                                                                                                                    {
                                                                                                                                      value:
                                                                                                                                        _vm
                                                                                                                                          .value
                                                                                                                                          .scm,
                                                                                                                                      callback:
                                                                                                                                        function (
                                                                                                                                          $$v
                                                                                                                                        ) {
                                                                                                                                          _vm.$set(
                                                                                                                                            _vm.value,
                                                                                                                                            "scm",
                                                                                                                                            $$v
                                                                                                                                          )
                                                                                                                                        },
                                                                                                                                      expression:
                                                                                                                                        "value.scm",
                                                                                                                                    },
                                                                                                                                }
                                                                                                                              )
                                                                                                                            : _vm._e(),
                                                                                                                        ],
                                                                                                                        1
                                                                                                                      )
                                                                                                                    : _vm._e(),
                                                                                                                ]
                                                                                                              ),
                                                                                                            ],
                                                                                                            1
                                                                                                          ),
                                                                                                        ]
                                                                                                      },
                                                                                                      proxy: true,
                                                                                                    },
                                                                                                  ],
                                                                                                  null,
                                                                                                  true
                                                                                                ),
                                                                                            },
                                                                                            _vm._l(
                                                                                              Object.keys(
                                                                                                _vm
                                                                                                  .editTemplateFrameWorkList[
                                                                                                  platform
                                                                                                ]
                                                                                              ),
                                                                                              function (
                                                                                                template,
                                                                                                i
                                                                                              ) {
                                                                                                return _c(
                                                                                                  VListItem,
                                                                                                  {
                                                                                                    key: i,
                                                                                                    staticStyle:
                                                                                                      {
                                                                                                        "margin-left":
                                                                                                          "-50px",
                                                                                                      },
                                                                                                    style:
                                                                                                      _vm.selectedEditTemplateName ==
                                                                                                      platform +
                                                                                                        "/" +
                                                                                                        template
                                                                                                        ? "background-color: #e4eef9; color: #2277cf;"
                                                                                                        : "",
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "div",
                                                                                                      {
                                                                                                        staticStyle:
                                                                                                          {
                                                                                                            cursor:
                                                                                                              "pointer",
                                                                                                          },
                                                                                                        on: {
                                                                                                          click:
                                                                                                            function (
                                                                                                              $event
                                                                                                            ) {
                                                                                                              return _vm.setTemplateFramework(
                                                                                                                _vm
                                                                                                                  .editTemplateFrameWorkList[
                                                                                                                  platform
                                                                                                                ][
                                                                                                                  template
                                                                                                                ]
                                                                                                                  .element
                                                                                                              )
                                                                                                            },
                                                                                                          mouseenter:
                                                                                                            function (
                                                                                                              $event
                                                                                                            ) {
                                                                                                              return _vm.setHover(
                                                                                                                platform,
                                                                                                                template
                                                                                                              )
                                                                                                            },
                                                                                                          mouseleave:
                                                                                                            function (
                                                                                                              $event
                                                                                                            ) {
                                                                                                              return _vm.setHover()
                                                                                                            },
                                                                                                        },
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "div",
                                                                                                          {
                                                                                                            staticStyle:
                                                                                                              {
                                                                                                                display:
                                                                                                                  "flex",
                                                                                                                "align-items":
                                                                                                                  "center",
                                                                                                              },
                                                                                                            style:
                                                                                                              _vm
                                                                                                                .editTemplateFrameWorkList[
                                                                                                                platform
                                                                                                              ][
                                                                                                                template
                                                                                                              ]
                                                                                                                .isPushed
                                                                                                                ? "color: darkgray;"
                                                                                                                : "",
                                                                                                          },
                                                                                                          [
                                                                                                            _vm
                                                                                                              .editTemplateFrameWorkList[
                                                                                                              platform
                                                                                                            ][
                                                                                                              template
                                                                                                            ]
                                                                                                              .isPushed
                                                                                                              ? _c(
                                                                                                                  "Icon",
                                                                                                                  {
                                                                                                                    staticStyle:
                                                                                                                      {
                                                                                                                        color:
                                                                                                                          "darkgray",
                                                                                                                      },
                                                                                                                    attrs:
                                                                                                                      {
                                                                                                                        icon: "mdi:file-document-arrow-right-outline",
                                                                                                                        width:
                                                                                                                          "20",
                                                                                                                        height:
                                                                                                                          "20",
                                                                                                                      },
                                                                                                                  }
                                                                                                                )
                                                                                                              : _vm
                                                                                                                  .editTemplateFrameWorkList[
                                                                                                                  platform
                                                                                                                ][
                                                                                                                  template
                                                                                                                ]
                                                                                                                  .isFixed
                                                                                                              ? _c(
                                                                                                                  "Icon",
                                                                                                                  {
                                                                                                                    staticStyle:
                                                                                                                      {
                                                                                                                        color:
                                                                                                                          "green",
                                                                                                                      },
                                                                                                                    attrs:
                                                                                                                      {
                                                                                                                        icon: "mdi:file-document-check-outline",
                                                                                                                        width:
                                                                                                                          "20",
                                                                                                                        height:
                                                                                                                          "20",
                                                                                                                      },
                                                                                                                  }
                                                                                                                )
                                                                                                              : _vm
                                                                                                                  .editTemplateFrameWorkList[
                                                                                                                  platform
                                                                                                                ][
                                                                                                                  template
                                                                                                                ]
                                                                                                                  .failedGenerate
                                                                                                              ? _c(
                                                                                                                  "Icon",
                                                                                                                  {
                                                                                                                    staticStyle:
                                                                                                                      {
                                                                                                                        color:
                                                                                                                          "red",
                                                                                                                      },
                                                                                                                    attrs:
                                                                                                                      {
                                                                                                                        icon: "mdi:file-document-alert-outline",
                                                                                                                        width:
                                                                                                                          "20",
                                                                                                                        height:
                                                                                                                          "20",
                                                                                                                      },
                                                                                                                  }
                                                                                                                )
                                                                                                              : _vm
                                                                                                                  .editTemplateFrameWorkList[
                                                                                                                  platform
                                                                                                                ][
                                                                                                                  template
                                                                                                                ]
                                                                                                                  .isAdded
                                                                                                              ? _c(
                                                                                                                  "Icon",
                                                                                                                  {
                                                                                                                    staticStyle:
                                                                                                                      {
                                                                                                                        color:
                                                                                                                          "#2278cf",
                                                                                                                      },
                                                                                                                    attrs:
                                                                                                                      {
                                                                                                                        icon: "mdi:file-document-plus-outline",
                                                                                                                        width:
                                                                                                                          "20",
                                                                                                                        height:
                                                                                                                          "20",
                                                                                                                      },
                                                                                                                  }
                                                                                                                )
                                                                                                              : _vm
                                                                                                                  .editTemplateFrameWorkList[
                                                                                                                  platform
                                                                                                                ][
                                                                                                                  template
                                                                                                                ]
                                                                                                                  .isDeleted
                                                                                                              ? _c(
                                                                                                                  "Icon",
                                                                                                                  {
                                                                                                                    staticStyle:
                                                                                                                      {
                                                                                                                        color:
                                                                                                                          "red",
                                                                                                                      },
                                                                                                                    attrs:
                                                                                                                      {
                                                                                                                        icon: "mdi:file-document-minus-outline",
                                                                                                                        width:
                                                                                                                          "20",
                                                                                                                        height:
                                                                                                                          "20",
                                                                                                                      },
                                                                                                                  }
                                                                                                                )
                                                                                                              : _c(
                                                                                                                  "Icon",
                                                                                                                  {
                                                                                                                    staticStyle:
                                                                                                                      {
                                                                                                                        color:
                                                                                                                          "#2278cf",
                                                                                                                      },
                                                                                                                    attrs:
                                                                                                                      {
                                                                                                                        icon: "mdi:file-document-edit-outline",
                                                                                                                        width:
                                                                                                                          "20",
                                                                                                                        height:
                                                                                                                          "20",
                                                                                                                      },
                                                                                                                  }
                                                                                                                ),
                                                                                                            _c(
                                                                                                              "div",
                                                                                                              [
                                                                                                                _vm
                                                                                                                  .editTemplateFrameWorkList[
                                                                                                                  platform
                                                                                                                ][
                                                                                                                  template
                                                                                                                ]
                                                                                                                  .element[0]
                                                                                                                  .computedSubFileName
                                                                                                                  ? _c(
                                                                                                                      VListItemSubtitle,
                                                                                                                      {
                                                                                                                        staticStyle:
                                                                                                                          {
                                                                                                                            "font-size":
                                                                                                                              "x-small",
                                                                                                                          },
                                                                                                                      },
                                                                                                                      [
                                                                                                                        _vm._v(
                                                                                                                          "\n                                                                                            " +
                                                                                                                            _vm._s(
                                                                                                                              _vm
                                                                                                                                .editTemplateFrameWorkList[
                                                                                                                                platform
                                                                                                                              ][
                                                                                                                                template
                                                                                                                              ]
                                                                                                                                .element[0]
                                                                                                                                .computedSubFileName
                                                                                                                            ) +
                                                                                                                            "\n                                                                                        "
                                                                                                                        ),
                                                                                                                      ]
                                                                                                                    )
                                                                                                                  : _vm._e(),
                                                                                                                _c(
                                                                                                                  VListItemTitle,
                                                                                                                  {
                                                                                                                    style:
                                                                                                                      !_vm
                                                                                                                        .editTemplateFrameWorkList[
                                                                                                                        platform
                                                                                                                      ][
                                                                                                                        template
                                                                                                                      ]
                                                                                                                        .element[0]
                                                                                                                        .computedSubFileName
                                                                                                                        ? "margin-top:10px;"
                                                                                                                        : "",
                                                                                                                    domProps:
                                                                                                                      {
                                                                                                                        textContent:
                                                                                                                          _vm._s(
                                                                                                                            _vm
                                                                                                                              .editTemplateFrameWorkList[
                                                                                                                              platform
                                                                                                                            ][
                                                                                                                              template
                                                                                                                            ]
                                                                                                                              .element[0]
                                                                                                                              .computedFileName
                                                                                                                          ),
                                                                                                                      },
                                                                                                                  }
                                                                                                                ),
                                                                                                              ],
                                                                                                              1
                                                                                                            ),
                                                                                                            _vm.isHoveredTemplateFilePath ==
                                                                                                            platform +
                                                                                                              "/" +
                                                                                                              template
                                                                                                              ? _c(
                                                                                                                  VBtn,
                                                                                                                  {
                                                                                                                    staticStyle:
                                                                                                                      {
                                                                                                                        color:
                                                                                                                          "darkgray",
                                                                                                                      },
                                                                                                                    attrs:
                                                                                                                      {
                                                                                                                        icon: "",
                                                                                                                      },
                                                                                                                    on: {
                                                                                                                      click:
                                                                                                                        function (
                                                                                                                          $event
                                                                                                                        ) {
                                                                                                                          return _vm.deleteModifiedTemplate(
                                                                                                                            platform,
                                                                                                                            template
                                                                                                                          )
                                                                                                                        },
                                                                                                                    },
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _c(
                                                                                                                      VIcon,
                                                                                                                      {
                                                                                                                        attrs:
                                                                                                                          {
                                                                                                                            small:
                                                                                                                              "",
                                                                                                                          },
                                                                                                                      },
                                                                                                                      [
                                                                                                                        _vm._v(
                                                                                                                          "mdi-close"
                                                                                                                        ),
                                                                                                                      ]
                                                                                                                    ),
                                                                                                                  ],
                                                                                                                  1
                                                                                                                )
                                                                                                              : _vm._e(),
                                                                                                          ],
                                                                                                          1
                                                                                                        ),
                                                                                                      ]
                                                                                                    ),
                                                                                                  ]
                                                                                                )
                                                                                              }
                                                                                            ),
                                                                                            1
                                                                                          )
                                                                                        : _vm._e(),
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                }
                                                                              ),
                                                                              0
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm.editTemplateMode
                                                                      ? _c(
                                                                          "div",
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                width:
                                                                                  "100%",
                                                                                "margin-top":
                                                                                  "-10px",
                                                                              },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              VDivider
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(VDivider, {
                                                              attrs: {
                                                                vertical: "",
                                                              },
                                                            }),
                                                            _c(
                                                              VCardText,
                                                              {
                                                                staticStyle: {
                                                                  padding:
                                                                    "1px",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  VBtn,
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        position:
                                                                          "absolute",
                                                                        right:
                                                                          "5px",
                                                                        "z-index":
                                                                          "1",
                                                                      },
                                                                    attrs: {
                                                                      icon: "",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          ;(_vm.editTemplateMode = false),
                                                                            _vm.defaultCodeViewerRenderKey++
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      VIcon,
                                                                      {
                                                                        attrs: {
                                                                          small:
                                                                            "",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-close"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                                _c(
                                                                  VTabs,
                                                                  {
                                                                    model: {
                                                                      value:
                                                                        _vm.editTemplateTabNumber,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.editTemplateTabNumber =
                                                                            $$v
                                                                        },
                                                                      expression:
                                                                        "editTemplateTabNumber",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      VTabsSlider
                                                                    ),
                                                                    _vm.startReGenerateCode
                                                                      ? _c(
                                                                          VProgressCircular,
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                "margin-top":
                                                                                  "15px",
                                                                              },
                                                                            attrs:
                                                                              {
                                                                                indeterminate:
                                                                                  "",
                                                                                size: 20,
                                                                                color:
                                                                                  "primary",
                                                                              },
                                                                          }
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm._l(
                                                                      _vm.editTemplateTabItems,
                                                                      function (
                                                                        item
                                                                      ) {
                                                                        return _c(
                                                                          VTab,
                                                                          {
                                                                            key: item.tab,
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                item.tab
                                                                              )
                                                                            ),
                                                                          ]
                                                                        )
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      VDivider,
                                                                      {
                                                                        attrs: {
                                                                          vertical:
                                                                            "",
                                                                          inset:
                                                                            "",
                                                                        },
                                                                      }
                                                                    ),
                                                                    !_vm.startReGenerateCode
                                                                      ? _c(
                                                                          VBtn,
                                                                          {
                                                                            staticStyle:
                                                                              {
                                                                                "margin-top":
                                                                                  "5px",
                                                                              },
                                                                            attrs:
                                                                              {
                                                                                icon: "",
                                                                              },
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.reGenerateTemplateCode()
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            _c(
                                                                              VIcon,
                                                                              [
                                                                                _vm._v(
                                                                                  "mdi-play"
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                    _vm._l(
                                                                      _vm.editTemplateTabItems,
                                                                      function (
                                                                        item
                                                                      ) {
                                                                        return _c(
                                                                          VTabItem,
                                                                          {
                                                                            key: item.tab,
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                key: _vm.editModeResultViewerRenderKey,
                                                                              },
                                                                              [
                                                                                item.tabKey ==
                                                                                "edit"
                                                                                  ? _c(
                                                                                      VCard,
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            flat: "",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "separate-panel-components",
                                                                                          {
                                                                                            staticClass:
                                                                                              "gs-edit-template-separate-panel",
                                                                                            attrs:
                                                                                              {
                                                                                                min: _vm
                                                                                                  .codeSeparatePanelInfo
                                                                                                  .min,
                                                                                                max: _vm
                                                                                                  .codeSeparatePanelInfo
                                                                                                  .max,
                                                                                                triggerLength: 2,
                                                                                                paneLengthPercent:
                                                                                                  _vm
                                                                                                    .codeSeparatePanelInfo
                                                                                                    .current,
                                                                                                inBoundSeparatePanel: true,
                                                                                              },
                                                                                            on: {
                                                                                              "update:paneLengthPercent":
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  return _vm.$set(
                                                                                                    _vm.codeSeparatePanelInfo,
                                                                                                    "current",
                                                                                                    $event
                                                                                                  )
                                                                                                },
                                                                                              "update:pane-length-percent":
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  return _vm.$set(
                                                                                                    _vm.codeSeparatePanelInfo,
                                                                                                    "current",
                                                                                                    $event
                                                                                                  )
                                                                                                },
                                                                                            },
                                                                                            scopedSlots:
                                                                                              _vm._u(
                                                                                                [
                                                                                                  {
                                                                                                    key: "one",
                                                                                                    fn: function () {
                                                                                                      return [
                                                                                                        _c(
                                                                                                          "div",
                                                                                                          [
                                                                                                            _vm.opennedTemplateFramework &&
                                                                                                            _vm
                                                                                                              .opennedTemplateFramework[0]
                                                                                                              .templateErrMsg
                                                                                                              ? _c(
                                                                                                                  "div",
                                                                                                                  {
                                                                                                                    staticStyle:
                                                                                                                      {
                                                                                                                        "min-width":
                                                                                                                          "100px",
                                                                                                                        "margin-top":
                                                                                                                          "10px",
                                                                                                                        "margin-bottom":
                                                                                                                          "-15px",
                                                                                                                        "margin-right":
                                                                                                                          "15px",
                                                                                                                      },
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _c(
                                                                                                                      VTooltip,
                                                                                                                      {
                                                                                                                        staticStyle:
                                                                                                                          {
                                                                                                                            "z-index":
                                                                                                                              "9999",
                                                                                                                          },
                                                                                                                        attrs:
                                                                                                                          {
                                                                                                                            top: "",
                                                                                                                            color:
                                                                                                                              "error",
                                                                                                                          },
                                                                                                                        scopedSlots:
                                                                                                                          _vm._u(
                                                                                                                            [
                                                                                                                              {
                                                                                                                                key: "activator",
                                                                                                                                fn: function ({
                                                                                                                                  on,
                                                                                                                                  attrs,
                                                                                                                                }) {
                                                                                                                                  return [
                                                                                                                                    _c(
                                                                                                                                      VChip,
                                                                                                                                      _vm._g(
                                                                                                                                        _vm._b(
                                                                                                                                          {
                                                                                                                                            staticClass:
                                                                                                                                              "ma-2",
                                                                                                                                            attrs:
                                                                                                                                              {
                                                                                                                                                small:
                                                                                                                                                  "",
                                                                                                                                                color:
                                                                                                                                                  "red",
                                                                                                                                                "text-color":
                                                                                                                                                  "white",
                                                                                                                                              },
                                                                                                                                          },
                                                                                                                                          "v-chip",
                                                                                                                                          attrs,
                                                                                                                                          false
                                                                                                                                        ),
                                                                                                                                        on
                                                                                                                                      ),
                                                                                                                                      [
                                                                                                                                        _c(
                                                                                                                                          VIcon,
                                                                                                                                          {
                                                                                                                                            attrs:
                                                                                                                                              {
                                                                                                                                                left: "",
                                                                                                                                                small:
                                                                                                                                                  "",
                                                                                                                                              },
                                                                                                                                          },
                                                                                                                                          [
                                                                                                                                            _vm._v(
                                                                                                                                              "\n                                                                                                    mdi-alert-circle-outline\n                                                                                                "
                                                                                                                                            ),
                                                                                                                                          ]
                                                                                                                                        ),
                                                                                                                                        _vm._v(
                                                                                                                                          "\n                                                                                                " +
                                                                                                                                            _vm._s(
                                                                                                                                              _vm
                                                                                                                                                .opennedTemplateFramework[0]
                                                                                                                                                .templateErrMsg
                                                                                                                                            ) +
                                                                                                                                            " \n                                                                                            "
                                                                                                                                        ),
                                                                                                                                      ],
                                                                                                                                      1
                                                                                                                                    ),
                                                                                                                                  ]
                                                                                                                                },
                                                                                                                              },
                                                                                                                            ],
                                                                                                                            null,
                                                                                                                            true
                                                                                                                          ),
                                                                                                                      },
                                                                                                                      [
                                                                                                                        _c(
                                                                                                                          "span",
                                                                                                                          [
                                                                                                                            _c(
                                                                                                                              VIcon,
                                                                                                                              {
                                                                                                                                staticStyle:
                                                                                                                                  {
                                                                                                                                    "margin-right":
                                                                                                                                      "10px",
                                                                                                                                  },
                                                                                                                                attrs:
                                                                                                                                  {
                                                                                                                                    color:
                                                                                                                                      "white",
                                                                                                                                  },
                                                                                                                              },
                                                                                                                              [
                                                                                                                                _vm._v(
                                                                                                                                  "mdi-alert-circle-outline"
                                                                                                                                ),
                                                                                                                              ]
                                                                                                                            ),
                                                                                                                            _vm._v(
                                                                                                                              _vm._s(
                                                                                                                                _vm
                                                                                                                                  .opennedTemplateFramework[0]
                                                                                                                                  .templateErrMsg
                                                                                                                              )
                                                                                                                            ),
                                                                                                                          ],
                                                                                                                          1
                                                                                                                        ),
                                                                                                                      ]
                                                                                                                    ),
                                                                                                                  ],
                                                                                                                  1
                                                                                                                )
                                                                                                              : _vm._e(),
                                                                                                            _c(
                                                                                                              "code-viewer",
                                                                                                              {
                                                                                                                staticClass:
                                                                                                                  "gs-code-previewer-edit-template-left",
                                                                                                                attrs:
                                                                                                                  {
                                                                                                                    editMode: true,
                                                                                                                    readOnly: false,
                                                                                                                    codeSuggestionObj:
                                                                                                                      _vm.opennedTemplateFramework
                                                                                                                        ? _vm
                                                                                                                            .opennedTemplateFramework[0]
                                                                                                                            .eleKeys
                                                                                                                        : {},
                                                                                                                  },
                                                                                                                on: {
                                                                                                                  update:
                                                                                                                    _vm.updatePathTmp,
                                                                                                                  editCode:
                                                                                                                    _vm.editCode,
                                                                                                                  editBreakPoint:
                                                                                                                    _vm.editBreakPoint,
                                                                                                                },
                                                                                                                model:
                                                                                                                  {
                                                                                                                    value:
                                                                                                                      _vm.opennedTemplateFramework,
                                                                                                                    callback:
                                                                                                                      function (
                                                                                                                        $$v
                                                                                                                      ) {
                                                                                                                        _vm.opennedTemplateFramework =
                                                                                                                          $$v
                                                                                                                      },
                                                                                                                    expression:
                                                                                                                      "opennedTemplateFramework",
                                                                                                                  },
                                                                                                              }
                                                                                                            ),
                                                                                                          ],
                                                                                                          1
                                                                                                        ),
                                                                                                      ]
                                                                                                    },
                                                                                                    proxy: true,
                                                                                                  },
                                                                                                  {
                                                                                                    key: "two",
                                                                                                    fn: function () {
                                                                                                      return [
                                                                                                        _c(
                                                                                                          "div",
                                                                                                          {
                                                                                                            staticStyle:
                                                                                                              {
                                                                                                                width:
                                                                                                                  "500px",
                                                                                                                "margin-left":
                                                                                                                  "15px",
                                                                                                                "margin-top":
                                                                                                                  "10px",
                                                                                                              },
                                                                                                            style:
                                                                                                              _vm.opennedTemplateFramework &&
                                                                                                              _vm
                                                                                                                .opennedTemplateFramework[0]
                                                                                                                .refList &&
                                                                                                              _vm
                                                                                                                .opennedTemplateFramework[0]
                                                                                                                .refList
                                                                                                                .length >
                                                                                                                0
                                                                                                                ? "margin-bottom: -30px;"
                                                                                                                : "",
                                                                                                          },
                                                                                                          [
                                                                                                            _vm.opennedTemplateFramework &&
                                                                                                            _vm
                                                                                                              .opennedTemplateFramework[0]
                                                                                                              .refList &&
                                                                                                            _vm
                                                                                                              .opennedTemplateFramework[0]
                                                                                                              .refList
                                                                                                              .length >
                                                                                                              0
                                                                                                              ? _c(
                                                                                                                  VSelect,
                                                                                                                  {
                                                                                                                    staticStyle:
                                                                                                                      {
                                                                                                                        "font-size":
                                                                                                                          "13px",
                                                                                                                      },
                                                                                                                    attrs:
                                                                                                                      {
                                                                                                                        variant:
                                                                                                                          "solo",
                                                                                                                        label:
                                                                                                                          "Selected file",
                                                                                                                        items:
                                                                                                                          _vm
                                                                                                                            .opennedTemplateFramework[0]
                                                                                                                            .refList,
                                                                                                                      },
                                                                                                                    on: {
                                                                                                                      change:
                                                                                                                        _vm.setTemplateResult,
                                                                                                                    },
                                                                                                                    model:
                                                                                                                      {
                                                                                                                        value:
                                                                                                                          _vm.templateResultPath,
                                                                                                                        callback:
                                                                                                                          function (
                                                                                                                            $$v
                                                                                                                          ) {
                                                                                                                            _vm.templateResultPath =
                                                                                                                              $$v
                                                                                                                          },
                                                                                                                        expression:
                                                                                                                          "templateResultPath",
                                                                                                                      },
                                                                                                                  }
                                                                                                                )
                                                                                                              : _vm._e(),
                                                                                                          ],
                                                                                                          1
                                                                                                        ),
                                                                                                        _vm.neverUsedTmp
                                                                                                          ? _c(
                                                                                                              VChip,
                                                                                                              {
                                                                                                                staticClass:
                                                                                                                  "ma-2",
                                                                                                                attrs:
                                                                                                                  {
                                                                                                                    color:
                                                                                                                      "red",
                                                                                                                    "text-color":
                                                                                                                      "white",
                                                                                                                  },
                                                                                                              },
                                                                                                              [
                                                                                                                _c(
                                                                                                                  VIcon,
                                                                                                                  {
                                                                                                                    attrs:
                                                                                                                      {
                                                                                                                        left: "",
                                                                                                                        small:
                                                                                                                          "",
                                                                                                                      },
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _vm._v(
                                                                                                                      "\n                                                                                    mdi-alert-circle-outline\n                                                                                "
                                                                                                                    ),
                                                                                                                  ]
                                                                                                                ),
                                                                                                                _vm._v(
                                                                                                                  "\n                                                                                Selected template file never used on this model\n                                                                            "
                                                                                                                ),
                                                                                                              ],
                                                                                                              1
                                                                                                            )
                                                                                                          : _vm.isErrTempResult
                                                                                                          ? _c(
                                                                                                              VChip,
                                                                                                              {
                                                                                                                staticClass:
                                                                                                                  "ma-2",
                                                                                                                attrs:
                                                                                                                  {
                                                                                                                    color:
                                                                                                                      "red",
                                                                                                                    "text-color":
                                                                                                                      "white",
                                                                                                                  },
                                                                                                              },
                                                                                                              [
                                                                                                                _c(
                                                                                                                  VIcon,
                                                                                                                  {
                                                                                                                    attrs:
                                                                                                                      {
                                                                                                                        left: "",
                                                                                                                        small:
                                                                                                                          "",
                                                                                                                      },
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _vm._v(
                                                                                                                      "\n                                                                                    mdi-alert-circle-outline\n                                                                                "
                                                                                                                    ),
                                                                                                                  ]
                                                                                                                ),
                                                                                                                _vm._v(
                                                                                                                  "\n                                                                                " +
                                                                                                                    _vm._s(
                                                                                                                      _vm.isErrTempResult
                                                                                                                    ) +
                                                                                                                    "\n                                                                            "
                                                                                                                ),
                                                                                                              ],
                                                                                                              1
                                                                                                            )
                                                                                                          : _c(
                                                                                                              "div",
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "code-viewer",
                                                                                                                  {
                                                                                                                    staticClass:
                                                                                                                      "gs-code-previewer-edit-template-right",
                                                                                                                    attrs:
                                                                                                                      {
                                                                                                                        editMode: true,
                                                                                                                        readOnly: true,
                                                                                                                      },
                                                                                                                    on: {
                                                                                                                      update:
                                                                                                                        _vm.updatePathTmp,
                                                                                                                      editCode:
                                                                                                                        _vm.editCode,
                                                                                                                    },
                                                                                                                    model:
                                                                                                                      {
                                                                                                                        value:
                                                                                                                          _vm.openCode,
                                                                                                                        callback:
                                                                                                                          function (
                                                                                                                            $$v
                                                                                                                          ) {
                                                                                                                            _vm.openCode =
                                                                                                                              $$v
                                                                                                                          },
                                                                                                                        expression:
                                                                                                                          "openCode",
                                                                                                                      },
                                                                                                                  }
                                                                                                                ),
                                                                                                              ],
                                                                                                              1
                                                                                                            ),
                                                                                                      ]
                                                                                                    },
                                                                                                    proxy: true,
                                                                                                  },
                                                                                                ],
                                                                                                null,
                                                                                                true
                                                                                              ),
                                                                                          }
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  : _vm._e(),
                                                                                item.tabKey ==
                                                                                "autoGen"
                                                                                  ? _c(
                                                                                      VCard,
                                                                                      {
                                                                                        attrs:
                                                                                          {
                                                                                            flat: "",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "separate-panel-components",
                                                                                          {
                                                                                            attrs:
                                                                                              {
                                                                                                min: _vm
                                                                                                  .codeSeparatePanelInfo
                                                                                                  .min,
                                                                                                max: _vm
                                                                                                  .codeSeparatePanelInfo
                                                                                                  .max,
                                                                                                triggerLength: 2,
                                                                                                paneLengthPercent:
                                                                                                  _vm
                                                                                                    .codeSeparatePanelInfo
                                                                                                    .current,
                                                                                                inBoundSeparatePanel: true,
                                                                                              },
                                                                                            on: {
                                                                                              "update:paneLengthPercent":
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  return _vm.$set(
                                                                                                    _vm.codeSeparatePanelInfo,
                                                                                                    "current",
                                                                                                    $event
                                                                                                  )
                                                                                                },
                                                                                              "update:pane-length-percent":
                                                                                                function (
                                                                                                  $event
                                                                                                ) {
                                                                                                  return _vm.$set(
                                                                                                    _vm.codeSeparatePanelInfo,
                                                                                                    "current",
                                                                                                    $event
                                                                                                  )
                                                                                                },
                                                                                            },
                                                                                            scopedSlots:
                                                                                              _vm._u(
                                                                                                [
                                                                                                  {
                                                                                                    key: "one",
                                                                                                    fn: function () {
                                                                                                      return [
                                                                                                        _c(
                                                                                                          "div",
                                                                                                          {
                                                                                                            key: _vm.editModeResultViewerRenderKey,
                                                                                                          },
                                                                                                          [
                                                                                                            _c(
                                                                                                              "code-viewer",
                                                                                                              {
                                                                                                                attrs:
                                                                                                                  {
                                                                                                                    editMode: true,
                                                                                                                    readOnly: false,
                                                                                                                  },
                                                                                                                on: {
                                                                                                                  update:
                                                                                                                    _vm.updatePathTmp,
                                                                                                                  editCode:
                                                                                                                    _vm.editMustacheCode,
                                                                                                                },
                                                                                                                model:
                                                                                                                  {
                                                                                                                    value:
                                                                                                                      _vm.originMustacheTemplate,
                                                                                                                    callback:
                                                                                                                      function (
                                                                                                                        $$v
                                                                                                                      ) {
                                                                                                                        _vm.originMustacheTemplate =
                                                                                                                          $$v
                                                                                                                      },
                                                                                                                    expression:
                                                                                                                      "originMustacheTemplate",
                                                                                                                  },
                                                                                                              }
                                                                                                            ),
                                                                                                            _c(
                                                                                                              VBtn,
                                                                                                              {
                                                                                                                staticStyle:
                                                                                                                  {
                                                                                                                    "margin-top":
                                                                                                                      "-280px",
                                                                                                                    "margin-left":
                                                                                                                      "3px",
                                                                                                                  },
                                                                                                                attrs:
                                                                                                                  {
                                                                                                                    color:
                                                                                                                      "primary",
                                                                                                                    loading:
                                                                                                                      _vm.startGenerate,
                                                                                                                    disabled:
                                                                                                                      _vm.startGenerate ||
                                                                                                                      _vm.startGenerateUseOpenAI,
                                                                                                                  },
                                                                                                                on: {
                                                                                                                  click:
                                                                                                                    function (
                                                                                                                      $event
                                                                                                                    ) {
                                                                                                                      return _vm.startAutoGenerateCode(
                                                                                                                        "mustache"
                                                                                                                      )
                                                                                                                    },
                                                                                                                },
                                                                                                              },
                                                                                                              [
                                                                                                                _vm._v(
                                                                                                                  "Start Generate"
                                                                                                                ),
                                                                                                              ]
                                                                                                            ),
                                                                                                            _c(
                                                                                                              VBtn,
                                                                                                              {
                                                                                                                staticStyle:
                                                                                                                  {
                                                                                                                    "margin-top":
                                                                                                                      "-280px",
                                                                                                                    "margin-left":
                                                                                                                      "3px",
                                                                                                                  },
                                                                                                                attrs:
                                                                                                                  {
                                                                                                                    color:
                                                                                                                      "primary",
                                                                                                                    loading:
                                                                                                                      _vm.startGenerateUseOpenAI,
                                                                                                                    disabled:
                                                                                                                      _vm.startGenerate ||
                                                                                                                      _vm.startGenerateUseOpenAI,
                                                                                                                  },
                                                                                                                on: {
                                                                                                                  click:
                                                                                                                    function (
                                                                                                                      $event
                                                                                                                    ) {
                                                                                                                      return _vm.startAutoGenerateCode(
                                                                                                                        "openAI"
                                                                                                                      )
                                                                                                                    },
                                                                                                                },
                                                                                                              },
                                                                                                              [
                                                                                                                _vm._v(
                                                                                                                  "Start Generate use OpenAI"
                                                                                                                ),
                                                                                                              ]
                                                                                                            ),
                                                                                                          ],
                                                                                                          1
                                                                                                        ),
                                                                                                      ]
                                                                                                    },
                                                                                                    proxy: true,
                                                                                                  },
                                                                                                  {
                                                                                                    key: "two",
                                                                                                    fn: function () {
                                                                                                      return [
                                                                                                        _c(
                                                                                                          "div",
                                                                                                          {
                                                                                                            key: _vm.editModeResultViewerRenderKey,
                                                                                                          },
                                                                                                          [
                                                                                                            _c(
                                                                                                              "code-viewer",
                                                                                                              {
                                                                                                                attrs:
                                                                                                                  {
                                                                                                                    editMode: true,
                                                                                                                    readOnly: true,
                                                                                                                  },
                                                                                                                on: {
                                                                                                                  update:
                                                                                                                    _vm.updatePathTmp,
                                                                                                                },
                                                                                                                model:
                                                                                                                  {
                                                                                                                    value:
                                                                                                                      _vm.modifiedMustacheTemplate,
                                                                                                                    callback:
                                                                                                                      function (
                                                                                                                        $$v
                                                                                                                      ) {
                                                                                                                        _vm.modifiedMustacheTemplate =
                                                                                                                          $$v
                                                                                                                      },
                                                                                                                    expression:
                                                                                                                      "modifiedMustacheTemplate",
                                                                                                                  },
                                                                                                              }
                                                                                                            ),
                                                                                                          ],
                                                                                                          1
                                                                                                        ),
                                                                                                      ]
                                                                                                    },
                                                                                                    proxy: true,
                                                                                                  },
                                                                                                ],
                                                                                                null,
                                                                                                true
                                                                                              ),
                                                                                          }
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  : _vm._e(),
                                                                              ],
                                                                              1
                                                                            ),
                                                                          ]
                                                                        )
                                                                      }
                                                                    ),
                                                                  ],
                                                                  2
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      VDialog,
                                      {
                                        attrs: { "no-click-animation": "" },
                                        model: {
                                          value:
                                            _vm.openExpectedTemplateTestDialog,
                                          callback: function ($$v) {
                                            _vm.openExpectedTemplateTestDialog =
                                              $$v
                                          },
                                          expression:
                                            "openExpectedTemplateTestDialog",
                                        },
                                      },
                                      [
                                        _c("ExpectedTemplateTestDialog", {
                                          attrs: {
                                            actualCodeList: _vm.codeLists,
                                            templateFrameWorkList:
                                              _vm.templateFrameWorkList,
                                            existOnlyExpected:
                                              _vm.existOnlyExpected,
                                            existOnlyActual:
                                              _vm.existOnlyActual,
                                            diffList: _vm.diffList,
                                            templateMetaData:
                                              _vm.templateMetaData,
                                            modelingProjectId:
                                              _vm.modelingProjectId,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm.changedModifying
                                      ? _c(
                                          "div",
                                          [
                                            _vm.openCodeWeb.length > 0
                                              ? _c(
                                                  "h2",
                                                  {
                                                    staticStyle: {
                                                      float: "left",
                                                      "margin-left": "27px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "IDE Exist\n                                        Code"
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.openCodeWeb.length > 0
                                              ? _c(
                                                  "h2",
                                                  {
                                                    staticStyle: {
                                                      float: "right",
                                                      "margin-right": "27px",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Merged\n                                        Code"
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.openCodeWeb.length > 0
                                              ? _c("code-viewer", {
                                                  attrs: {
                                                    type: "diff",
                                                    readOnly: true,
                                                    "diff-value":
                                                      _vm.openCodeWeb,
                                                    "create-value":
                                                      _vm.passValue,
                                                  },
                                                  on: {
                                                    update: _vm.updatePathTmp,
                                                  },
                                                  model: {
                                                    value: _vm.openCodeMerge,
                                                    callback: function ($$v) {
                                                      _vm.openCodeMerge = $$v
                                                    },
                                                    expression: "openCodeMerge",
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm.isDiffMode
                                      ? _c(
                                          "div",
                                          { staticStyle: { height: "100%" } },
                                          [
                                            _c("code-viewer", {
                                              attrs: {
                                                type: "diff",
                                                readOnly: true,
                                                "create-value":
                                                  _vm.changedDiffCode,
                                              },
                                              on: { update: _vm.updatePathTmp },
                                              model: {
                                                value: _vm.filteredOpenCode,
                                                callback: function ($$v) {
                                                  _vm.filteredOpenCode = $$v
                                                },
                                                expression: "filteredOpenCode",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "div",
                                          {
                                            key: _vm.defaultCodeViewerRenderKey,
                                            staticStyle: { height: "100%" },
                                          },
                                          [
                                            _c("code-viewer", {
                                              key: _vm.docsRenderKey,
                                              ref: "codeViewer",
                                              staticClass:
                                                "gs-code-previewer-code-viewer",
                                              attrs: {
                                                readOnly: false,
                                                showGpt: _vm.showGpt,
                                                testFileList: _vm.testFileList,
                                                isRootFolder: _vm.isRootFolder,
                                              },
                                              on: {
                                                startImplWithAI:
                                                  _vm.startImplWithAI,
                                                editCode:
                                                  _vm.setCurrentCodeForAutoCodeGenerate,
                                                startAutoGenerate:
                                                  _vm.startAutoGenerateCode,
                                                update: _vm.updatePathTmp,
                                              },
                                              model: {
                                                value: _vm.filteredOpenCode,
                                                callback: function ($$v) {
                                                  _vm.filteredOpenCode = $$v
                                                },
                                                expression: "filteredOpenCode",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          VSnackbar,
                          {
                            attrs: {
                              timeout: _vm.gitSnackBar.timeout,
                              "auto-height": "",
                              color: _vm.gitSnackBar.Color,
                              "multi-line": "",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "action",
                                fn: function ({ attrs }) {
                                  return [
                                    _c(
                                      VBtn,
                                      _vm._b(
                                        {
                                          attrs: {
                                            color: "white",
                                            text: "",
                                            small: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.gitSnackBar.show = false
                                            },
                                          },
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      [
                                        _vm._v(
                                          "\n                                Close\n                            "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.gitSnackBar.show,
                              callback: function ($$v) {
                                _vm.$set(_vm.gitSnackBar, "show", $$v)
                              },
                              expression: "gitSnackBar.show",
                            },
                          },
                          [
                            _c(
                              VLayout,
                              { attrs: { "align-center": "", "pr-4": "" } },
                              [
                                _vm.gitSnackBar.icon
                                  ? _c(
                                      VIcon,
                                      {
                                        staticClass: "pr-3",
                                        attrs: { dark: "", large: "" },
                                      },
                                      [_vm._v(_vm._s(_vm.gitSnackBar.icon))]
                                    )
                                  : _vm._e(),
                                _c(VLayout, { attrs: { column: "" } }, [
                                  _vm.gitSnackBar.title
                                    ? _c("div", [
                                        _c("strong", [
                                          _vm._v(_vm._s(_vm.gitSnackBar.title)),
                                        ]),
                                      ])
                                    : _vm._e(),
                                  _c("div", [
                                    _vm._v(_vm._s(_vm.gitSnackBar.Text)),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        VDialog,
        {
          attrs: { width: "500", persistent: "" },
          model: {
            value: _vm.templateDialog.show,
            callback: function ($$v) {
              _vm.$set(_vm.templateDialog, "show", $$v)
            },
            expression: "templateDialog.show",
          },
        },
        [
          _c(
            VCard,
            [
              _c(VCardTitle, { staticClass: "headline" }, [
                _vm._v("Template from Git"),
              ]),
              _c(
                VCardText,
                [
                  _c(VTextField, {
                    attrs: { label: "Git Repo URL", autofocus: "" },
                    model: {
                      value: _vm.templateDialog.url,
                      callback: function ($$v) {
                        _vm.$set(_vm.templateDialog, "url", $$v)
                      },
                      expression: "templateDialog.url",
                    },
                  }),
                  !_vm.gitAccessToken
                    ? _c(VTextField, {
                        attrs: {
                          "disabled-v-if": "!gitAccessTokenIs",
                          label: "Github AccessToken",
                        },
                        model: {
                          value: _vm.gitAccessToken,
                          callback: function ($$v) {
                            _vm.gitAccessToken = $$v
                          },
                          expression: "gitAccessToken",
                        },
                      })
                    : _vm._e(),
                  _c(
                    "a",
                    {
                      attrs: {
                        href: "https://intro-kor.msaez.io/custom-template/tutorial/#%EC%BB%A4%EC%8A%A4%ED%85%80-%ED%85%9C%ED%94%8C%EB%A6%BF-%EC%84%A4%EC%A0%95%ED%95%98%EA%B8%B0",
                        target: "_blank",
                      },
                    },
                    [_vm._v("Custom Template 튜토리얼")]
                  ),
                ],
                1
              ),
              _c(
                VCardActions,
                [
                  _c(VSpacer),
                  _c(
                    VBtn,
                    {
                      attrs: { color: "dimgray", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.closeTemplateDialog()
                        },
                      },
                    },
                    [_vm._v("\n                    Cancel\n                ")]
                  ),
                  _c(
                    VBtn,
                    {
                      attrs: { color: "primary", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.applyTemplateDialog()
                        },
                      },
                    },
                    [_vm._v("\n                    Apply\n                ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        VDialog,
        {
          ref: "all-apply-dialog",
          attrs: { "max-width": "370", persistent: "" },
          model: {
            value: _vm.showApplyBaseTemplateDialog,
            callback: function ($$v) {
              _vm.showApplyBaseTemplateDialog = $$v
            },
            expression: "showApplyBaseTemplateDialog",
          },
        },
        [
          _c(
            VCard,
            [
              _c(VCardTitle, { staticClass: "headline" }, [
                _vm._v(
                  "\n                Apply Base Template To All?\n            "
                ),
              ]),
              _c(VCardText, [
                _vm._v(
                  " Do you want to change other template settings also to default template settings values? "
                ),
              ]),
              _c(
                VCardActions,
                [
                  _c(VSpacer),
                  _c(
                    VBtn,
                    {
                      attrs: { color: "green darken-1", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.applyBaseTemplateDialog(true)
                        },
                      },
                    },
                    [_vm._v("YES")]
                  ),
                  _c(
                    VBtn,
                    {
                      attrs: { color: "red darken-1", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.applyBaseTemplateDialog(false)
                        },
                      },
                    },
                    [_vm._v("NO")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        VDialog,
        {
          attrs: { width: "480" },
          model: {
            value: _vm.isCustomTemplateForLoad,
            callback: function ($$v) {
              _vm.isCustomTemplateForLoad = $$v
            },
            expression: "isCustomTemplateForLoad",
          },
        },
        [
          _vm.isOnPrem
            ? _c("login-by-gitlab")
            : _c("Login", { attrs: { onlyGitLogin: true } }),
        ],
        1
      ),
      _c(
        VDialog,
        {
          attrs: { scrim: false, persistent: "", width: "auto" },
          model: {
            value: _vm.startGenerate,
            callback: function ($$v) {
              _vm.startGenerate = $$v
            },
            expression: "startGenerate",
          },
        },
        [
          _c(
            VCard,
            [
              _c(VProgressLinear, {
                staticClass: "mb-0",
                staticStyle: { height: "6px" },
                attrs: { indeterminate: "", color: "primary" },
              }),
              _c(
                VCardText,
                { staticStyle: { "text-align-last": "center" } },
                [
                  _c("div", { staticStyle: { "margin-top": "5px" } }, [
                    _vm._v("Waiting for code to be generated"),
                  ]),
                  _c(
                    VBtn,
                    {
                      staticStyle: {
                        "margin-top": "5px",
                        "margin-bottom": "-10px",
                      },
                      attrs: { color: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.stopGenerate()
                        },
                      },
                    },
                    [_vm._v("cancel")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        VDialog,
        {
          model: {
            value: _vm.showLoginCard,
            callback: function ($$v) {
              _vm.showLoginCard = $$v
            },
            expression: "showLoginCard",
          },
        },
        [
          _vm.isOnPrem
            ? _c("login-by-gitlab")
            : _c("Login", {
                attrs: { onlyGitLogin: true },
                on: {
                  login: function ($event) {
                    _vm.showLoginCard = false
                  },
                },
              }),
        ],
        1
      ),
      _vm.showModelDataEditor
        ? _c(
            VDialog,
            {
              attrs: { width: "auto" },
              model: {
                value: _vm.showModelDataEditor,
                callback: function ($$v) {
                  _vm.showModelDataEditor = $$v
                },
                expression: "showModelDataEditor",
              },
            },
            [
              _c(
                VCard,
                { staticStyle: { width: "500px" } },
                [
                  _c(VCardSubtitle, [
                    _vm._v(_vm._s(_vm.currentModelData.subPath)),
                  ]),
                  _c(
                    VCardTitle,
                    {
                      staticStyle: {
                        "margin-top": "-35px",
                        "margin-bottom": "-20px",
                      },
                    },
                    [_vm._v(_vm._s(_vm.currentModelData.name))]
                  ),
                  _c(
                    VCardText,
                    [
                      _c(VTextField, {
                        attrs: { autofocus: "" },
                        model: {
                          value: _vm.currentModelData.value,
                          callback: function ($$v) {
                            _vm.$set(_vm.currentModelData, "value", $$v)
                          },
                          expression: "currentModelData.value",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.selectedTreeItem && _vm.showTemplateTreeEditor
        ? _c(
            VDialog,
            {
              attrs: { width: "auto" },
              model: {
                value: _vm.showTemplateTreeEditor,
                callback: function ($$v) {
                  _vm.showTemplateTreeEditor = $$v
                },
                expression: "showTemplateTreeEditor",
              },
            },
            [
              _c(
                VCard,
                { staticStyle: { width: "500px" } },
                [
                  _vm.selectedTreeItem.editMode == "add New"
                    ? _c(
                        "div",
                        { staticStyle: { padding: "10px" } },
                        [
                          _c(
                            VChip,
                            {
                              staticStyle: { "margin-right": "5px" },
                              style:
                                _vm.newTemplateType == "template"
                                  ? ""
                                  : "color: darkgray;",
                              attrs: {
                                small: "",
                                color:
                                  _vm.newTemplateType == "template"
                                    ? "black"
                                    : "",
                                outlined:
                                  _vm.newTemplateType == "template"
                                    ? true
                                    : false,
                              },
                              on: {
                                click: function ($event) {
                                  _vm.newTemplateType = "template"
                                },
                              },
                            },
                            [
                              _vm.newTemplateType == "template"
                                ? _c(
                                    VIcon,
                                    { attrs: { color: "green", left: "" } },
                                    [
                                      _vm._v(
                                        "\n                        mdi-check\n                    "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(
                                "\n                    Template\n                "
                              ),
                            ],
                            1
                          ),
                          _c(
                            VChip,
                            {
                              style:
                                _vm.newTemplateType == "topping"
                                  ? ""
                                  : "color: darkgray;",
                              attrs: {
                                small: "",
                                color:
                                  _vm.newTemplateType == "topping"
                                    ? "black"
                                    : "",
                                outlined:
                                  _vm.newTemplateType == "topping"
                                    ? true
                                    : false,
                              },
                              on: {
                                click: function ($event) {
                                  _vm.newTemplateType = "topping"
                                },
                              },
                            },
                            [
                              _vm.newTemplateType == "topping"
                                ? _c(
                                    VIcon,
                                    { attrs: { color: "green", left: "" } },
                                    [
                                      _vm._v(
                                        "\n                        mdi-check\n                    "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(
                                "\n                    Topping\n                "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.selectedTreeItemPath && _vm.selectedTreeItem.subPath
                    ? _c(
                        VCardSubtitle,
                        { staticStyle: { "margin-bottom": "-35px" } },
                        [
                          _vm._v(
                            _vm._s(_vm.selectedTreeItemPath) +
                              _vm._s(_vm.selectedTreeItem.subPath)
                          ),
                        ]
                      )
                    : _vm.selectedTreeItemPath
                    ? _c(
                        VCardSubtitle,
                        { staticStyle: { "margin-bottom": "-35px" } },
                        [_vm._v(_vm._s(_vm.selectedTreeItemPath))]
                      )
                    : _vm._e(),
                  _c(
                    VCardTitle,
                    { staticStyle: { "margin-bottom": "-20px" } },
                    [
                      _vm._v(_vm._s(_vm.selectedTreeItem.name)),
                      _vm.selectedTreeItem.editMode.includes("add")
                        ? _c("span", [_vm._v("/")])
                        : _vm._e(),
                    ]
                  ),
                  _c(
                    VCardText,
                    [
                      _vm.selectedTreeItem.editMode == "edit" ||
                      _vm.selectedTreeItem.editMode.includes("add")
                        ? _c(VTextField, {
                            attrs: { autofocus: "" },
                            model: {
                              value: _vm.selectedTreeItem.newName,
                              callback: function ($$v) {
                                _vm.$set(_vm.selectedTreeItem, "newName", $$v)
                              },
                              expression: "selectedTreeItem.newName",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    VCardActions,
                    { staticStyle: { "justify-content": "right" } },
                    [
                      _c(
                        VBtn,
                        {
                          staticStyle: { "margin-top": "-30px" },
                          attrs: { color: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.editTreeItem()
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.selectedTreeItem.editMode))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }