var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "text-reader",
      staticStyle: { "max-width": "300px", "text-align": "left" },
    },
    [
      _c("label", { attrs: { for: "ex_file" } }, [_vm._v(_vm._s(_vm.label))]),
      _c("input", {
        attrs: { id: "ex_file", type: "file" },
        on: { change: _vm.loadTextFromFile },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }